import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Check from "react-bootstrap/FormCheck";
import Label from "react-bootstrap/FormLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  getDeviceAlarmCount,
  getDeviceOkCount,
  getOpenDoorCount,
  getDeviceLocations,
} from "./selectors/deviceListSelector";
import {
  filterByStatus,
  filterByAlarm,
  filterByOk,
  searchByValue,
  filterByDoor,
  filterByFavourite,
  clearFilter,
  filterByLocation,
  showHighTempOnly,
  isEnabled,
} from "./actions/deviceListActions";
import DeviceListGroupFilterDropdown from "./DeviceListGroupFilterDropdown";
class DeviceListFilters extends Component {
  constructor(props) {
	  super(props);
	  this.state = {
	    condition: 'Conditions',
	  };
	}

  renderDropDownItem(location, index) {
    return (
      <Dropdown.Item
        as="button"
        onClick={() => this.props.filterByLocation(location)}
        key={index}
      >
        {location}
      </Dropdown.Item>
    );
  }

  renderLocationDropdown() {
    let dropdown;
    dropdown = (
      <>
        <Dropdown.Toggle variant="info" id="dropdown-basic">
          Location
        </Dropdown.Toggle>{" "}
        <Dropdown.Menu>
          {this.props.deviceLocations.map((location, index) =>
            this.renderDropDownItem(location, index)
          )}
        </Dropdown.Menu>
      </>
    );
    return dropdown;
  }

  renderConditionDropdown() {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="info" id="dropdown-conditions" className="dropdown-v2">
          {this.state.condition}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            as="button"
            onClick={() => {
							this.setState({ condition : "All" });
							this.props.clearFilter()
						}}
          >
            All
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            onClick={() => {
							this.props.clearFilter()
							this.setState({ condition : "Alarmed" });
							this.props.filterByAlarm(true)
						}}
          >
            Alarmed
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            onClick={() => {
							this.props.clearFilter()
							this.setState({ condition : "Not Alarmed" });
							this.props.filterByAlarm(false)
						}}
          >
            Not Alarmed
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            onClick={() => {
							this.props.clearFilter()
							this.setState({ condition : "Door Open" });
							this.props.filterByDoor(true)
						}}
          >
            Door Open
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            onClick={() => {
							this.props.clearFilter()
							this.setState({ condition : "High Temperature" });
							this.props.showHighTempOnly()
						}}
          >
            High Temperature
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  render() {
    return (
      <div className="list-group-filter-button-container">
        <Dropdown>
          <DeviceListGroupFilterDropdown />
        </Dropdown>

        {this.renderConditionDropdown()}

        <div className="checkbox-wrapper checkbox-wrapper-label-first">
            <Label htmlFor="include_inactive">Include inactive</Label>
            <Check
                id="include_inactive"
                size="sm"
                type="switch"
                onChange={(e) =>
                    this.props.isEnabled(e.target.checked)
                }
            />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    deviceAlarmCount: getDeviceAlarmCount(state),
    deviceOkCount: getDeviceOkCount(state),
    deviceOpenDoorCount: getOpenDoorCount(state),
    deviceLocations: getDeviceLocations(state),
  };
}

const mapDispatchToProps = {
  filterByStatus,
  filterByAlarm,
  filterByOk,
  searchByValue,
  filterByDoor,
  filterByFavourite,
  clearFilter,
  filterByLocation,
  showHighTempOnly,
  isEnabled,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceListFilters);
