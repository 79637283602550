import { callAdminApiPost, callAdminApiGet } from "../../api/apiUtils";
import authService from "../../api-authorization/AuthorizeService";

export async function getDeviceIncidentAlarmList(deviceId) {
  const url = `api/alarmconfig/devicealarms/${deviceId}`;

  return callAdminApiGet(url);
}

export async function updateDeviceAlarmConfig(deviceId, alarmConfig) {
  const url = `api/alarmconfig/upsertdevicealarmconfig/${deviceId}`;

  return callAdminApiPost(url, alarmConfig);
}

export async function deleteDeviceAlarmConfig(alarmId) {
  const url = `api/alarmconfig/deletealarmconfig/${alarmId}`;

  return callAdminApiPost(url, {});
}

export async function setDeviceAlarmMute(muteConfig) {
  const url = `api/alarmconfig/setalarmmute`;

  return callAdminApiPost(url, muteConfig);
}

export async function getAlarmContacts(alarmId) {
  const url = `api/alarmconfig/getAlarmContacts/${alarmId}`;

  return callAdminApiGet(url);
}

export async function getAvailableAlarmContacts(alarmId) {
  const url = `api/alarmconfig/getAvailableAlarmContacts/${alarmId}`;

  return callAdminApiGet(url);
}

export async function getContact(contactId) {
  const url = `api/alarmconfig/getContact/${contactId}`;

  return callAdminApiGet(url);
}

export async function getTimezones() {
  const url = `api/alarmconfig/gettimezones`;

  return callAdminApiGet(url);
}

export async function addAlarmContact(alarmId, contactId) {
  const url = `api/alarmconfig/addalarmcontact/${alarmId}/${contactId}`;

  return callAdminApiPost(url);
}

export async function deleteAlarmContact(alarmId, contactId) {
  const url = `api/alarmconfig/deletealarmcontact/${alarmId}/${contactId}`;

  return callAdminApiPost(url);
}

// TOOD: might not need
export async function addContact(contact) {
  const url = "api/alarmconfig/addcontact";

  return callAdminApiPost(url, contact);
}

export async function getAvailabilitySchedule() {
  const user = await authService.getUser();
  const url = `api/alarmconfig/getavailabilityschedule/${user.customerId}`;

  return callAdminApiGet(url);
}

export async function upsertContact(alarmContactUpdate) {
  const user = await authService.getUser();
  alarmContactUpdate.alarmContact.customerId = user.customerId;
  const url = "api/alarmconfig/upsertcontact";

  return callAdminApiPost(url, alarmContactUpdate);
}

export async function getFullAlarmContact(contactId) {
  const url = `api/alarmconfig/getfullalarmcontacttoupdate/${contactId}`;
  return callAdminApiGet(url, contactId);
}

export async function addAlarmContactToAllAlarmsForDevice(deviceId, contactId) {
  const url = `api/alarmconfig/addalarmcontacttoallalarmsfordevice/${deviceId}/${contactId}`;

  return callAdminApiPost(url);
}

export async function removeAlarmContactToAllAlarmsForDevice(
  deviceId,
  contactId
) {
  const url = `api/alarmconfig/removealarmcontacttoallalarmsfordevice/${deviceId}/${contactId}`;

  return callAdminApiPost(url);
}

export async function getAlarmActions(alarmId) {
  const user = await authService.getUser();
  const url = `api/alarmconfig/getalarmactions/${user.customerId}/${alarmId}`;

  return callAdminApiGet(url);
}

export async function putAlarmAction(alarmId, alarmActionId) {
  const url = `api/alarmconfig/putalarmaction/${alarmId}/${alarmActionId}`;

  return callAdminApiPost(url);
}
