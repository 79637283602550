import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { getAvailabilitySchedule } from "./selectors/alarmContactSelector";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./DeviceConfig.css";
import JSONPretty from "react-json-pretty";

class AlarmContactEmail extends Component {
  constructor(props) {
    super(props);

    this.updateSchedule = this.updateSchedule.bind(this);
  }

  componentDidMount() {
    if (
      this.props.availabilitySchedule.length > 0 &&
      this.props.availabilityId === -1
    ) {
      this.props.updateEmailAvailability(
        this.props.availabilitySchedule[0].availabilityScheduleId,
        this.props.id
      );
    }
  }

  updateEmail(e) {
    this.props.updateEmail(e, this.props.id);
  }

  updateSchedule(e) {
    this.props.updateEmailAvailability(e.target.value, this.props.id);
  }
  render() {
    return (
      <Row className="form-row">
        <JSONPretty data={this.state}></JSONPretty>
        <Col className="form-col-label-alarm-contact">Email:</Col>
        <Col className="form-col-input-alarm-contact">
          <Form.Control
            size="sm"
            value={this.props.email}
            onChange={(e) => this.updateEmail(e)}
            isInvalid={!!this.props.errors.email}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {this.props.errors.email}
          </Form.Control.Feedback>
        </Col>
        <Col className="form-col-label-alarm-contact">Availability</Col>
        <Col className="form-col-input-alarm-contact-availability">
          <select
            className="form-control form-control-sm"
            onChange={this.updateSchedule}
            value={this.props.availabilityId}
          >
            {this.props.availabilitySchedule.map((item, index) => {
              return (
                <option value={item.availabilityScheduleId}>
                  {item.description}
                </option>
              );
            })}
          </select>

          {this.props.errors.availabilityId && (
            <div className="invalid">{this.props.errors.availabilityId}</div>
          )}
        </Col>
      </Row>
    );
  }
}

AlarmContactEmail.defaultProps = {
  availabilitySchedule: [],
};

function mapStateToProps(state) {
  return {
    availabilitySchedule: getAvailabilitySchedule(state),
  };
}
export default connect(mapStateToProps, null)(AlarmContactEmail);
