import React, { Component } from "react";
import { connect } from "react-redux";
import {
  deviceListNotificationUpdate,
  getServiceBusNoticationSubscription,
} from "./actions/deviceNotificationActions";
import { updateDeviceData } from "../deviceList/actions/deviceListActions";
import { getServiceBusSubscriptionDetails } from "../deviceNotifications/selectors/deviceNotificationSelectors";
import * as signalR from "@microsoft/signalr";
//import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { getSelectedDevice } from "../deviceList/selectors/deviceListSelector";
import { FridgeInsightsDeviceNotificationsHub } from "../configuration/config";
import {
  updateDeviceTelemetryChartData,
  resetLiveDeviceTelemetryChart,
} from "../charts/actions/chartActions";
import { resetDeviceIncidentList } from "../incidents/actions/incidentActions";

class DeviceNotificationSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      servicebusSubscription: {},
      connection: null,
      receivedData: [],
      alarms: [],
      events: [],
      incidents: [],
      filters: {
        includeData: true,
        includeAlarms: true,
        includeIncidents: true,
      },
      status: "not connected to a device",
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.device !== prevProps.device) {
      if (this.state.connection) {
        const status = `disconnecting from ${prevProps.device.hardwareId}`;
        this.setState({ status: status });

        await this.closeExistingSubscriptionStream();
      }

      await this.getSubscriptionDetails();
      await this.setUpSignalRConnection();

      this.startSubscriptionStream();
    }
  }

  async getSubscriptionDetails() {
    this.props.getServiceBusNoticationSubscription(
      this.props.device.hardwareId,
      this.state.filters
    );
  }

  async setUpSignalRConnection() {
    const status = `connecting to ${this.props.device.hardwareId}`;
    this.setState({ status: status });

    const protocol = new signalR.JsonHubProtocol();

    this.setState({
      connection: new signalR.HubConnectionBuilder()
        .withUrl(FridgeInsightsDeviceNotificationsHub)
        .withHubProtocol(protocol)
        .build(),
    });
  }

  async closeExistingSubscriptionStream() {
    //this.props.resetLiveDeviceTelemetryChart();
    this.props.resetDeviceIncidentList();
    console.log("Stopping existing subscription stream");
    const json = JSON.stringify(this.props.servicebusSubscription);
    await this.state.connection.invoke("StopSubscriptionStream", json);
    await this.setState({ receivedData: [] });
  }

  /*
startSubscriptionStream = () => {
    const { connection, servicebusSubscription } = this.state;

    if (connection.state !== signalR.HubConnectionState.Disconnected) {
      console.error("Cannot start a HubConnection that is not in the 'Disconnected' state");
      return;
    }

    return connection.start()
      .then(() => {
        console.log("Connection established");
        const status = `connection established to ${this.props.device.hardwareId}`;
        this.setState({ status: status });

        const json = JSON.stringify(servicebusSubscription);
        return connection.invoke("StartSubscriptionStream", json)
          .then(() => {
            console.log("Subscription stream started successfully");
            connection.on("data", this.dataNotification.bind(this));
            connection.on("alarm", this.alarmNotification.bind(this));
            connection.on("event", this.eventNotification.bind(this));
            connection.on("incident", this.incidentNotification.bind(this));
          });
      })
      .catch((err) => {
        console.error("Error starting subscription stream: ", err);
        this.props.setIsConnectedStatus(false);
      });
  };
  */
    startSubscriptionStream() {
        this.state.connection
            .start()
            .then((data) => {
                console.log("Connection established");
                const status = `connection established to ${this.props.device.hardwareId}`;
                this.setState({ status: status });

                const json = JSON.stringify(this.props.servicebusSubscription);
                this.state.connection
                    .invoke("StartSubscriptionStream", json)
                    .then(() => {
                        this.state.connection.on("data", this.dataNotification.bind(this));
                        this.state.connection.on(
                            "alarm",
                            this.alarmNotification.bind(this)
                        );
                        this.state.connection.on(
                            "event",
                            this.eventNotification.bind(this)
                        );
                        this.state.connection.on(
                            "incident",
                            this.incidentNotification.bind(this)
                        );
                    });
            })
            .catch((err) => console.error(err, "red"));
    }


  dataNotification(data) {
    // console.log("DATA NOTIFICATION");
    // console.log(data);
    const deviceData = JSON.parse(data);
    this.props.updateDeviceTelemetryChartData(deviceData);
    this.props.updateDeviceData(deviceData);

    this.setState({
      receivedData: [...this.state.receivedData, deviceData],
      status: `receiving data from ${this.props.device.hardwareId}`,
    });
  }

  alarmNotification(data) {
    // console.log("ALARM NOTIFICATION");
    // console.log(data);
    this.setState({
      receivedData: [...this.state.alarms, JSON.parse(data)],
    });
  }

  eventNotification(data) {
    // console.log("EVENT NOTIFICATION");
    // console.log(data);
    this.setState({
      receivedData: [...this.state.events, JSON.parse(data)],
    });
  }

  incidentNotification(data) {
    // console.log("INCIDENT NOTIFICATION");
    // console.log(data);
    this.setState({
      receivedData: [...this.state.incidents, JSON.parse(data)],
    });
  }

  render() {
    return (
      //<></>
      // USEFUL DEBUGGING DATA
      <div>
        Current Status: {this.state.status}
        {/* <div>
          <h4>Device Notifications Hub Url</h4>
          <JSONPretty data={this.props.servicebusSubscription} />
        </div> */}
        {/* <div>
          <h4>Service Bus Subscription</h4>
          <JSONPretty data={this.props.servicebusSubscription} />
        </div> */}
        {/* <div>
          <h4>Alarms</h4>
          <JSONPretty data={this.state.alarms} />
        </div>
        <div>
          <h4>Events</h4>
          <JSONPretty data={this.state.events} />
        </div>
        <div>
          <h4>Incidents</h4>
          <JSONPretty data={this.state.incidents} />
        </div> */}
        {/* <div>
          <h4>Data</h4>
          <JSONPretty data={this.state.receivedData} />
        </div> */}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    servicebusSubscription: getServiceBusSubscriptionDetails(state),
    device: getSelectedDevice(state),
  };
}

const mapDispatchToProps = {
  deviceListNotificationUpdate,
  getServiceBusNoticationSubscription,
  updateDeviceData,
  updateDeviceTelemetryChartData,
  resetLiveDeviceTelemetryChart,
  resetDeviceIncidentList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceNotificationSubscription);
