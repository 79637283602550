import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function incidentListReducer(
  state = initialState.incidentList,
  action
) {
  switch (action.type) {
    case types.LOAD_DEVICE_INCIDENT_LIST_SUCCESS: {
      if (state.length === 0) {
        return action.incidents;
      }

      return [...state, ...action.incidents];
    }
    case types.RESET_DEVICE_INCIDENT_LIST: {
      return [];
    }
    default:
      return state;
  }
}
