// Common config options that will be change depending on environment Dev/Testing/Production

// LIVE
// export const FridgeInsightsApi = "https://fi-oz-api.azurewebsites.net";

// export const FridgeInsightsAuthorisationHeader = {
//   headers: {
//     Authorization: "5Qgf80GiSPQQ6A97tMLDwPavPwYAGQsQj3pJdzDpo9RPV6EV",
//   },
// };

// export const FridgeInsightsDeviceNotificationsHub =
//   "https://api.fridgeinsights.com/NotificationsHub";

// LIVE END

//export const FridgeInsightsServiceBusSubcriptionApi = `${FridgeInsightsApi}/GET /Customer/INSERVIO/notifications`

// DEV
//export const FridgeInsightsApi = "https://devapi.fridgeinsights.com";
export const Environment = process.env.REACT_APP_ENV;
export const FridgeInsightsApi = process.env.REACT_APP_FRIDGE_INSIGHTS_API;
export const FridgeInsightsAuthorisationHeader = {
  headers: {
    Authorization: process.env.REACT_APP_FRIDGE_INSIGHTS_AUTH_HEADER, //"321",
  },
};

export const FridgeInsightsDeviceNotificationsHub =
  process.env.REACT_APP_FRIDGE_INSIGHTS_DEVICE_NOTIFICATIONS_HUB;
//"https://devapi.fridgeinsights.com/NotificationsHub";

// DEV END

export const FridgeInsightsCustomerApi = `${FridgeInsightsApi}/Customer`;

export const FridgeInsightsDeviceApi = `${FridgeInsightsApi}/Device`;

export const appConfig = {
  deviceInventoryHistoryRecordsPerPage: 18,
};
