import { combineReducers } from "redux";
import applicationState from "../../application/reducers/applicationReducer";
import eventLogData from "../../eventLogs/reducers/eventLogReducer";
import latestIncidents from "../../incidents/reducers/latestIncidentsReducer";
import incidentDetails from "../../incidents/reducers/incidentDetailsReducer";
import errors from "../../errors/reducers/errorReducer";
import deviceList from "../../deviceList/reducers/deviceListReducer";
import deviceNotification from "../../deviceNotifications/reducers/deviceNotificationReducer";
import customer from "../../customer/reducers/customerReducer";
import deviceTelemetryChartData from "../../charts/reducers/deviceTelemetryChartDataReducer";
import analysisDeviceTelemetryChartData from "../../charts/reducers/analysisDeviceTelemetryChartDataReducer";
import dataAnalysisParameters from "../../dataAnalysis/reducers/dataAnalysisReducers";
import incidentList from "../../incidents/reducers/incidentListReducer";
import incidentAlarmList from "../../incidents/reducers/incidentAlarmListReducer";
import incidentReportDropdownList from "../../incidents/reducers/incidentReportDropdownListReducer";
import deviceContacts from "../../customerContact/reducers/deviceContactReducer";
import deviceAlarmConfigList from "../../deviceConfiguration/reducers/alarmConfigReducer";
import newAlarmConfigDialog from "../../deviceConfiguration/reducers/newAlarmConfigDialogReducer";
import generalDeviceSettings from "../../deviceConfiguration/reducers/generalSettingsReducer";
import customerLocations from "../../customer/reducers/customerLocationReducer";
import deviceGroups from "../../deviceGroup/reducers/deviceGroupsReducer";
import deviceInventory from "../../inventory/reducers/deviceInventoryReducer";
import deviceInventoryHistory from "../../inventory/reducers/deviceInventoryHistoryReducer";
import deviceInventorySummary from "../../inventory/reducers/deviceInventorySummaryReducer";
import alarmContacts from "../../deviceConfiguration/reducers/alarmContactReducer";
import availableAlarmContacts from "../../deviceConfiguration/reducers/availableAlarmContactReducer";
import timezones from "../../deviceConfiguration/reducers/timezoneReduder";
import availabilitySchedule from "../../deviceConfiguration/reducers/availabilityScheduleReducer";
import currentUser from "../../users/reducers/currentUserReducer";
import customers from "../../customer/reducers/customersReducer";
import deviceProvisioningTemplates from "../../provisioning/reducers/provisioningDeviceTemplateReducer";

const rootReducer = combineReducers({
  applicationState,
  eventLogData,
  latestIncidents,
  incidentDetails,
  errors,
  deviceList,
  deviceNotification,
  customer,
  deviceTelemetryChartData,
  analysisDeviceTelemetryChartData,
  dataAnalysisParameters,
  incidentList,
  incidentAlarmList,
  incidentReportDropdownList,
  deviceContacts,
  deviceAlarmConfigList,
  newAlarmConfigDialog,
  generalDeviceSettings,
  customerLocations,
  deviceGroups,
  deviceInventory,
    deviceInventoryHistory,
    deviceInventorySummary,
  alarmContacts,
  availableAlarmContacts,
  timezones,
  availabilitySchedule,
  currentUser,
  customers,
  deviceProvisioningTemplates,
});

export default rootReducer;
