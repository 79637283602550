import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function customerReducer(state = initialState.customer, action) {
  switch (action.type) {
    case types.CUSTOMER_DETAILS_LOADED: {
      return action.customer;
    }
    default:
      return state;
  }
}
