import { FridgeInsightsAuthorisationHeader } from "../configuration/config";
import authService from "../api-authorization/AuthorizeService";

async function getAuthHeader(contentType) {
  const token = await authService.getAccessToken();
  const header = {
    headers: !token ? {} : { Authorization: `Bearer ${token}` },
  };

  return header;
}

export async function callAdminApiGet(url) {
  const header = await getAuthHeader();
  return fetch(url, header).then(handleResponse).catch(handleError);
}

export async function callAdminApiPost(url, data) {
  const token = await authService.getAccessToken();

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function callFridgeInsightsApiGet(url) {
  return fetch(url, FridgeInsightsAuthorisationHeader)
    .then(handleResponse)
    .catch(handleError);
}

export async function handleResponse(response) {
  if (response.status === 204) {
    return Promise.resolve();
  }

  if (response.ok) {
    const jsonValue = await response.json();
    return Promise.resolve(jsonValue);
  }
  const error = await response.text();
  throw new Error(error);
}

export function handleError(error) {
  // debugger;
  throw error;
}
