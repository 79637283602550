import { callFridgeInsightsApiGet } from "../../api/apiUtils";
import { FridgeInsightsCustomerApi } from "../../configuration/config";
import { isEmpty } from "../../utils/generalUtils";
import authService from "../../api-authorization/AuthorizeService";

export async function getServiceBusNotificationSubscription(deviceId, filters) {
  // TODO:
  // const token = await authService.getAccessToken();
  // todo get customer stuff from login auth

  const user = await authService.getUser();
  //const customer = "INSERVIO";

  const filterQueryString = buildQueryString(deviceId, filters);

  const url = `${FridgeInsightsCustomerApi}/${user.cosmosId}/notifications/${filterQueryString}`;
  //debugger;
  // console.log(
  //   "deviceNotificationSubscriptionApi.getServiceBusNoticationSubscription URL:"
  // );
  // console.log(url);

  return callFridgeInsightsApiGet(url);
}

// TODO: should probably be private, but for now, makes testing easier
export function buildQueryString(deviceId, filters) {
  const filterQueryString = buildFilterQueryString(filters);

  let deviceParam = "?";
  if (deviceId) {
    deviceParam = `?deviceId=${deviceId}&`;
  }

  const queryString = deviceParam + filterQueryString;
  return queryString;
}

function buildFilterQueryString(filters) {
  if (isEmpty(filters))
    // default to getting alarms
    return "includeAlarms=true";

  return `includeData=${filters.includeData}&includeAlarms=${filters.includeAlarms}&includeIncidents=${filters.includeIncidents}`;
}
