export const formatDate = (rawDate) => {
  if (rawDate && rawDate !== "0001-01-01T00:00:00") {
    const date = new Date(rawDate);
    return date.toLocaleString();
  }

  return "";
};

export const formatDateIgnoreOffset = (rawDate) => {
  if (rawDate) {
    let date = new Date(rawDate);

    const index = rawDate.indexOf("+");
    if (index > -1) {
      const dateWithoutOffsetString = rawDate.substring(0, index);
      date = new Date(dateWithoutOffsetString);
    }

    return date.toLocaleString();
  }

  return "";
};

const getTimeFromJavaScriptDate = (date) => {
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  let secs = seconds;
  if (seconds < 10) {
    secs = `0${seconds}`;
  }
  let mins = minutes;
  if (minutes < 10) {
    mins = `0${minutes}`;
  }
  let hours = hour;
  if (hour < 10) {
    hours = `0${hour}`;
  }
  const time = `${hours}:${mins}:${secs}`;

  return time;
};

export const getTime = (dataTime) => {
  const date = new Date(dataTime);

  return getTimeFromJavaScriptDate(date);
};

export const getTimeWithoutTimezoneConversion = (dataTime) => {
  const dataTimeWithoutOffset = dataTime.slice(0, dataTime.length - 6);
  const date = new Date(dataTimeWithoutOffset);
  const time = getTimeFromJavaScriptDate(date);

  return time;
};
