import React from "react";
import Row from "react-bootstrap/Row";
import DeviceListSortableColumnHeader from "./DeviceListSortableColumnHeader";
import PropTypes from "prop-types";

const DeviceListTableHeader = ({ activeFilter, handleSort }) => (
  <Row className="table-header">
        {["Code", "Name", "Voltage", "Product", "Door"].map((label) => (
      <DeviceListSortableColumnHeader
        key={label}
        label={label}
        onSort={handleSort}
        activeFilter={activeFilter}
      />
    ))}
  </Row>
);

DeviceListTableHeader.propTypes = {
  activeFilter: PropTypes.string,
  handleSort: PropTypes.func.isRequired,
};

export default DeviceListTableHeader;
