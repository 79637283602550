import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "./Inventory.css";

class StockTable extends Component {
  render() {
    return (
      <div>
        {/* <JSONPretty data={this.props.stockList}></JSONPretty> */}
        <Table responsive size="sm" className="table table-hover">
          <thead>
            <tr>
              <th>Product</th>
              <th>Serial Number</th>
              <th>Batch</th>
              <th>Expiry Date</th>
              <th>EPC</th>
              <th>Tag ID</th>
            </tr>
          </thead>
          <tbody>
            {this.props.stockList.map((item, key) => {
              return (
                <tr key={key}>
                  <td>{item.product}</td>
                  <td>{item.serialNumber}</td>
                  <td>{item.batch}</td>
                  <td>{item.expiryDate}</td>
                  <td>{item.epc}</td>
                  <td>{item.tagId}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default StockTable;
