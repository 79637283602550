export const devicesListDashboard = [
    {
      id: 1,
      SerialNumber: "AAO-780",
      Name: "proto-dev-C198",
      Location: "Meadow Heights",
      Company: "Buzzster",
      BusinessType: "Eye Centre",
      CompanyName: "Buzzster Meadow Heights Eye Centre",
      Status: "OK",
      ProductTemperature: 6,
      FridgeTemperature: 25,
      Door: false,
      CategoryFilter: "NSW",
    },
    {
      id: 2,
      SerialNumber: "AAQ-063",
      Name: "proto-dev-C119",
      Location: "Fitzroy",
      Company: "Eimbee",
      BusinessType: "Eye Centre",
      CompanyName: "Eimbee Fitzroy Eye Centre",
      Status: "Warning",
      ProductTemperature: -4,
      FridgeTemperature: 41,
      Door: true,
      CategoryFilter: "SA",
    },
    {
      id: 3,
      SerialNumber: "AAX-026",
      Name: "proto-dev-C195",
      Location: "Clifton Hill",
      Company: "Skyble",
      BusinessType: "Surgery",
      CompanyName: "Skyble Clifton Hill Surgery",
      Status: "Warning",
      ProductTemperature: 41,
      FridgeTemperature: -7,
      Door: false,
      CategoryFilter: "TAS",
    },
    {
      id: 4,
      SerialNumber: "AAI-278",
      Name: "proto-dev-C128",
      Location: "Balaclava",
      Company: "Brightbean",
      BusinessType: "Retina Clinic",
      CompanyName: "Brightbean Balaclava Retina Clinic",
      Status: "OK",
      ProductTemperature: 38,
      FridgeTemperature: -10,
      Door: true,
      CategoryFilter: "QLD",
    },
    {
      id: 5,
      SerialNumber: "AAP-946",
      Name: "proto-dev-C111",
      Location: "Hawthorn",
      Company: "Gevee",
      BusinessType: "Pharmacy",
      CompanyName: "Gevee Hawthorn Pharmacy",
      Status: "Alert",
      ProductTemperature: -1,
      FridgeTemperature: -6,
      Door: true,
      CategoryFilter: "SA",
    },
    {
      id: 6,
      SerialNumber: "AAO-123",
      Name: "proto-dev-C103",
      Location: "Belgrave",
      Company: "Omba",
      BusinessType: "Pharmacy",
      CompanyName: "Omba Belgrave Pharmacy",
      Status: "OK",
      ProductTemperature: 20,
      FridgeTemperature: 47,
      Door: false,
      CategoryFilter: "SA",
    },
    {
      id: 7,
      SerialNumber: "AAV-993",
      Name: "proto-dev-C184",
      Location: "Badger Creek",
      Company: "Zoomlounge",
      BusinessType: "Surgery",
      CompanyName: "Zoomlounge Badger Creek Surgery",
      Status: "Alert",
      ProductTemperature: 19,
      FridgeTemperature: 9,
      Door: true,
      CategoryFilter: "WA",
    },
    {
      id: 8,
      SerialNumber: "AAO-365",
      Name: "proto-dev-C196",
      Location: "Albert Park",
      Company: "Voolia",
      BusinessType: "Surgery",
      CompanyName: "Voolia Albert Park Surgery",
      Status: "Alert",
      ProductTemperature: 3,
      FridgeTemperature: 0,
      Door: false,
      CategoryFilter: "WA",
    },
    {
      id: 9,
      SerialNumber: "AAP-037",
      Name: "proto-dev-C113",
      Location: "Belgrave",
      Company: "Tekfly",
      BusinessType: "Eye Clinic",
      CompanyName: "Tekfly Belgrave Eye Clinic",
      Status: "Warning",
      ProductTemperature: 7,
      FridgeTemperature: 28,
      Door: true,
      CategoryFilter: "QLD",
    },
    {
      id: 10,
      SerialNumber: "AAF-600",
      Name: "proto-dev-C159",
      Location: "Mooroolbark",
      Company: "Gabtune",
      BusinessType: "Pharmacy",
      CompanyName: "Gabtune Mooroolbark Pharmacy",
      Status: "Alert",
      ProductTemperature: 3,
      FridgeTemperature: -1,
      Door: false,
      CategoryFilter: "NSW",
    },
    {
      id: 11,
      SerialNumber: "AAO-780",
      Name: "proto-dev-C198",
      Location: "Meadow Heights",
      Company: "Buzzster",
      BusinessType: "Eye Centre",
      CompanyName: "Buzzster Meadow Heights Eye Centre",
      Status: "OK",
      ProductTemperature: 6,
      FridgeTemperature: 25,
      Door: false,
      CategoryFilter: "NSW",
    },
    {
      id: 12,
      SerialNumber: "AAQ-063",
      Name: "proto-dev-C119",
      Location: "Fitzroy",
      Company: "Eimbee",
      BusinessType: "Eye Centre",
      CompanyName: "Eimbee Fitzroy Eye Centre 2",
      Status: "Warning",
      ProductTemperature: -4,
      FridgeTemperature: 41,
      Door: true,
      CategoryFilter: "SA",
    },
    {
      id: 13,
      SerialNumber: "AAX-026",
      Name: "proto-dev-C195",
      Location: "Clifton Hill",
      Company: "Skyble",
      BusinessType: "Surgery",
      CompanyName: "Skyble Clifton Hill Surgery",
      Status: "Warning",
      ProductTemperature: 41,
      FridgeTemperature: -7,
      Door: false,
      CategoryFilter: "TAS",
    },
    {
      id: 14,
      SerialNumber: "AAI-278",
      Name: "proto-dev-C128",
      Location: "Balaclava",
      Company: "Brightbean",
      BusinessType: "Retina Clinic",
      CompanyName: "Brightbean Balaclava Retina Clinic",
      Status: "OK",
      ProductTemperature: 38,
      FridgeTemperature: -10,
      Door: true,
      CategoryFilter: "QLD",
    },
    {
      id: 15,
      SerialNumber: "AAP-946",
      Name: "proto-dev-C111",
      Location: "Hawthorn",
      Company: "Gevee",
      BusinessType: "Pharmacy",
      CompanyName: "Gevee Hawthorn Pharmacy",
      Status: "Alert",
      ProductTemperature: -1,
      FridgeTemperature: -6,
      Door: true,
      CategoryFilter: "SA",
    },
    {
      id: 16,
      SerialNumber: "AAO-123",
      Name: "proto-dev-C103",
      Location: "Belgrave",
      Company: "Omba",
      BusinessType: "Pharmacy",
      CompanyName: "Omba Belgrave Pharmacy",
      Status: "OK",
      ProductTemperature: 20,
      FridgeTemperature: 47,
      Door: false,
      CategoryFilter: "SA",
    },
    {
      id: 17,
      SerialNumber: "AAV-993",
      Name: "proto-dev-C184",
      Location: "Badger Creek",
      Company: "Zoomlounge",
      BusinessType: "Surgery",
      CompanyName: "Zoomlounge Badger Creek Surgery",
      Status: "Alert",
      ProductTemperature: 19,
      FridgeTemperature: 9,
      Door: true,
      CategoryFilter: "WA",
    },
    {
      id: 18,
      SerialNumber: "AAO-365",
      Name: "proto-dev-C196",
      Location: "Albert Park",
      Company: "Voolia",
      BusinessType: "Surgery",
      CompanyName: "Voolia Albert Park Surgery",
      Status: "Alert",
      ProductTemperature: 3,
      FridgeTemperature: 0,
      Door: false,
      CategoryFilter: "WA",
    },
    {
      id: 19,
      SerialNumber: "AAP-037",
      Name: "proto-dev-C113",
      Location: "Belgrave",
      Company: "Tekfly",
      BusinessType: "Eye Clinic",
      CompanyName: "Tekfly Belgrave Eye Clinic",
      Status: "Warning",
      ProductTemperature: 7,
      FridgeTemperature: 28,
      Door: true,
      CategoryFilter: "QLD",
    },
    {
      id: 20,
      SerialNumber: "AAF-600",
      Name: "proto-dev-C159",
      Location: "Mooroolbark",
      Company: "Gabtune",
      BusinessType: "Pharmacy",
      CompanyName: "Gabtune Mooroolbark Pharmacy",
      Status: "Alert",
      ProductTemperature: 3,
      FridgeTemperature: -1,
      Door: false,
      CategoryFilter: "NSW",
    },
  ];
  