import React, { Component } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import {
  initialDeviceListLoaded,
  getInitialDeviceId,
} from "../application/selectors/applicationSelector";
import { loadDeviceGroups } from "../deviceGroup/actions/deviceGroupActions";
import { getAllDeviceGroups } from "../deviceGroup/selectors/deviceGroupSelector";
import { loadDeviceList } from "../deviceList/actions/deviceListActions";

class DeviceListGroupFilterDropdown extends Component {
constructor(props) {
	super(props);
		this.state = {
				location: null,
		};
	}

  componentDidUpdate(prevProps) {
    if (this.props.devicesLoaded && prevProps.deviceGroups.length === 0) {
      this.props.loadDeviceGroups(this.props.initialDeviceId);
    }
  }
  renderDropDownItem(deviceGroup) {
    return (
      <Dropdown.Item
        as="button"
        onClick={() => {
					this.setState({ location: deviceGroup.description });
					this.props.loadDeviceList(deviceGroup.deviceGroupId)
				}}
        key={deviceGroup.deviceGroupId}
      >
        {deviceGroup.description}
      </Dropdown.Item>
    );
  }

  renderDeviceGroupDropdown() {
    let dropdown;
    dropdown = (
      <>
        <Dropdown.Toggle variant="info" id="dropdown-basic" className="dropdown-v2">
          {this.state.location ? this.state.location : "Location"}
        </Dropdown.Toggle>{" "}
        <Dropdown.Menu>
          {/* {this.props.deviceLocations.map((location, index) =>
                this.renderDropDownItem(location, index)
              )} */}
          {this.props.deviceGroups.map((deviceGroup, index) =>
            this.renderDropDownItem(deviceGroup)
          )}
        </Dropdown.Menu>
      </>
    );
    return dropdown;
  }
  render() {
    return this.props.devicesLoaded ? (
      <>{this.renderDeviceGroupDropdown()}</>
    ) : (
      <>
        <Button variant="info">Location</Button>
      </>
    );
  }
}

DeviceListGroupFilterDropdown.defaultProps = {
  devicesLoaded: false,
  deviceGroups: [],
  initialDeviceId: null,
};

function mapStateToProps(state, ownProps) {
  return {
    devicesLoaded: initialDeviceListLoaded(state),
    initialDeviceId: getInitialDeviceId(state),
    deviceGroups: getAllDeviceGroups(state),
  };
}

const mapDispatchToProps = {
  loadDeviceGroups,
  loadDeviceList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceListGroupFilterDropdown);
