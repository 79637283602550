export const getDeviceInventoryHistory = (state, deviceId) => {
  const filtered = state.deviceInventoryHistory.filter(
    (inv) => inv.deviceId === deviceId
  );
  if (filtered.length > 0) return filtered[0].inventoryHistory;

  return [];
};

export const getDeviceInventoryHistoryToken = (state, deviceId) => {
  const filtered = state.deviceInventoryHistory.filter(
    (inv) => inv.deviceId === deviceId
  );
  if (filtered.length > 0) return filtered[0].token;

  return [];
};
