import * as incidentApi from "../api/incidentApi";
import * as types from "../../common/state/actionTypes";
import { Error } from "../../errors/errorType";
import { logError } from "../../errors/actions/errorActions";

export function loadLatestCustomerIncidentsSuccess(incidents) {
  return { type: types.LOAD_LATEST_CUSTOMER_INCIDENT_SUCCESS, incidents };
}

export function loadIndicentDetailsSuccess(incidentDetails) {
  return { type: types.LOAD_INCIDENT_DETAILS_SUCCESS, incidentDetails };
}

export function loadDeviceIncidentListSuccess(incidents) {
  return { type: types.LOAD_DEVICE_INCIDENT_LIST_SUCCESS, incidents };
}

export function resetDeviceIncidentList() {
  return { type: types.RESET_DEVICE_INCIDENT_LIST };
}
export function loadDeviceIncidentAlarmListSuccess(
  incident,
  incidentAlarmList
) {
  return {
    type: types.LOAD_DEVICE_INCIDENT_ALARM_LIST_SUCCESS,
    incident,
    incidentAlarmList,
  };
}

// export function acknowledgeIncidentAlarmSuccess(
//   incidentId,
//   incidentAlarmId,
//   ackAlarmResponse
// ) {
//   return {
//     type: types.ACKNOWLEDGE_INCIDENT_ALARM_SUCCESS,
//     incidentId,
//     incidentAlarmId,
//     ackAlarmResponse,
//   };
// }

export function loadIncidentReportDropdownListSuccess(dropdownlist) {
  return {
    type: types.LOAD_INCIDENT_REPORT_DROPDOWN_LIST_SUCCESS,
    dropdownlist,
  };
}

export function loadLatestCustomerIncidents() {
  return function (dispatch) {
    return incidentApi
      .getActiveCustomerIncidents()
      .then((incidents) => {
        dispatch(loadLatestCustomerIncidentsSuccess(incidents));
      })
      .catch((error) => {
        const err = new Error(
          "incidentActions.js, loadLatestCustomerIncidents()",
          error.toString()
        );
        dispatch(logError(err));
      });
  };
}

export function loadIncidentDetails(deviceId, incidentId) {
  return function (dispatch) {
    return incidentApi
      .getDeviceIncident(deviceId, incidentId)
      .then((incidentDetails) => {
        dispatch(loadIndicentDetailsSuccess(incidentDetails));
      })
      .catch((error) => {
        const params = [
          {
            deviceId: deviceId,
          },
          {
            incidentId: incidentId,
          },
        ];
        const err = new Error(
          "incidentActions.js, loadIncidentDetails()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function loadDeviceIncidentList(deviceId, recordsPerPage, token) {
  return function (dispatch) {
    return incidentApi
      .getDeviceIncidentList(deviceId, recordsPerPage, token)
      .then((incidents) => {
        dispatch(loadDeviceIncidentListSuccess(incidents));
      })
      .catch((error) => {
        const params = [
          {
            deviceId: deviceId,
          },
          {
            token: token,
          },
        ];
        const err = new Error(
          "incidentActions.js, loadDeviceIncidentList()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function loadDeviceIncidentAlarmList(deviceId, incident) {
  return function (dispatch) {
    return incidentApi
      .getDeviceIncidentAlarmList(deviceId, incident)
      .then((incidentAlarmList) => {
        dispatch(
          loadDeviceIncidentAlarmListSuccess(incident, incidentAlarmList)
        );
      })
      .catch((error) => {
        const params = [
          {
            deviceId: deviceId,
          },
          {
            token: incident,
          },
        ];
        const err = new Error(
          "incidentActions.js, loadDeviceIncidentAlarmList()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function submitDeviceIncidentReportRequest(reportParams) {
  return function (dispatch) {
    return incidentApi
      .submitDeviceIncidentReportRequest(reportParams)
      .catch((error) => {
        const params = [
          {
            reportParams: reportParams,
          },
        ];
        const err = new Error(
          "incidentActions.js, submitDeviceIncidentReportRequest()",
          error.toString(),
          params
        );
        dispatch(logError(err));
      });
  };
}

export function loadIncidentReportDropdownList() {
  return function (dispatch) {
    return incidentApi
      .getIncidentReportDropdownList()
      .then((dropdownlist) => {
        dispatch(loadIncidentReportDropdownListSuccess(dropdownlist));
      })
      .catch((error) => {
        const err = new Error(
          "incidentActions.js, loadIncidentReportDropdownList()",
          error.toString()
        );

        dispatch(logError(err));
      });
  };
}

export function acknowledgIncidentAlarm(incidentId, incidentAlarmId, deviceId) {
  return function (dispatch) {
    return (
      incidentApi
        .acknowledgeAlarm(incidentAlarmId, deviceId)
        // .then(() => {
        //   loadDeviceIncidentAlarmList(deviceId, incidentId);
        // })
        // .then((ackAlarmResponse) => {
        //   dispatch(
        //     acknowledgeIncidentAlarmSuccess(
        //       incidentId,
        //       incidentAlarmId,
        //       ackAlarmResponse
        //     )
        //   );
        // })
        .catch((error) => {
          // debugger;
          const params = [
            {
              incidentId: incidentId,
              incidentAlarmId: incidentAlarmId,
            },
          ];
          const err = new Error(
            "incidentActions.js, acknowledgIncidentAlarm()",
            error.toString(),
            params
          );

          dispatch(logError(err));
        })
    );
  };
}
