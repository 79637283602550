import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LoginMenu } from "./api-authorization/LoginMenu";
import "./NavMenu.css";
import authService from "./api-authorization/AuthorizeService";
import { UserRoles } from "./api-authorization/ApiAuthorizationConstants";
import { connect } from "react-redux";
import { setCurrentUser } from "../components/users/actions/usersActions";
import { LinkContainer } from "react-router-bootstrap";
import { setCustomerDetails } from "../components/customer/actions/customerActions";
class NavMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasAdminRights: false,
      isSuperAdmin: false,
      isAuthenticated: false,
      user: {},
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  async populateState() {
    const user = await authService.getUser();

    let hasAdminRights = false;
    let isAuthenticated = false;
    let isSuperAdmin = false;

    if (user) {
      isAuthenticated = true;
      if (
        user.role === UserRoles.Administrator ||
        user.role === UserRoles.SuperAdmin
      ) {
        hasAdminRights = true;
      }
      if (user.role === UserRoles.SuperAdmin) {
        isSuperAdmin = true;
      }

      this.props.setCurrentUser(user);

      const customer = {
        id: user.customerId,
        name: user.customerName,
      };
      this.props.setCustomerDetails(customer);

      sessionStorage.setItem("email", user.email);
      sessionStorage.setItem("name", `${user.firstName} ${user.lastName}`);
    }

    this.setState({
      hasAdminRights,
      isAuthenticated,
      isSuperAdmin,
      user,
    });
  }

  render() {
    return (
      <header>
        <Navbar
          collapseOnSelect
          expand="lg"
          className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
        >
          <Container fluid>
            {this.state.isAuthenticated && (
              <Navbar.Brand href="/">
                <img
                  src="/images/FridgeInsights-PrimaryLogo.png"
                  height="50"
                  className="d-inline-block align-top"
                  alt="Fridge Insights logo"
                />
              </Navbar.Brand>
            )}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
            >
              <Nav>
                <Navbar.Text className="justify-content-end">
                  v1.1.0
                </Navbar.Text>
                {this.state.hasAdminRights && (
                  <>
                    <LinkContainer to="/">
                      <Nav.Link>Dashboard</Nav.Link>
                    </LinkContainer>
                    <NavDropdown className="text-dark" title="Admin">
                      <LinkContainer to="/users">
                        <NavDropdown.Item className="text-dark">
                          Users
                        </NavDropdown.Item>
                      </LinkContainer>
                      {!this.state.isSuperAdmin && (
                        <LinkContainer to="/provisioning">
                          <NavDropdown.Item className="text-dark">
                            Provisioning
                          </NavDropdown.Item>
                        </LinkContainer>
                      )}
                      {this.state.isSuperAdmin && (
                        <LinkContainer to="/ota">
                          <NavDropdown.Item className="text-dark" href="/ota">
                            OTA Updates
                          </NavDropdown.Item>
                        </LinkContainer>
                      )}
                    </NavDropdown>
                  </>
                )}
                <LoginMenu></LoginMenu>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  setCurrentUser,
  setCustomerDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
