import * as alarmConfigApi from "../api/alarmConfigApi";
import * as types from "../../common/state/actionTypes";
import { Error } from "../../errors/errorType";
import { logError } from "../../errors/actions/errorActions";

export function loadDeviceAlarmConfigListSuccess(alarmConfigList) {
  return { type: types.LOAD_DEVICE_ALARM_CONFIG_LIST_SUCCESS, alarmConfigList };
}

export function resetDeviceAlarmConfigList() {
  return { type: types.RESET_DEVICE_ALARM_CONFIG_LIST };
}

export function toggleNewAlarmConfigDialog(open) {
  return { type: types.TOGGLE_NEW_ALARM_CONFIG_DIALOG, open };
}

export function loadAlarmContactsSuccess(alarmId, alarmContacts) {
  return { type: types.LOAD_ALARM_CONTACTS_SUCCESS, alarmId, alarmContacts };
}

export function loadAvailableContactsSuccess(alarmId, alarmContacts) {
  return {
    type: types.LOAD_ALARM_AVIALABLE_CONTACTS_SUCCESS,
    alarmId,
    alarmContacts,
  };
}

export function loadTimezonesSuccess(timezones) {
  return {
    type: types.LOAD_TIMEZONES_SUCCESS,
    timezones,
  };
}

export function loadAvailabilityScheduleSuccess(schedule) {
  return {
    type: types.LOAD_AVAILABILITY_SCHEDULE_SUCCESS,
    schedule,
  };
}

export function loadDeviceAlarmConfigList(deviceId) {
  return function (dispatch) {
    return alarmConfigApi
      .getDeviceIncidentAlarmList(deviceId)
      .then((alarmConfigList) => {
        dispatch(loadDeviceAlarmConfigListSuccess(alarmConfigList));
      })
      .catch((error) => {
        const params = [
          {
            deviceId: deviceId,
          },
        ];
        const err = new Error(
          "alarmConfigActions.js, loadDeviceAlarmConfigList()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function updateDeviceAlarmConfig(deviceId, alarmConfig) {
  return function (dispatch) {
    return alarmConfigApi
      .updateDeviceAlarmConfig(deviceId, alarmConfig)
      .catch((error) => {
        const params = [
          {
            deviceId: deviceId,
            alarmConfig: alarmConfig,
          },
        ];
        const err = new Error(
          "alarmConfigActions.js, udateDeviceAlarmConfig()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function deleteDeviceAlarmConfig(alarmId) {
  return function (dispatch) {
    return alarmConfigApi.deleteDeviceAlarmConfig(alarmId).catch((error) => {
      const params = [
        {
          alarmId: alarmId,
        },
      ];
      const err = new Error(
        "alarmConfigActions.js, deleteDeviceAlarmConfig()",
        error.toString(),
        params
      );

      dispatch(logError(err));
    });
  };
}

export function setDeviceAlarmMute(muteConfig) {
  return function (dispatch) {
    return alarmConfigApi.setDeviceAlarmMute(muteConfig).catch((error) => {
      const params = [
        {
          muteConfig: muteConfig,
        },
      ];
      const err = new Error(
        "alarmConfigActions.js, setDeviceAlarmMute()",
        error.toString(),
        params
      );

      dispatch(logError(err));
    });
  };
}

export function loadAlarmContacts(alarmId) {
  return function (dispatch) {
    return alarmConfigApi
      .getAlarmContacts(alarmId)
      .then((alarmContacts) => {
        dispatch(loadAlarmContactsSuccess(alarmId, alarmContacts));
      })
      .catch((error) => {
        const params = [
          {
            alarmId: alarmId,
          },
        ];
        const err = new Error(
          "alarmConfigActions.js, loadAlarmContacts()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function loadAvailableAlarmContacts(alarmId) {
  return function (dispatch) {
    return alarmConfigApi
      .getAvailableAlarmContacts(alarmId)
      .then((alarmContacts) => {
        dispatch(loadAvailableContactsSuccess(alarmId, alarmContacts));
      })
      .catch((error) => {
        const params = [
          {
            alarmId: alarmId,
          },
        ];
        const err = new Error(
          "alarmConfigActions.js, loadAlarmContacts()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function loadContact(contactId) {
  return function (dispatch) {
    return alarmConfigApi.getContact(contactId).catch((error) => {
      const params = [
        {
          contactId: contactId,
        },
      ];
      const err = new Error(
        "alarmConfigActions.js, loadContact()",
        error.toString(),
        params
      );

      dispatch(logError(err));
    });
  };
}

export function loadTimezones() {
  return function (dispatch) {
    return alarmConfigApi
      .getTimezones()
      .then((timezones) => {
        dispatch(loadTimezonesSuccess(timezones));
      })
      .catch((error) => {
        const params = [];
        const err = new Error(
          "alarmConfigActions.js, loadTimezones()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function loadAvailabilitySchedule() {
  return function (dispatch) {
    return alarmConfigApi
      .getAvailabilitySchedule()
      .then((schedule) => {
        dispatch(loadAvailabilityScheduleSuccess(schedule));
      })
      .catch((error) => {
        const params = [];
        const err = new Error(
          "alarmConfigActions.js, loadAvailabilitySchedule()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function addAlarmContact(alarmId, contactId) {
  return function (dispatch) {
    return alarmConfigApi
      .addAlarmContact(alarmId, contactId)
      .then(() => {
        dispatch(loadAlarmContacts(alarmId));
      })
      .catch((error) => {
        const params = [
          {
            alarmId: alarmId,
            contactId: contactId,
          },
        ];
        const err = new Error(
          "alarmConfigActions.js, addAlarmContact()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function deleteAlarmContact(alarmId, contactId) {
  return function (dispatch) {
    return alarmConfigApi
      .deleteAlarmContact(alarmId, contactId)
      .then(() => {
        dispatch(loadAlarmContacts(alarmId));
      })
      .catch((error) => {
        const params = [
          {
            alarmId: alarmId,
            contactId: contactId,
          },
        ];
        const err = new Error(
          "alarmConfigActions.js, addAlarmContact()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

// TODO: might not need
export function addContact(contact) {
  return function (dispatch) {
    return alarmConfigApi.addContact(contact).catch((error) => {
      const params = [
        {
          contact: contact,
        },
      ];
      const err = new Error(
        "alarmConfigActions.js, addAlarmContact()",
        error.toString(),
        params
      );

      dispatch(logError(err));
    });
  };
}

export function upsertContact(alarmContactUpdate) {
  return function (dispatch) {
    return alarmConfigApi.upsertContact(alarmContactUpdate).catch((error) => {
      const params = [
        {
          alarmContactUpdate: alarmContactUpdate,
        },
      ];
      const err = new Error(
        "alarmConfigActions.js, upsertContact()",
        error.toString(),
        params
      );

      dispatch(logError(err));
    });
  };
}

export function getFullAlarmContact(contactId) {
  return function (dispatch) {
    return alarmConfigApi.getFullAlarmContact(contactId).catch((error) => {
      const params = [
        {
          contactId: contactId,
        },
      ];
      const err = new Error(
        "alarmConfigActions.js, getFullAlarmContact()",
        error.toString(),
        params
      );

      dispatch(logError(err));
    });
  };
}

export function addAlarmContactToAllAlarmsForDevice(deviceId, contactId) {
  return function (dispatch) {
    return (
      alarmConfigApi
        .addAlarmContactToAllAlarmsForDevice(deviceId, contactId)
        // .then(() => {
        //   dispatch(loadAlarmContacts(deviceId));
        // })
        .catch((error) => {
          const params = [
            {
              deviceId: deviceId,
              contactId: contactId,
            },
          ];
          const err = new Error(
            "alarmConfigActions.js, addAlarmContactToAllAlarmsForDevice()",
            error.toString(),
            params
          );

          dispatch(logError(err));
        })
    );
  };
}

export function removeAlarmContactToAllAlarmsForDevice(deviceId, contactId) {
  return function (dispatch) {
    return (
      alarmConfigApi
        .removeAlarmContactToAllAlarmsForDevice(deviceId, contactId)
        // .then(() => {
        //   dispatch(loadAlarmContacts(deviceId));
        // })
        .catch((error) => {
          const params = [
            {
              deviceId: deviceId,
              contactId: contactId,
            },
          ];
          const err = new Error(
            "alarmConfigActions.js, removeAlarmContactToAllAlarmsForDevice()",
            error.toString(),
            params
          );

          dispatch(logError(err));
        })
    );
  };
}

export function getAlarmActions(alarmId) {
  return function (dispatch) {
    return alarmConfigApi.getAlarmActions(alarmId).catch((error) => {
      const params = [
        {
          alarmId: alarmId,
        },
      ];
      const err = new Error(
        "alarmConfigActions.js, getAlarmActions()",
        error.toString(),
        params
      );

      dispatch(logError(err));
    });
  };
}

export function putAlarmAction(alarmId, alarmActionId) {
  return function (dispatch) {
    return alarmConfigApi
      .putAlarmAction(alarmId, alarmActionId)
      .catch((error) => {
        const params = [
          {
            alarmId: alarmId,
            alarmActionId: alarmActionId,
          },
        ];
        const err = new Error(
          "alarmConfigActions.js, getAlarmActions()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}
