import React, { Component } from "react";
import { connect } from "react-redux";
import { getSelectedDevice } from "../deviceList/selectors/deviceListSelector";
import { isEmpty } from "../utils/generalUtils";
import {
  loadDeviceAlarmConfigList,
  toggleNewAlarmConfigDialog,
} from "./actions/alarmConfigActions";
import {
  getDeviceAlarmConfigList,
  getDeviceAlarmConfigInputs,
  getNextSortOrder,
  showNewAlarmConfigDialog,
} from "./selectors/alarmConfigSelector";
//import JSONPretty from "react-json-pretty";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import EditAlarmConfiguration from "./EditAlarmConfiguration";
import { formatDate } from "../utils/dateUtils";
import "./DeviceConfig.css";

class AlarmConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInput: [],
      selectedTabInput: "",
      selectedLogicalInputId: 0,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.onTabSelect = this.onTabSelect.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.device !== prevProps.device) {
      this.props.loadDeviceAlarmConfigList(this.props.device.id).then(() => {
        this.setSelectedTabInputs(this.props.inputList[0]);
      });
    }
  }

  handleClose() {
    this.props.toggleNewAlarmConfigDialog(false);
  }

  handleShow() {
    this.props.toggleNewAlarmConfigDialog(true);
  }

  onTabSelect(key) {
    this.setSelectedTabInputs(key);
  }

  setSelectedTabInputs(key) {
    let selectedInput = this.props.alarmConfigList.filter(
      (item) => item.inputDescription === key
    );

    if (selectedInput.length > 0) {
      this.setState({
        selectedTabInput: selectedInput[0].inputDescription,
        selectedLogicalInputId: selectedInput[0].logicalInputId,
      });
    }
  }

  render() {
    let device = "No Device Selected";
    if (!isEmpty(this.props.device)) {
      device = this.props.device.name;
    }
    return (
      <div className="content-container">
        <h4>{device}</h4>
        <Tab.Container
          id="left-tabs-example"
          onSelect={(k) => this.onTabSelect(k)}
          defaultActiveKey="SYSTEM"
        >
          <Row>
            <Col sm={3} className="config-input-list-container">
              <Nav variant="pills" className="flex-column">
                {this.props.inputList.map((input, i) => (
                  <Nav.Item key={i}>
                    <Nav.Link eventKey={input}>{input}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                {this.props.inputList.map((input, ind) => (
                  <Tab.Pane eventKey={input} key={ind}>
                    {this.props.alarmConfigList
                      .filter((item) => item.inputDescription === input)
                      .map((config, index) => {
                        return (
                          <div key={index}>
                            {config.alarmId && (
                              <EditAlarmConfiguration
                                key={index}
                                deviceId={this.props.device.id}
                                alarmId={config.alarmId}
                                sortOrder={config.sortOrder}
                                alarmSettingDescription={
                                  config.alarmSettingDescription
                                }
                                logicalInputId={config.logicalInputId}
                                checkType={config.checkType}
                                checkTypeId={config.checkTypeId}
                                threshold={config.threshold}
                                restoreThreshold={config.restoreThreshold}
                                alarmHoldOff={config.alarmHoldOff}
                                restoreHoldoff={config.restoreHoldoff}
                                restoreCheckType={config.restoreCheckType}
                                restoreCheckTypeId={config.restoreCheckTypeId}
                                withoutNotification={config.withoutNotification}
                                repeatInterval={config.repeatInterval}
                                mutedUntil={formatDate(config.mutedUntil)}
                                mutedFlag={config.mutedFlag}
                                mutedFlagBoolean={config.mutedFlagBoolean}
                                newAlarm={false}
                                notificationsFlag={config.notificationsFlag}
                                actionsFlag={config.actionsFlag}
                                cloudAlarmFlag={config.cloudAlarmFlag}
                              ></EditAlarmConfiguration>
                            )}
                          </div>
                        );
                      })}
                    {input !== "SYSTEM" && (
                      <Button
                        size="sm"
                        variant="primary"
                        className="form-group-button"
                        onClick={this.handleShow}
                      >
                        Add Alarm
                      </Button>
                    )}
                    <Modal
                      show={this.props.showDialog}
                      onHide={this.handleClose}
                      size="lg"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Add New Alarm: {this.state.selectedTabInput}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <EditAlarmConfiguration
                          deviceId={this.props.device.id}
                          alarmId={null}
                          sortOrder={this.props.nextSortOder}
                          alarmSettingDescription={""}
                          logicalInputId={this.state.selectedLogicalInputId}
                          checkType={">"}
                          checkTypeId={1}
                          threshold={0}
                          restoreThreshold={0}
                          alarmHoldOff={0}
                          restoreHoldoff={0}
                          restoreCheckType={">"}
                          restoreCheckTypeId={1}
                          withoutNotification={"N"}
                          repeatInterval={0}
                          newAlarm={true}
                        ></EditAlarmConfiguration>
                      </Modal.Body>
                    </Modal>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}

AlarmConfiguration.defaultProps = {
  device: {},
  alarmConfigList: [],
  inputList: [],
  nextSortOrder: 0,
  showDialog: false,
};

function mapStateToProps(state) {
  const device = getSelectedDevice(state);
  return {
    device: device,
    alarmConfigList: getDeviceAlarmConfigList(state),
    inputList: getDeviceAlarmConfigInputs(state),
    nextSortOder: getNextSortOrder(state),
    showDialog: showNewAlarmConfigDialog(state),
  };
}

const mapDispatchToProps = {
  loadDeviceAlarmConfigList,
  getDeviceAlarmConfigInputs,
  toggleNewAlarmConfigDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmConfiguration);
