export const chartColours = [
  "#00FF3F", // door
  "#0019FF", // temperature - fridge
  "#a3a3a3", // compressor
  "#FF00E9", // voltage - main
  "#9900E6", // voltage - battery
  "#FF246D", // temperature - product
  "#FF8C00", // door - magnet
  "#00E396",
];
