import * as types from "../../common/state/actionTypes";
import * as customerApi from "../api/customerApi";
import { logError } from "../../errors/actions/errorActions";

export const setCustomerDetails = (customer) => ({
  type: types.CUSTOMER_DETAILS_LOADED,
  customer,
});

export const customerLocationsLoadedSuccess = (locations) => ({
  type: types.CUSTOMER_LOCATIONS_LOADED_SUCCESS,
  locations,
});

export const customerListLoadedSuccess = (customers) => ({
  type: types.CUSTOMER_LIST_LOADED,
  customers,
});

export function loadCustomerLocations() {
  return function (dispatch) {
    return customerApi
      .getCustomerLocations()
      .then((locations) => {
        dispatch(customerLocationsLoadedSuccess(locations));
      })
      .catch((error) => {
        const params = [{}];
        const err = new Error(
          "customerActions.js, loadCustomerLocations()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function upsertLocation(customerLocation) {
  return function (dispatch) {
    //let updatedCustomerLoc = {};

    return customerApi
      .upsertLocation(customerLocation)
      .then((updatedCustomerLocation) => {
        //  updatedCustomerLoc = updatedCustomerLocation[0];

        return customerApi.getCustomerLocations().then((locations) => {
          dispatch(customerLocationsLoadedSuccess(locations));
        });
      })

      .catch((error) => {
        const params = [
          {
            customerLocation: customerLocation,
          },
        ];
        const err = new Error(
          "customerActions.js, upsertLocation()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

// export function deleteLocation(customerLocationId) {
//   console.log("deleteLocation, customerLocationId: " + customerLocationId);
// }
export function deleteLocation(customerLocationId) {
  return function (dispatch) {
    debugger;
    return customerApi
      .deleteLocation(customerLocationId)
      .then(() => {
        return customerApi.getCustomerLocations().then((locations) => {
          dispatch(customerLocationsLoadedSuccess(locations));
          // dispatch(updateGeneralSettingLocation(-1));
        });
      })
      .catch((error) => {
        const params = [
          {
            customerLocationId: customerLocationId,
          },
        ];
        const err = new Error(
          "customerActions.js, deleteLocation()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function getAllCustomers() {
  return function (dispatch) {
    return customerApi
      .getAllCustomers()
      .then((customers) => {
        dispatch(customerListLoadedSuccess(customers));
      })
      .catch((error) => {
        const params = [{}];
        const err = new Error(
          "customerActions.js, getAllCustomers()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}
