import React, { Component } from "react";
import usersService from "./UsersService";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Button } from "reactstrap";

class PasswordResetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      error: "",
      email: "",
      resetResult: undefined,
    };
  }

  componentDidMount() {
    // TODO: check that code exists, if not redirect to root
    const code = new URLSearchParams(window.location.search).get("code");
    const email = new URLSearchParams(window.location.search).get("email");
    this.setState({ code: code, email: email });
  }

  handleValidSubmit = (event, values) => {
    const resetPasswordModel = {
      email: this.state.email,
      password: values.password,
      code: this.state.code,
    };
    debugger;
    (async () => {
      const result = await usersService.resetPassword(resetPasswordModel);
      this.setState({ resetResult: result });
    })();
    // const { history } = this.props;
    // values.customerId = this.props.customer.id;
    // (async () => {
    //   await usersService.addUser(values);
    //   history.push("/users");
    // })();
  };
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            {this.state.resetResult === undefined && (
              <div>
                <h4>Password Reset</h4>
                <AvForm onValidSubmit={this.handleValidSubmit}>
                  <AvField
                    name="password"
                    type="password"
                    label="Password"
                    required
                  />
                  <AvField
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    required
                    validate={{ match: { value: "password" } }}
                  />
                  <FormGroup>
                    <Button>Reset</Button>&nbsp;
                  </FormGroup>
                </AvForm>
              </div>
            )}
            {this.state.resetResult === true && (
              <div className="col-xs-1" align="center">
                <h4>Your password has been successfully reset</h4>
                <br />
                <a
                  href="/"
                  className="btn btn-primary active"
                  role="button"
                  aria-pressed="true"
                >
                  Login
                </a>
              </div>
            )}
            {this.state.resetResult === false && (
              <div>
                <h4>Password Reset</h4>
                <div className="alert alert-danger" role="alert">
                  There was a problem resetting your password, please contact
                  your administrator
                </div>
              </div>
            )}
            {/* <JSONPretty data={this.state} /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordResetPage;
