import { formatDateIgnoreOffset } from "../../utils/dateUtils";

export const getDeviceInventory = (state, deviceId) => {
  const filtered = state.deviceInventory.filter(
    (inv) => inv.deviceId === deviceId
  );
  if (filtered.length > 0) return filtered[0].inventory;

  return [];
};

export const getUnidentifiedTags = (state, deviceId) => {
  const filtered = state.deviceInventory.filter(
    (inv) => inv.deviceId === deviceId
  );
  if (filtered.length > 0) return filtered[0].undientifiedTags;

  return [];
};

export const getLastScanTime = (state, deviceId) => {
  const filtered = state.deviceInventory.filter(
    (inv) => inv.deviceId === deviceId
  );
  if (filtered.length > 0 && filtered[0].inventory.length > 2)
    return formatDateIgnoreOffset(filtered[0].inventory[2].inventoryTime);

    console.log("JF lastscantime");
  return "";
};

export const getProductTotals = (state, deviceId) => {
  let productTotals = {
    totalItems: 0,
    productCounts: [],
  };

  const filtered = state.deviceInventory.filter(
    (inv) => inv.deviceId === deviceId
  );

  if (filtered.length > 0) {
    productTotals.totalItems = filtered[0].totalItems;
      productTotals.productCounts = filtered[0].productCounts;
      console.log("JF filtered " +filtered[0]);

    }
    console.log("JF productTotals" + productTotals.totalItems);

  return productTotals;
};

export const getProductCounts = (state, deviceId) => {
    let productTotals = {
        totalItems: 0,
        productCounts: [],
    };

    const filtered = state.deviceInventory.filter(
        (inv) => inv.deviceId === deviceId
    );

    if (filtered.length > 0) {
        productTotals.totalItems = filtered[0].totalItems;
        productTotals.productCounts = filtered[0].productCounts;
        console.log("JF filtered yyy " + filtered[0]);

    }
    console.log("JF productTotals yyy" + productTotals.totalItems);

    return productTotals;
};

export const getProductCountsInv = (inventory, deviceId) => {
    let productTotals = {
        totalItems: 0,
        productCounts: [],
    };

    const filtered = inventory.filter(
        (inv) => inv.deviceId === deviceId
    );

    if (filtered.length > 0) {
        productTotals.totalItems = filtered[0].totalItems;
        productTotals.productCounts = filtered[0].productCounts;
        console.log("JF filtered inv " + filtered[0]);

    }
    console.log("JF productTotals inv" + productTotals.totalItems);

    return productTotals;
};


export const getDeviceInventoryProductCounts = (state, deviceId) => {
    let productTotals = {
        totalItems: 0,
        productCounts: [],
    };

    const filtered = state.deviceInventory.filter(
        (inv) => inv.deviceId === deviceId
    );

    if (filtered.length > 0) {
        productTotals.totalItems = filtered[0].totalItems;
        productTotals.productCounts = filtered[0].productCounts;
        console.log("JF filtered xxx " + filtered[0]);

    }
    console.log("JF productTotals xxx" + productTotals.totalItems);

    return productTotals;
};

