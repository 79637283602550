import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function latestIncidentsReducer(
  state = initialState.latestIncidents,
  action
) {
  switch (action.type) {
    case types.LOAD_LATEST_CUSTOMER_INCIDENT_SUCCESS: {
      if (state.length === 0) {
        return action.incidents;
      }

      let newState = [...state];

      let index = -1;

      action.incidents.forEach((element) => {
        index = newState.findIndex((inc) => inc.id === element.id);

        if (index === -1) {
          newState.push(element);
        } else {
          newState[index] = element;
        }
      });

      return newState;
    }
    default:
      return state;
  }
}
