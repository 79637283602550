import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function timezoneReducer(
  state = initialState.timezones,
  action
) {
  switch (action.type) {
    case types.LOAD_TIMEZONES_SUCCESS: {
      return action.timezones;
    }
    default:
      return state;
  }
}
