import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function deviceNotificationReducer(
  state = initialState.deviceNotification,
  action
) {
  switch (action.type) {
    case types.SERVICE_BUS_SUBSCRIPTION_DETAILS_LOADED: {
      let newState = Object.assign({}, state);
      newState.serviceBusSubscription = action.subscription;
      return newState;
    }
    // case types.SET_IS_CONNECTED: {
    //     let newState = Object.assign({}, state);
    //     newState.isConnected = action.isConnected;
    //     return newState;
    // }
    default:
      return state;
  }
}
