export default {
  en: {
    translation: {
      en: "English",
      "Welcome to React": "Welcome to React and react-i18next",
      FieldRequired: "The field is required!",
      FieldInvalid: "The field is invalid!",
      FirstName: "First name",
    },
  },
  el: {
    translation: {
      Home: "Αρχική",
      Counter: "Μετρητής",
      "Fetch data": "Κατέβασε δεδομένα",
      el: "Ελληνικά",
      Hello: "Γεια σου!",
      Logout: "Αποσύνδεση",
      Users: "Χρήστες",
      FieldRequired: "Το πεδίο είναι απαραίτητο!",
      FieldInvalid: "Το πεδίο είναι λανθασμένο!",
      FirstName: "Όνομα",
    },
  },
};
