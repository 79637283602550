import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { searchByValue } from "./actions/deviceListActions";

class DeviceListSearch extends Component {
  searchDevices(e) {
    this.props.searchByValue(e.target.value);
  }
  render() {
    return (
      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Search devices"
          onChange={(e) => {
            this.searchDevices(e);
          }}
        />
      </Form.Group>
    );
  }
}

const mapDispatchToProps = {
  searchByValue,
};

export default connect(null, mapDispatchToProps)(DeviceListSearch);
