import { callAdminApiPost, callAdminApiGet } from "../../api/apiUtils";

export async function getGeneralDevicesettings(deviceId) {
  const url = `api/generaldevicesettings/settings/${deviceId}`;

  return callAdminApiGet(url);
}

export async function updateGeneralDeviceSettings(deviceId, generalSettings) {
  const url = `api/generaldevicesettings/updatesettings/${deviceId}`;

  return callAdminApiPost(url, generalSettings);
}

export async function pushConfigUi(deviceId) {
  const url = `api/generaldevicesettings/pushconfigui/${deviceId}`;

  return callAdminApiPost(url);
}


