import React, { Component } from "react";
import { connect } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DeviceList from "../deviceList/DeviceList";
import DeviceListSearch from "../deviceList/DeviceListSearch";
import DeviceListPagination from "../deviceList/DeviceListPagination";
import DeviceListFilters from "../deviceList/DeviceListFilters";
import AnalysisDeviceTelemetryChart from "../charts/AnalysisDeviceTelemetryChart";
import DeviceNotificationSubscription from "../deviceNotifications/DeviceNotificationSubscription";
//import DeviceTelemetryChartExample from "../charts/DeviceTelemetryChartExample";
//import FakeDeviceTelemetryGenerator from "../utils/FakeDeviceTelemetryGenerator";
import DeviceTelemetryChart from "../charts/DeviceTelemetryChart";
import "react-datetime/css/react-datetime.css";
import DeviceIncidentList from "../incidents/DeviceIncidentList";
import DeviceCustomerContactInformation from "../customerContact/DeviceCustomerContactInformation";
import DeviceListUpdate from "../deviceList/DeviceListUpdate";
import AlarmConfiguration from "../deviceConfiguration/AlarmConfiguration";
import GeneralSettings from "../deviceConfiguration/GeneralSettings";
import Inventory from "../inventory/Inventory";
import Loader from "../common/loader/Loader";

class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      forceChartReRender: 0,
    };
  }

  handleSelect(eventKey) {
    //console.log("select: " + eventKey);
    if (eventKey === "liveDeviceStatus") {
      this.setState({ forceChartReRender: this.state.forceChartReRender + 1 });
    }
  }

  render() {
    return (
      <div>
        {this.props.isConnected ? null : <Loader />}
        <Row>
          <Col sm={4} className="col-aside">
            <DeviceListFilters></DeviceListFilters>
            <DeviceListSearch></DeviceListSearch>
            <DeviceList></DeviceList>{" "}
            <DeviceListPagination></DeviceListPagination>
            <DeviceListUpdate></DeviceListUpdate>
          </Col>
          <Col sm={8} className="col-main">
            <Tabs
              defaultActiveKey="liveDeviceStatus"
              onSelect={(eventKey) => this.handleSelect(eventKey)}
            >
              <Tab eventKey="liveDeviceStatus" title="Live Status">
                <DeviceTelemetryChart
                  forceChartReRender={this.state.forceChartReRender}
                ></DeviceTelemetryChart>
                <DeviceNotificationSubscription></DeviceNotificationSubscription>
                {/* <FakeDeviceTelemetryGenerator></FakeDeviceTelemetryGenerator> */}
              </Tab>
              <Tab eventKey="Analysis" title="Analysis">
                <AnalysisDeviceTelemetryChart></AnalysisDeviceTelemetryChart>
                {/* <DeviceTelemetryChartExample></DeviceTelemetryChartExample> */}
              </Tab>
              <Tab eventKey="Incidents" title="Incidents">
                {/* TODO: MAKE RECORDSPERPAGE CONFIGURABLE */}
                <DeviceIncidentList recordsPerPage={10} token={null} />
              </Tab>
              <Tab eventKey="ContactInfo" title="Information">
                <DeviceCustomerContactInformation></DeviceCustomerContactInformation>
              </Tab>
              <Tab eventKey="AlarmConfig" title="Alarm Configuration">
                <AlarmConfiguration></AlarmConfiguration>
              </Tab>
              <Tab eventKey="GeneralSettings" title="General Settings">
                <GeneralSettings></GeneralSettings>
              </Tab>
              <Tab eventKey="Inventory" title="Inventory">
                <Inventory></Inventory>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
    // isConnected: state.connection.isConnected,
// });

const mapStateToProps = (state) => {
    // console.log("STATE", state)
    return {
        isConnected: state.applicationState.isConnected,
    }
};

export default connect(mapStateToProps)(DashboardPage);
