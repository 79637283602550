export const getDeviceIncident = (state, deviceId) => {
  const filtered = state.incidentDetails.filter((i) => i.deviceId === deviceId);

  if (filtered.length > 0) return filtered[0];

  return {};
};

export default getDeviceIncident;

export const getIncidentList = (state) => state.incidentList;

export const getDeviceIncidentAlarmList = (state, incident) => {
  const filtered = state.incidentAlarmList.filter(
    (inc) => inc.incident === incident
  );
  if (filtered.length > 0) return filtered[0].alarms;

  return [];
};

export const getTokenForMoreIncidents = (state) => {
  let token = "";
  if (state.incidentList.length > 0) {
    token = state.incidentList[state.incidentList.length - 1].token;
  }

  return token;
};

export const getIncidentReportDropdownOptions = (state) => {
  // TODO: hook up to api etc

  // const reportOptions = [
  //   {
  //     value: 1,
  //     label: "Post Voltage Excursion (Product Temp)",
  //   },
  //   {
  //     value: 2,
  //     label: "Post Voltage Excursion (Fridge + Product Temp)",
  //   },
  //   {
  //     value: 3,
  //     label: "Post Door Open Event",
  //   },
  // ];

  let reportOptions = [];
  reportOptions = state.incidentReportDropdownList;

  return reportOptions;
};
