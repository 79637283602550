export const getDeviceAlarmConfigList = (state) => state.deviceAlarmConfigList;

export const getDeviceAlarmConfigInputs = (state) => {
  return state.deviceAlarmConfigList
    .map((item) => item.inputDescription)
    .filter((value, index, self) => self.indexOf(value) === index);
};

export const getNextSortOrder = (state) => {
  const highestSortOrder = Math.max(
    ...state.deviceAlarmConfigList.map((config) => config.sortOrder)
  );
  return highestSortOrder + 10;
};

export const showNewAlarmConfigDialog = (state) =>
  state.newAlarmConfigDialog.open;
