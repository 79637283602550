import React from "react";

const DeviceTemperature = ({ label, value }) => {
  let statusClass = "ok";
  if (value >= 10) statusClass = "alarm";

  return (
    <tr>
      <td className={statusClass}>{label}</td>
      <td className={statusClass}>{value} C</td>
    </tr>
  );
};

export default DeviceTemperature;
