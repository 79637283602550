import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function availabilityScheduleReducer(
  state = initialState.availabilitySchedule,
  action
) {
  switch (action.type) {
    case types.LOAD_AVAILABILITY_SCHEDULE_SUCCESS: {
      return action.schedule;
    }
    default:
      return state;
  }
}
