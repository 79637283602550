import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";
import { formatDateIgnoreOffset } from "../../utils/dateUtils";
import { getGraphSeries } from "../helpers/telemetryDataToChartSeriesHelper";

export default function analysisDeviceTelemetryChartDataReducer(
  state = initialState.analysisDeviceTelemetryChartData,
  action
) {
  switch (action.type) {
    case types.LOAD_ANALYSIS_DEVICE_TELEMETRY_DATA_SUCCESS: {
      let graphSeries = [];
      let time;
      let newState = Object.assign({}, state);

      action.telemetryData.forEach((element) => {
        time = formatDateIgnoreOffset(element.valueTime);
        graphSeries = getGraphSeries(newState, element, time);

        newState.series = graphSeries;
      });

      newState.deviceId = action.deviceId;

      return newState;
    }
    case types.RESET_ANALYIS_DEVICE_TELEMETRY_DATA: {
      return {
        deviceId: "",
        series: [],
      };
    }
    default:
      return state;
  }
}
