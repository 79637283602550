export class Error {
  parameters = [];
  constructor(originator, message, params) {
    this.originator = originator;
    this.message = message;
    this.created = Date(Date.now()).toString();
    if (params) {
      this.parameters = params;
    }
  }
}
