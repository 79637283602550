import React, { Component } from "react";
import { connect } from "react-redux";
import { getSelectedDevice } from "../deviceList/selectors/deviceListSelector";
import {
  getDeviceInventoryHistory,
  getDeviceInventoryHistoryToken,
} from "./selectors/deviceInventoryHistorySelector";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { formatDateIgnoreOffset } from "../utils/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import StockTable from "./StockTable";
import { loadDeviceInventoryHistoryMore } from "./actions/deviceInventoryActions";
import Button from "react-bootstrap/Button";
import { appConfig } from "../configuration/config";

class InventoryHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          dataField: "date",
          text: "Stock Changed",
          editable: false,
          formatter: this.dateFormatter,
        },
        {
          dataField: "itemsAddedCount",
          text: "Items Added",
          editable: false,
        },
        {
          dataField: "itemsRemovedCount",
          text: "Items Removed",
          editable: false,
        },
      ],
    };

    this.loadMoreStockEvents = this.loadMoreStockEvents.bind(this);
  }

  loadMoreStockEvents() {
    this.props.loadDeviceInventoryHistoryMore(
      this.props.device.id,
      appConfig.deviceInventoryHistoryRecordsPerPage,
      this.props.token
    );
  }

  dateFormatter(cell) {
    return <span>{formatDateIgnoreOffset(cell)}</span>;
  }

  render() {
    const expandRow = {
      renderer: (row) => (
        <div>
          <h5>Added:</h5>
          <StockTable stockList={row.itemsAdded}></StockTable>
          <h5>Removed:</h5>
          <StockTable stockList={row.itemsRemoved}></StockTable>
        </div>
      ),
      showExpandColumn: true, // set to true to show + sign
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) =>
        isAnyExpands ? (
          <FontAwesomeIcon className="expander" icon={faMinus} />
        ) : (
          <FontAwesomeIcon className="expander" icon={faPlus} />
        ),
      expandColumnRenderer: ({ expanded, rowKey, expandable }) =>
        expanded ? (
          <FontAwesomeIcon className="expander" icon={faMinus} />
        ) : (
          <FontAwesomeIcon className="expander" icon={faPlus} />
        ),
    };
    // let device = "No Device Selected";
    // if (!isEmpty(this.props.device)) {
    //   device = this.props.device.name;
    // }
    return (
      <div>
        <div>
          {/* Inventory History: {device} */}
          {/*<JSONPretty data={this.props.token}></JSONPretty> */}
        </div>
        <h5>Stock Change Events</h5>
        <div>
          <ToolkitProvider
            bootstrap4
            keyField="date"
            data={this.props.inventoryHistory}
            columns={this.state.columns}
            search={{ defaultSearch: "" }}
          >
            {(props) => (
              <div>
                <BootstrapTable
                  {...props.baseProps}
                  hover
                  expandRow={expandRow}
                  bordered
                  condensed
                />
              </div>
            )}
          </ToolkitProvider>
          <Button variant="primary" onClick={() => this.loadMoreStockEvents()}>
            More
          </Button>
          {/* <JSONPretty data={this.props.inventoryHistory}></JSONPretty> */}
        </div>
        {/* <JSONPretty data={this.props.inventoryHistory}></JSONPretty> */}
      </div>
    );
  }
}

InventoryHistory.defaultProps = {
  device: {},
  inventoryHistory: [],
  token: "token",
};

function mapStateToProps(state) {
  const device = getSelectedDevice(state);
  return {
    device: device,
    inventoryHistory: getDeviceInventoryHistory(state, device.id),
    token: getDeviceInventoryHistoryToken(state, device.id),
  };
}

const mapDispatchToProps = {
  loadDeviceInventoryHistoryMore,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryHistory);
