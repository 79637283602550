import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function generalDeviceSettingsReducer(
  state = initialState.generalDeviceSettings,
  action
) {
  switch (action.type) {
    case types.LOAD_GENERAL_DEVICE_SETTINGS_SUCCESS: {
      return action.settings;
    }
    // case types.RESET_DEVICE_ALARM_CONFIG_LIST: {
    //   return [];
    // }
    case types.UPDATE_GENERAL_DEVICE_SETTINGS_LOCATION: {
      return {
        ...state,
        customerLocationId: action.customerLocationId,
      };
    }
    default:
      return state;
  }
}
