import React, { Component } from "react";
import Item from "react-bootstrap/ListGroupItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DeviceTemperature from "./DeviceTemperature";
import DeviceDoor from "./DeviceDoor";
import DeviceVoltage from "./DeviceVoltage";
import DeviceListRowDevice from "./DeviceListRowDevice";
import { connect } from "react-redux";
import { setSelectedDevice } from "./actions/deviceListActions";
import {
  loadRecentHistoricalDeviceTelemetry,
  resetAnalysisDeviceTelemetryChart,
} from "../deviceTelemetry/actions/deviceTelemetryActions";
import DeviceFavourite from "./DeviceFavourite";
import { loadDeviceCustomerContactInfo } from "../customerContact/actions/customerContactActions";
import { resetDeviceAlarmConfigList } from "../deviceConfiguration/actions/alarmConfigActions";
import {
  loadDeviceInventory,
    loadDeviceInventoryHistory,
    loadDeviceInventorySummary,
} from "../inventory/actions/deviceInventoryActions";
import { resetDeviceIncidentList } from "../incidents/actions/incidentActions";
import { appConfig } from "../configuration/config";
class DeviceListRow extends Component {
  setSelectedDevice() {
    // TODO: look at moving this stuff to a more general 'reset ui' type area
    this.props.resetDeviceIncidentList();
    this.props.resetAnalysisDeviceTelemetryChart();
    this.props.setSelectedDevice(this.props.device);

    //console.log(this.props.device);
    const endDate = new Date();
    let startDate = new Date();
    //startDate.setMinutes(startDate.getMinutes() - 30);
    startDate.setHours(startDate.getHours() - 2);
    // console.log(
    //   `deviceId:${
    //     this.props.device.id
    //   }, startDate:${startDate.toISOString()}, endDate:${endDate.toISOString()}`
    // );

    this.props.loadRecentHistoricalDeviceTelemetry(
      this.props.device.id,
      startDate.toISOString(),
      endDate.toISOString(),
      this.props.device.hardwareId
    );

    this.props.loadDeviceCustomerContactInfo(this.props.device.id);

    this.props.resetDeviceAlarmConfigList();

      this.props.loadDeviceInventory(this.props.device.id);
      this.props.loadDeviceInventorySummary(this.props.device.id);
    this.props.loadDeviceInventoryHistory(
      this.props.device.id,
      appConfig.deviceInventoryHistoryRecordsPerPage,
      "token"
    );
    // need deviceId, currentTime, currenttime - 10 minutes
    // this.props.loadRecentHistoricalDeviceTelemetry
  }
  render() {
    return (
      <div>
        <Item className="device-list-item" action href="" onClick={() => this.setSelectedDevice()}>
            <Row className="mb-0 justify-content-between">
                <Col className="col-auto col-left">

                        <strong>{this.props.device.customerCode ? (
                            <p>{this.props.device.customerCode}</p>
                        ) : "-"}</strong>
                </Col>
                <Col className="col-6 col-details-right">
                    <table className="details-right ml-auto">
                        <tbody>
                            <DeviceVoltage value={this.props.device.voltageMain}></DeviceVoltage>
                            <DeviceTemperature value={this.props.device.temperatureProduct}></DeviceTemperature>
                            <DeviceDoor value={this.props.device.doorFridge}></DeviceDoor>
                        </tbody>
                    </table>
                    </Col>
                    <Col className="col-12">
                        <div className="details-right">
                            {this.props.device.name ? this.props.device.name : "-"}
                        </div>
                    </Col>

            </Row>
        </Item>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setSelectedDevice,
  loadRecentHistoricalDeviceTelemetry,
  loadDeviceCustomerContactInfo,
  resetDeviceAlarmConfigList,
  loadDeviceInventory,
    loadDeviceInventoryHistory,
    loadDeviceInventorySummary,
  resetDeviceIncidentList,
  resetAnalysisDeviceTelemetryChart,
};

export default connect(null, mapDispatchToProps)(DeviceListRow);
