import { callAdminApiPost, callAdminApiGet } from "../../api/apiUtils";

export async function getDeviceProvisioningTemplates(customerId) {
  const url = `api/provisioning/getdevicetemplates/${customerId}`;

  return callAdminApiGet(url);
}

export async function canReprovisionDevice(customerId, hardwareId) {
  const url = `api/provisioning/canreprovisiondevice/${customerId}/${hardwareId}`;

  return callAdminApiGet(url);
}

export async function provisionDevice(deviceProvisionSettings) {
  const url = `api/provisioning/provisiondevice`;

  return callAdminApiPost(url, deviceProvisionSettings);
}

export async function getNextDeviceId(customerId) {
  const url = `api/provisioning/getnextdeviceid/${customerId}`;

  return callAdminApiGet(url);
}

export async function commissionNewDevice(deviceCommissionNewSettings) {
  const url = `api/provisioning/commissionnewdevice`;

  return callAdminApiPost(url, deviceCommissionNewSettings);
}

export async function checkDeviceProvisioningStatus(customerId, hardwareId) {
  const url = `api/provisioning/checkdeviceprovisioning/${customerId}/${hardwareId}`;

  return callAdminApiGet(url);
}
