import React, { Component } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import DeviceListRow from "./DeviceListRow";
import { connect } from "react-redux";
import { loadDeviceList, filterByName, filterByCustomerCode, filterByVoltage, filterByProdTemp, sortByDoor } from "./actions/deviceListActions";
import { getFilteredDevices } from "./selectors/deviceListSelector";
import "../dashboard/Dashboard.css";
import PropTypes from "prop-types";
import DeviceListTableHeader from "./DeviceListTableHeader";

class DeviceList extends Component {
	state = {
    activeFilter: null, // No active filter initially
    sortOrder: "ASC", // Initial sort order
  };

  componentDidMount() {
    // TODO: needs to be customer specific
    const deviceGroupId = 1;
    this.props.loadDeviceList(deviceGroupId);
  }

	handleSort = (filter, sortOrder) => {
    this.setState({ activeFilter: filter, sortOrder });
    switch (filter) {
      case "Name":
        this.props.filterByName(sortOrder);
        break;
      case "Code":
        this.props.filterByCustomerCode(sortOrder);
        break;
      case "Voltage":
        this.props.filterByVoltage(sortOrder);
        break;
      case "Product":
        this.props.filterByProdTemp(sortOrder);
        break;
			case "Door":
				this.props.sortByDoor(sortOrder);
				break;
      default:
        break;
    }
  }

  render() {
    return (
      <ListGroup>
        <DeviceListTableHeader
          activeFilter={this.state.activeFilter}
          handleSort={this.handleSort}
        />
        {this.props.deviceList.map((device) => {
          return <DeviceListRow key={device.id} device={device} />;
        })}
      </ListGroup>
    );
  }
}

DeviceList.propTypes = {
  deviceList: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    deviceList: getFilteredDevices(state),
  };
}

const mapDispatchToProps = {
  loadDeviceList,
	filterByName,
	filterByCustomerCode,
	filterByVoltage,
	filterByProdTemp,
	sortByDoor
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceList);
