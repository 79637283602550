import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function incidentListReducer(
  state = initialState.incidentAlarmList,
  action
) {
  switch (action.type) {
    case types.LOAD_DEVICE_INCIDENT_ALARM_LIST_SUCCESS: {
      const alarmIncident = {
        incident: action.incident,
        alarms: action.incidentAlarmList,
      };

      if (state.length === 0) {
        let firstState = [];
        firstState.push(alarmIncident);
        return firstState;
      }

      const incidentIndex = state.findIndex(
        (inc) => inc.incident === action.incident
      );

      let newState = JSON.parse(JSON.stringify(state));

      if (incidentIndex > -1) {
        newState[incidentIndex].alarms = action.incidentAlarmList;
      } else {
        newState.push(alarmIncident);
      }

      return newState;
    }
    default:
      return state;
  }
}
