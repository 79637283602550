import React, { Component } from "react";
//import DeviceIncidentAlarmList from "./DeviceIncidentAlarmList";
import DeviceIncidentList from "./DeviceIncidentList";

// import ActiveCustomerIncidents from "./ActiveCustomerIncidents";
// import DeviceIncident from "./DeviceIncident";

class DeviceIncidentsPage extends Component {
  render() {
    return (
      <div>
        <h1>DeviceIncidentsPage</h1>
        {/* <ActiveCustomerIncidents deviceId="bytesnap-dev-08" />
        <DeviceIncident deviceId="bytesnap-dev-08" /> */}
        <DeviceIncidentList deviceId="454" recordsPerPage={10} token={null} />
      </div>
    );
  }
}

export default DeviceIncidentsPage;
