import React from "react";

const DeviceDoor = ({ label, value }) => {
  let statusClass = "ok";
  let doorState = "Closed";

  if (value) {
    statusClass = "alarm";
    doorState = "Open";
  }
  return (
    <tr>
      <td className={statusClass}>{label}</td>
      <td className={statusClass}>{doorState}</td>
    </tr>
  );
};

export default DeviceDoor;
