import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function errorReducer(state = initialState.errors, action) {
  switch (action.type) {
    case types.LOG_ERROR: {
      let newState = [...state];
      newState.push(action.error);

      return newState;
    }
    default:
      return state;
  }
}
