import React, { Component } from "react";
import { connect } from "react-redux";
import { getAnalysisTelemetrySeriesData } from "./selectors/deviceTelemetryChartDataSelector";
import {
    loadDeviceListAnalysisTelemetry,
    resetAnalysisDeviceTelemetryChart,
    submitDeviceAnalysisReportRequest,
} from "../deviceTelemetry/actions/deviceTelemetryActions";
import { getSelectedDevice } from "../deviceList/selectors/deviceListSelector";
import { getDates } from "../dataAnalysis/selectors/dataAnalysisSelector";
//import CustomChartLayoutAnalysis from "./CustomChartLayoutAnalysis";
import Chart from "react-apexcharts";
//import ApexCharts from "apexcharts";
import DateRange from "../date/DateRange";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty } from "../utils/generalUtils";
// import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { chartColours } from "./chartColours";

class AnalysisDeviceTelemetryChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialLoad: true,
            tooltipData2: {
                time: '--:--',
                dataset: [
                ],
                default: "Please hover over a data point to see more information."
            },
            options: {
                chart: {
                    id: "analysisChart",
                    height: 750,
                    type: "line",
                    zoom: {
                        type: "x",
                        enabled: true,
                        autoScaleYaxis: true,
                    },

                    events: {
                        dataPointMouseEnter: (event, chartContext, { seriesIndex, dataPointIndex }) => {
                            const data = chartContext.w.config.series[seriesIndex].data[dataPointIndex];
                            const xValue = data.x;
                            const allDataPoints = chartContext.w.config.series.map(series => series.data.find(point => point.x === xValue));
                            const labels = chartContext.w.config.series.map(series => series.name);
                            let formattedData = {
                                time: xValue,
                                dataset: [],
                            };

                            formattedData.dataset = allDataPoints.map((point, index) => ({
                                label: labels[index],
                                value: point.y
                            }));

                            //alert("here x")
                            //this.setTooltipData(formattedData);
                            this.setState({ tooltipData2: formattedData });
                        },
                    },

                },
                colors: chartColours,
//                animations: {
//                    enabled: false,
//                    easing: "linear",
//                    speed: 1000,
//                    animateGradually: {
//                        enabled: false,
//                        //delay: 150,
//                    },
//                    dynamicAnimation: {
//                        enabled: false,
//                        speed: 1000,
//                    },
//                },
                tooltip: {
                    enabled: true,
                    intersect: true,
                    shared: false,
                },
                markers: {
                    size: 4,
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                    lineCap: "round",
                    width: [1, 1, 1],
                },
                title: {
                    text: "Device Telemetry",
                    align: "left",
                },
                grid: {
                    row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                },
                yaxis: [{
                    opposite: true,
                    seriesName: "Door - Fridge",
                    tickAmount: 1,
                    decimalsInFloat: 1,
                    min: -0.1,
                    max: 1.1,
                    title: {
                        text: "Door / Compressor",
                    },
                    show: true,
                    showAlways: true,
                },
                    {
                        seriesName: "Temperature - Fridge",
                        tickAmount: 4,
                        show: true,
                        showAlways: true,
                        decimalsInFloat: 2,
                        min: 0.0,
                        title: {
                            text: "Temperature / Voltage",
                        },
                    },
                    {
                        opposite: true,
                        decimalsInFloat: 0,
                        min: -0.1,
                        max: 1.1,
                        seriesName: "Door - Fridge",
                        title: {
                            text: "Door - Magnet",
                        },
                        show: false,
                    },
                    {
                        seriesName: "Temperature - Fridge",
                        show: false,
                        min: 0.0,
                    },
                    {
                        seriesName: "Temperature - Fridge",
                        show: false,
                        min: 0.0,
                    },
                    {
                        seriesName: "Temperature - Fridge",
                        show: false,
                        min: 0.0,
                    },
                    {
                        opposite: true,
                        decimalsInFloat: 0,
                        min: -0.1,
                        max: 1.1,
                        seriesName: "Door - Fridge",
                        title: {
                            text: "Door - Magnet",
                        },
                        show: false,
                    },
                    {
                        seriesName: "Temperature - Fridge",
                        show: false,
                        min: 0.0,
                    },],
                xaxis: {
                    type: "time",
                    labels: {
                        format: "HH:mm",
                    },
                    title: {
                        text: "Time",
                    },
                    tickAmount: 10,
                },
            },
        };
    }

    setTooltipData(x) {
        alert("here");
        this.setState({ tooltipData2: x });
    }

    getYaxis(seriesData) {
        const doorSeries = {
            opposite: true,
            seriesName: "Door - Fridge",
            tickAmount: 1,
            decimalsInFloat: 1,
            min: -0.1,
            max: 1.1,
            title: {
                text: "Door / Compressor",
            },
            show: true,
            showAlways: true,
        };

        const tempFridgeSeries = {
            seriesName: "Temperature - Fridge",
            tickAmount: 4,
            show: true,
            showAlways: true,
            decimalsInFloat: 2,
            title: {
                text: "Temperature / Voltage",
            },
        };

        const yAxis = seriesData.map((s) => {
            switch (s.name) {
                case "Door - Fridge":
                    return doorSeries;
                case "Temperature - Fridge":
                    return tempFridgeSeries;
                case "Voltage - Main":
                case "Voltage - Main(Out)":
                case "Voltage - Battery":
                case "Temperature - Device Chip":
                case "Temperature - Product":
                case "Temperature - Air": {
                    const sharedTempSeries = {
                        seriesName: "Temperature - Fridge",
                        show: false,
                    };
                    return sharedTempSeries;
                }
                case "Compressor":
                case "Door - Magnet": {
                    const sharedDoorSeries = {
                        opposite: true,
                        decimalsInFloat: 0,
                        min: -0.1,
                        max: 1.1,
                        seriesName: "Door - Fridge",
                        title: {
                            text: "Door - Magnet",
                        },
                        show: false,
                    };
                    return sharedDoorSeries;
                }
                default: {
                    const series = {
                        seriesName: s.name,
                        show: false,
                    };

                    return series;
                }
            }
        });

        return yAxis;
    }

    //hideSeries() {
    //  if (this.props.seriesData.length > 0) {
    //    ApexCharts.exec(
    //      "analysisChart",
    //      "toggleSeries",
    //      "Temperature - Device Chip"
    //    );
    //  }
    //}

    componentDidUpdate() {
        // todo: causes problems with updates - graph redrawing
        //this.setChartOptions();
    }



    loadAnalysisData() {
        this.props.resetAnalysisDeviceTelemetryChart();

        var dtS = new Date(this.props.selectedDates.startDate);
        var dtE = new Date(this.props.selectedDates.endDate);
        var hours = Math.abs(dtS - dtE) / 36e5;

        if (hours > 48) {
            this.submitReport();
        } else {
            this.props.loadDeviceListAnalysisTelemetry(
                this.props.device.id,
                this.props.selectedDates.startDate,
                this.props.selectedDates.endDate
            );
        }

        this.setState({
            initialLoad: false,
        });

        this.setChartOptions();
    }

    setChartOptions() {
        if (this.props.seriesData.length > 0) {
            const maxValue = Math.max(
                ...this.props.seriesData.flatMap(serie => serie.data.map(point => point.y))
            );

            var yaxis_temp = this.getYaxis(this.props.seriesData);

            var options_temp = this.state.options;

            options_temp.yaxis = yaxis_temp;
            options_temp.max = maxValue + 1;


            this.setState({ options: options_temp });
        }
    }

    submitReportNotify() {
        toast.success(
            "As your selection spans more than 48 hours the data will be delivered by email shortly.",
            {
                hideProgressBar: true,
                autoClose: 10000,
            }
        );
    }

    submitReport() {
        const reportParams = {
            DeviceId: this.props.device.id,
            StartTime: this.props.selectedDates.startDate,
            EndTime: this.props.selectedDates.endDate,
        };

        this.props.submitDeviceAnalysisReportRequest(reportParams);
        this.submitReportNotify();
    }



    renderInitialLoad() {
        let initialLoad;

        if (this.state.initialLoad) {
            initialLoad = (
                <div>
                    Select a device, data range and press Load Data to view a chart
                </div>
            );
        }

        return initialLoad;
    }

    renderChart() {
        let chart;

        if (!this.state.initialLoad && this.props.seriesData.length > 0) {
            chart = (
                <div className="chart-container">
                    <div className="chart" >
                        <Chart
                            options={this.state.options}
                            series={this.props.seriesData}
                            type="line"
                            height={750}
                        />
                    </div>
                    <div className="aside" >
                        {this.state.tooltipData2 && (
                            <div className="aside-inner">
                                <div className="aside-inner-head">
                                    <p>{this.state.tooltipData2.time}</p>
                                </div>
                                {this.state.tooltipData2.dataset.length ? (
                                    <div className='aside-inner-body'>
                                        {this.state.tooltipData2.dataset.map((data, index) => (
                                            <Row className="mb-0 justify-content-between">
                                                <Col className="col-auto col-left">
                                                    <p><span className={'dot dot' + index}></span>{data.label}:</p>
                                                </Col>
                                                <Col className="col-3 col-details-right">
                                                    <p><strong>{data.value}</strong>&nbsp;</p>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                ) : (
                                    <div className='aside-inner-body'>
                                            <p>{this.state.tooltipData2.default}</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
        } else {
            chart = <div>No data for selected device</div>;
        }
        return chart;
    }

    render() {
        let device = "No Device Selected";
        if (!isEmpty(this.props.device)) {
            device = this.props.device.name;
        }

        return (
            <div className="content-container">
                <h4>{device} </h4>
                {/* <h2>this.props.seriesData</h2>        
        <div>{JSON.stringify(this.props.seriesData)}</div> */}
                <Row>
                    <DateRange></DateRange>
                    <Col sm>
                        <Button
                            variant="primary"
                            onClick={this.loadAnalysisData.bind(this)}
                        >
                            Load Data
                        </Button>
                    </Col>
                </Row>
                {this.renderInitialLoad()}
                {this.renderChart()}
                {/* <JSONPretty data={this.props.seriesData}></JSONPretty> */}
            </div>
        );
    }
}

AnalysisDeviceTelemetryChart.defaultProps = {
    seriesData: [],
    device: {},
};

function mapStateToProps(state, ownProps) {
    const seriesData = getAnalysisTelemetrySeriesData(state);
    const device = getSelectedDevice(state);
    let selectedDates = {};
    if (!isEmpty(device)) {
        selectedDates = getDates(state);
    }

    return {
        seriesData: seriesData,
        device: device,
        selectedDates: selectedDates,
    };
}

const mapDispatchToProps = {
    loadDeviceListAnalysisTelemetry,
    resetAnalysisDeviceTelemetryChart,
    submitDeviceAnalysisReportRequest,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnalysisDeviceTelemetryChart);
