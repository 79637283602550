import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSelectedDevice } from "../deviceList/selectors/deviceListSelector";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Datetime from "react-datetime";
import * as dataAnalysisActions from "../dataAnalysis/actions/dataAnalysisActions";
import moment from "moment";
import "react-datetime/css/react-datetime.css";

class DateRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
    };

    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.setPresetTime = this.setPresetTime.bind(this);
  }

  isValidDate(date) {
    return moment.isDate(date) || moment.isMoment(date);
  }

  setStartDate(startDate) {
    if (this.isValidDate(startDate)) {
      this.props.actions.saveStartDate(startDate.toISOString());
    }

    this.setState({ startDate: startDate });
  }

  setEndDate(endDate) {
    if (this.isValidDate(endDate)) {
      this.props.actions.saveEndDate(endDate.toISOString());
    }
    this.setState({ endDate: endDate });
  }

  setPresetTime(time) {
    let startDate = new Date();
    let endDate = new Date();

    switch (time) {
      case "Last Hour": {
        endDate.setHours(endDate.getHours() - 1);
        break;
      }
      case "Last 2 Hours": {
        endDate.setHours(endDate.getHours() - 2);
        break;
      }
      case "Last 6 Hours": {
        endDate.setHours(endDate.getHours() - 6);
        break;
      }
      case "Last 12 Hours": {
        endDate.setHours(endDate.getHours() - 12);
        break;
      }
      case "Last 24 Hours": {
        endDate.setHours(endDate.getHours() - 24);
        break;
      }
      default: {
        endDate.setHours(endDate.getHours() - 1);
        break;
      }
    }

    this.setStartDate(endDate);
    this.setEndDate(startDate);
  }

  render() {
    return (
      <>
        <Col sm>
          <Datetime
            value={this.state.startDate}
            dateFormat="yyyy-MM-DD"
            timeFormat="HH:mm"
            onChange={this.setStartDate}
          />
        </Col>
        <Col lg>
          <Datetime
            value={this.state.endDate}
            dateFormat="yyyy-MM-DD"
            timeFormat="HH:mm"
            onChange={this.setEndDate}
          />
        </Col>
        <Col sm>
          <DropdownButton id="dropdown-basic-button" title="Quick preset">
            <Dropdown.Item
              onSelect={() => this.setPresetTime("Last Hour")}
              href=""
            >
              Last hour
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() => this.setPresetTime("Last 2 Hours")}
              href=""
            >
              Last 2 hours
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() => this.setPresetTime("Last 6 Hours")}
              href=""
            >
              Last 6 hours
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() => this.setPresetTime("Last 12 Hours")}
              href=""
            >
              Last 12 hours
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() => this.setPresetTime("Last 24 Hours")}
              href=""
            >
              Last 24 hours
            </Dropdown.Item>
          </DropdownButton>
        </Col>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    device: getSelectedDevice(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveStartDate: bindActionCreators(
        dataAnalysisActions.saveStartDate,
        dispatch
      ),
      saveEndDate: bindActionCreators(
        dataAnalysisActions.saveEndDate,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DateRange);
