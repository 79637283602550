import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { loadTimezones } from "../deviceConfiguration/actions/alarmConfigActions";
import { getTimezones } from "../deviceConfiguration/selectors/alarmContactSelector";
import { updateGeneralSettingLocation } from "../deviceConfiguration/actions/generalSettingsActions";
import {
  upsertLocation,
  loadCustomerLocations,
} from "./actions/customerActions";

class CustomerLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerLocationId: this.props.location.customerLocationId,
      description: this.props.location.description ?? "",
      shortName: this.props.location.shortName ?? "",
      address: this.props.location.address ?? "",
      postcode: this.props.location.postcode ?? "",
      timezoneId: this.props.location.timeZoneId ?? null,
      timezoneName: this.props.location.timeZoneName ?? "",
      gpsCoordinates: this.props.location.gpsCoordinates ?? null,
      physicalRegion: this.props.location.physicalRegion ?? "",
      logicalReason: this.props.location.logicalReason ?? null,
      customerRef: this.props.location.customerRef ?? "",
      errors: {},
    };

    this.updateTimezone = this.updateTimezone.bind(this);
    this.saveLocation = this.saveLocation.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  closeDialog() {
    this.props.close();
  }

  updateDescription(e) {
    this.setState({ description: e.target.value });
  }

  updateShortName(e) {
    this.setState({ shortName: e.target.value });
  }

  updateAddress(e) {
    this.setState({ address: e.target.value });
  }

  updateTimezone(e) {
    let timezoneId = null;

    timezoneId = e.target.value;

    this.setState({ timezoneId: timezoneId });
  }

  updatePhysicalRegion(e) {
    this.setState({ physicalRegion: e.target.value });
  }

  updateCustomerRef(e) {
    this.setState({ customerRef: e.target.value });
  }

  componentDidMount() {
    this.props.loadTimezones();
  }

  saveLocation() {
    const newErrors = this.validateInput();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors });
      return;
    }

    let customerLocationId = null;
    if (this.state.customerLocationId !== 1) {
      customerLocationId = this.state.customerLocationId;
    }

    const customerLocation = {
      customerLocationId: customerLocationId,
      description: this.state.description,
      shortName: this.state.shortName,
      address: this.state.address,
      postcode: this.state.postcode,
      timeZoneId: this.state.timezoneId,
      gpsCoordinates: this.state.gpsCoordinates,
      physicalRegion: this.state.physicalRegion,
      logicalReason: this.state.logicalReason,
      customerRef: this.state.customerRef,
    };

    this.props.upsertLocation(customerLocation).then(() => {
      this.closeDialog();
    });
  }

  validateInput() {
    const newErrors = {};
    if (this.state.description === "") {
      newErrors.description = "Please enter a description";
    }

    if (this.state.shortName === "") {
      newErrors.shortName = "Please enter a short name";
    }

    if (this.state.address === "") {
      newErrors.address = "Please enter an address";
    }

    if (this.state.physicalRegion === "") {
      newErrors.physicalRegion = "Please enter a physical region";
    }

    return newErrors;
  }

  render() {
    return (
      <div>
        <Form className="form-group-container">
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              size="sm"
              value={this.state.description}
              isInvalid={!!this.state.errors.description}
              onChange={(e) => this.updateDescription(e)}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {this.state.errors.description}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Short Name</Form.Label>
            <Form.Control
              size="sm"
              value={this.state.shortName}
              isInvalid={!!this.state.errors.shortName}
              onChange={(e) => this.updateShortName(e)}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {this.state.errors.shortName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Address</Form.Label>
            <Form.Control
              size="sm"
              value={this.state.address}
              isInvalid={!!this.state.errors.address}
              onChange={(e) => this.updateAddress(e)}
            ></Form.Control>

            <Form.Control.Feedback type="invalid">
              {this.state.errors.address}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Time Zone</Form.Label>
            <select
              className="form-control form-control-sm"
              onChange={this.updateTimezone}
              value={this.state.timezoneId}
            >
              {this.props.timezones.map((item, index) => {
                return <option value={item.timezoneId}>{item.name}</option>;
              })}
            </select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Physical Region</Form.Label>
            <Form.Control
              size="sm"
              value={this.state.physicalRegion}
              isInvalid={!!this.state.errors.physicalRegion}
              onChange={(e) => this.updatePhysicalRegion(e)}
            ></Form.Control>

            <Form.Control.Feedback type="invalid">
              {this.state.errors.physicalRegion}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Customer Ref</Form.Label>
            <Form.Control
              size="sm"
              value={this.state.customerRef}
              onChange={(e) => this.updateCustomerRef(e)}
            ></Form.Control>

            <Form.Control.Feedback type="invalid">
              {this.state.errors.customerRef}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Row>
            <Col className="float-right">
              <Button
                size="sm"
                variant="primary"
                className="form-group-button float-right actions-button"
                onClick={this.closeDialog}
              >
                Cancel
              </Button>
              <Button
                className="float-right actions-button"
                size="sm"
                variant="primary"
                onClick={this.saveLocation}
              >
                Save
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </div>
    );
  }
}

CustomerLocation.defaultProps = {
  timezones: [],
};

function mapStateToProps(state) {
  return {
    timezones: getTimezones(state),
  };
}

const mapDispatchToProps = {
  loadTimezones,
  upsertLocation,
  loadCustomerLocations,
  updateGeneralSettingLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLocation);
