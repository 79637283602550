import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function incidentReportDropdownListReducer(
  state = initialState.incidentReportDropdownList,
  action
) {
  switch (action.type) {
    case types.LOAD_INCIDENT_REPORT_DROPDOWN_LIST_SUCCESS: {
      return action.dropdownlist;
    }
    default:
      return state;
  }
}
