import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function deviceInventoryReducer(
  state = initialState.deviceInventory,
  action
) {
  switch (action.type) {
    case types.LOAD_DEVICE_INVENTORY_SUCCESS: {
      const deviceInventory = {
        deviceId: action.deviceId,
        inventory: action.deviceInventory.inventoryList,
        undientifiedTags: action.deviceInventory.undientifiedTags,
        totalItems: action.deviceInventory.totalItems,
          productCounts: action.deviceInventory.productCounts,
          scanCheckResult: action.deviceInventory.scanCheckResult,
      };

      if (state.length === 0) {
        let firstState = [];
        firstState.push(deviceInventory);
        return firstState;
      }

      const deviceIndex = state.findIndex(
        (inv) => inv.deviceId === action.deviceId
      );
      if (deviceIndex > -1) {
        let newState = JSON.parse(JSON.stringify(state));
        newState[deviceIndex].inventory = action.deviceInventory.inventoryList;
        newState[deviceIndex].undientifiedTags = action.deviceInventory.undientifiedTags;
        newState[deviceIndex].totalItems = action.deviceInventory.totalItems;
          newState[deviceIndex].productCounts = action.deviceInventory.productCounts;
          newState[deviceIndex].scanCheckResult = action.deviceInventory.scanCheckResult;

        return newState;
      } else {
        return [...state, deviceInventory];
      }
    }
    default:
      return state;
  }
}
