import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function incidentDetailsReducer(
  state = initialState.incidentDetails,
  action
) {
  switch (action.type) {
    case types.LOAD_INCIDENT_DETAILS_SUCCESS: {
      if (state.length === 0) {
        let firstState = [];
        firstState.push(action.incidentDetails);
        return firstState;
      }

      let newState = [...state];
      let index = newState.findIndex(
        (inc) => inc.id === action.incidentDetails.id
      );

      if (index === -1) {
        newState.push(action.incidentDetails);
      } else {
        newState[index] = action.incidentDetails;
      }

      return newState;
    }
    default:
      return state;
  }
}
