import React, { Component } from "react";
import { connect } from "react-redux";
import { UserRoles } from "../api-authorization/ApiAuthorizationConstants";
import {
  getAllCustomers,
  setCustomerDetails,
} from "../customer/actions/customerActions";
import { getCustomers } from "../customer/selectors/customersSelector";
import { getCurrentUser } from "./selectors/usersSelector";
import UsersList from "./UsersList";
class UsersAdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSuperAdmin: false,
      customerId: 0,
      customerName: "",
    };

    this.setSelectedCustomer = this.setSelectedCustomer.bind(this);
  }

  componentDidMount() {
    if (this.props.currentUser?.role) {
      if (this.props.currentUser.role === UserRoles.SuperAdmin) {
        this.props.getAllCustomers();
        this.setState({ isSuperAdmin: true });
      } else {
        this.setState({ customerId: this.props.currentUser.customerId });
      }
    }
  }

  setSelectedCustomer(e) {
    const customerId = parseInt(e.target.value);
    let customer = this.props.customers.find((c) => c.id === customerId);

    const storeCustomer = {
      id: customer.id,
      name: customer.name,
    };
    this.props.setCustomerDetails(storeCustomer);

    this.setState({ customerId: e.target.value, customerName: customer.name });
  }

  render() {
    return (
      <div className="content-container">
        {/* <JSONPretty data={this.state} /> */}
        {/* <JSONPretty data={this.props.customers} /> */}
        {/* <JSONPretty data={this.props.currentUser} /> */}
        <div className="container">
          <div className="row">
            <div className="col">
              {this.state.isSuperAdmin && (
                <>
                  <h4>Selected Customer: {this.state.customerName}</h4>
                  <select
                    className="form-control form-control-sm"
                    onChange={this.setSelectedCustomer}
                    value={this.state.customerId}
                  >
                    {this.props.customers.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                  <br />
                  <br />
                </>
              )}
              <UsersList customerId={this.state.customerId}></UsersList>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UsersAdminPage.defaultProps = {
  customers: [],
  currentUser: {},
};

function mapStateToProps(state) {
  return {
    customers: getCustomers(state),
    currentUser: getCurrentUser(state),
  };
}

const mapDispatchToProps = {
  getAllCustomers,
  setCustomerDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersAdminPage);
