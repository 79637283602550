import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import Button from "react-bootstrap/Button";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import JSONPretty from "react-json-pretty";

class OtaGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: "DeviceId",
          minWidth: 180,
          field: "hardwareId",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          sortable: true,
        },
        {
          field: "softwareVersion",
          filter: "agNumberColumnFilter",
          //maxWidth: 100,
          sortable: true,
        },
        { field: "updateStatus" },
      ],
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
      },
      rowSelection: "multiple",
      rowData: null,
      selectedRows: null,
    };
  }

  componentDidMount() {
    let rowData = [];
    for (let i = 0; i < 300; i++) {
      const idNumber = i + 1;
      let hardwareId = "byte-snap-dev-";
      if (idNumber < 10) hardwareId += "0" + idNumber;
      else hardwareId += idNumber;

      let updateStatus = "Success";
      if (i > 4 && i < 10) {
        updateStatus = "Updating";
      }

      if (i > 12 && i < 15) {
        updateStatus = "Failed";
      }
      const row = {
        hardwareId: hardwareId,
        softwareVersion: "1.01",
        updateStatus: updateStatus,
      };

      rowData.push(row);
    }

    this.setState({ rowData: rowData });
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    // this.gridColumnApi = params.columnApi;
    // const updateData = (data) => params.api.setRowData(data);
    // fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
    //   .then((resp) => resp.json())
    //   .then((data) => updateData(data));
    //for (i = 0; i < 10; i++)
  };

  onButtonClick(e) {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    this.setState({ selectedRows: selectedData });
  }

  render() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            onGridReady={this.onGridReady}
            //onSelectionChanged={this.onSelectionChanged.bind(this)}
            groupSelectsFiltered={true}
            rowMultiSelectWithClick={true}
            rowData={this.state.rowData}
            // paginationAutoPageSize={true}
            // pagination={true}
          />
          <br />
          <Button onClick={() => this.onButtonClick()}>
            Update Selected Rows
          </Button>
          <br />
          <br />
          <h6>Debug - Selected Rows to Update:</h6>
          <JSONPretty data={this.state.selectedRows} />
        </div>
      </div>
    );
  }
}

export default OtaGrid;
