import React from "react";

const DeviceVoltage = ({ label, value }) => {
  let statusClass = "ok";
  // TODO: get range of voltages
  return (
    <tr>
      <td className={statusClass}>{label}</td>
      <td className={statusClass}>{value} v</td>
    </tr>
  );
};

export default DeviceVoltage;
