import * as provisioningApi from "../api/provisioningApi";
import * as types from "../../common/state/actionTypes";
import { Error } from "../../errors/errorType";
import { logError } from "../../errors/actions/errorActions";

export function loadDeviceProvisioningTemplatesSuccess(deviceTemplates) {
  return { type: types.DEVICE_PROVISIONING_TEMPLATES_LOADED, deviceTemplates };
}

export function loadDeviceProvisioningTemplates(customerId) {
  return function (dispatch) {
    return provisioningApi
      .getDeviceProvisioningTemplates(customerId)
      .then((deviceTemplates) => {
        dispatch(loadDeviceProvisioningTemplatesSuccess(deviceTemplates));
      })
      .catch((error) => {
        const params = [
          {
            customerId: customerId,
          },
        ];
        const err = new Error(
          "provisioningActions.js, loadDeviceProvisioningTemplates()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function reprovisionDeviceCheck(customerId, hardwareId) {
  return function (dispatch) {
    return provisioningApi
      .canReprovisionDevice(customerId, hardwareId)
      .catch((error) => {
        const params = [
          {
            customerId: customerId,
            hardwareId: hardwareId,
          },
        ];
        const err = new Error(
          "provisioningActions.js, reprovisionDeviceCheck()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function provisionDevice(deviceProvisionSettings) {
  return function (dispatch) {
    return provisioningApi
      .provisionDevice(deviceProvisionSettings)
      .catch((error) => {
        const params = [
          {
            deviceProvisionSettings: deviceProvisionSettings,
          },
        ];
        const err = new Error(
          "provisioningActions.js, provisionDevice()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function getNextDeviceId(customerId) {
  return function (dispatch) {
    return provisioningApi.getNextDeviceId(customerId).catch((error) => {
      const params = [
        {
          customerId: customerId,
        },
      ];
      const err = new Error(
        "provisioningActions.js, getNextDeviceId()",
        error.toString(),
        params
      );

      dispatch(logError(err));
    });
  };
}

export function commissionNewDevice(deviceCommissionNewSettings) {
  return function (dispatch) {
    return provisioningApi
      .commissionNewDevice(deviceCommissionNewSettings)
      .catch((error) => {
        const params = [
          {
            deviceCommissionNewSettings: deviceCommissionNewSettings,
          },
        ];
        const err = new Error(
          "provisioningActions.js, commissionNewDevice()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function checkDeviceProvisioningStatus(customerId, hardwareId) {
  return function (dispatch) {
    return provisioningApi
      .checkDeviceProvisioningStatus(customerId, hardwareId)
      .catch((error) => {
        const params = [
          {
            customerId: customerId,
            hardwareId: hardwareId,
          },
        ];
        const err = new Error(
          "provisioningActions.js, checkDeviceProvisioningStatus()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}
