import {
  callFridgeInsightsApiGet,
  callAdminApiPost,
  callAdminApiGet,
} from "../../api/apiUtils";

import {
  FridgeInsightsCustomerApi,
  FridgeInsightsDeviceApi,
} from "../../configuration/config";
import authService from "../../api-authorization/AuthorizeService";

// Azure API
export async function getActiveCustomerIncidents() {
  // TODO:
  // const token = await authService.getAccessToken();
  // todo get customer stuff from login auth
  const customer = "INSERVIO";
  const url = `${FridgeInsightsCustomerApi}/${customer}/incidents/active`;

  return callFridgeInsightsApiGet(url);
}

// Azure API
// API - TAKES EITHER THE INCIDENT ID  or 'last'
export async function getDeviceIncident(deviceId, incidentId) {
  const url = `${FridgeInsightsDeviceApi}/${deviceId}/incidents/${incidentId}`;

  return callFridgeInsightsApiGet(url);
}

// Sql Server
export async function getDeviceIncidentList(deviceId, recordsPerPage, token) {
  const url = "api/incident/list";
  const data = {
    DeviceId: deviceId,
    RecordsPerPage: recordsPerPage,
    Token: token,
  };

  return callAdminApiPost(url, data);
}

export async function getDeviceIncidentAlarmList(deviceId, incident) {
  const url = `api/incident/alarmlist/${deviceId}/${incident}`;

  return callAdminApiGet(url);
}

export async function submitDeviceIncidentReportRequest(reportParams) {
  const url = "api/incident/reportrequest";

  return callAdminApiPost(url, reportParams);
}

export async function getIncidentReportDropdownList() {
  const url = `api/incident/reportdropdown`;
  return callAdminApiGet(url);
}

export async function acknowledgeAlarm(incidentAlarmId, deviceId) {
  const user = await authService.getUser();
  var incidentAckRequest = {
    IncidentAlarmId: incidentAlarmId,
    UserId: user.userId,
    AcknowledgeFlag: "Y",
    DeviceId: deviceId,
  };

  const url = "api/incident/acknowledgealarm";

  return callAdminApiPost(url, incidentAckRequest);
}
