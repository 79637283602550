import React, { useEffect } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { deviceListUpdateData } from "./actions/deviceListActions";
import { connect } from "react-redux";
import authService from "../api-authorization/AuthorizeService";

const signalRConfiguration = {
  hubStatusUrl: "/hubs/devicelist",
};

function DeviceListUpdate({ devices, deviceListUpdateData }) {
  useEffect(() => {
    async function connectSecurelyToStatusHub() {
      const token = await authService.getAccessToken();
      const user = await authService.getUser();
      const userId = parseInt(user.userId);

      const connection = new HubConnectionBuilder()
        .withUrl(signalRConfiguration.hubStatusUrl, {
          accessTokenFactory: () => {
            return token;
          },
        })
        .withAutomaticReconnect()
        .build();

      connection
        .start()
        .then(() => {
          // TODO: sort out where to get this
          const deviceGroupId = 1;
          // console.log("Connnected to controller status hub");
          connection.invoke("GetDeviceListUpdate", userId, deviceGroupId);
          connection.on("DeviceListUpdate", (devices) => {
            deviceListUpdateData(devices);
            // console.log(`Device List Upate for User:${userId}`);
            // console.log(devices);
          });
        })
        .catch((e) => console.log("Connection failed: ", e));

      return () => {
        connection.stop();
      };
    }

    connectSecurelyToStatusHub();
  }, [devices, deviceListUpdateData]);

  return <div></div>;
}

const mapDispatchToProps = {
  deviceListUpdateData,
};

export default connect(null, mapDispatchToProps)(DeviceListUpdate);
