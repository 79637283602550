import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";
import {
  getTime,
  getTimeWithoutTimezoneConversion,
} from "../../utils/dateUtils";
import { getGraphSeries } from "../helpers/telemetryDataToChartSeriesHelper";

export default function deviceTelemetryChartDataReducer(
  state = initialState.deviceTelemetryChartData,
  action
) {
  switch (action.type) {
    case types.DEVICE_TELEMETRY_DATA_UPDATE: {
      const time = getTimeWithoutTimezoneConversion(
        action.deviceData.dataTimeLoc
      );

      let stateDevice = state.find(
        (d) => d.deviceId === action.deviceData.deviceId
      );

      let newDevice = false;
      if (stateDevice === undefined) {
        newDevice = true;
        stateDevice = {
          deviceId: action.deviceData.deviceId,
          series: [],
          dataPointCount: 0,
          maxDataPointCount: 10,
        };
      }
      let graphSeries = getGraphSeries(stateDevice, action.deviceData, time);

      if (state.length === 0) {
        stateDevice.series = graphSeries;
        stateDevice.dataPointCount = 1;
        let newState = [];
        newState.push(stateDevice);
        return newState;
      }

      if (newDevice) {
        stateDevice.series = graphSeries;
        stateDevice.dataPointCount = 1;

        return [...state, stateDevice];
      }

      return state.map((device) => {
        if (device.deviceId === action.deviceData.deviceId) {
          return {
            ...device,
            series: graphSeries,
            dataPointCount: device.dataPointCount + 1,
          };
        }
        return device;
      });
    }
    case types.RESET_LIVE_DEVICE_TELEMETRY_DATA: {
      return {
        deviceId: "",
        series: [],
        dataPointCount: 0,
        maxDataPointCount: 10,
      };
    }
    case types.LOAD_RECENT_TELEMETRY_DATA_SUCCESS: {
      let graphSeries = [];
      let time;

      let stateDevice = {
        deviceId: action.deviceId,
        series: [],
        dataPointCount: 0,
        maxDataPointCount: 10,
      };

      if (action.telemetryData.length > 10) {
        action.telemetryData = action.telemetryData.slice(-10);
      }

      action.telemetryData.forEach((element) => {
        time = getTime(element.valueTime);
        graphSeries = getGraphSeries(stateDevice, element, time);
        stateDevice.series = graphSeries;
      });

      stateDevice.dataPointCount = action.telemetryData.length;

      return [...state, stateDevice];
    }
    default:
      return state;
  }
}
