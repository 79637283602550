import { callAdminApiGet } from "../../api/apiUtils";
import authService from "../../api-authorization/AuthorizeService";

export async function getDeviceList(deviceGroupId) {
  const user = await authService.getUser();
  //  console.log(`userId:${user.userId}, customerId:${user.customerId}`);
  const url = `api/device/getdevicelist/${user.userId}/${deviceGroupId}`;

  return callAdminApiGet(url);
}
