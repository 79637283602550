import * as deviceInventoryApi from "../api/deviceInventoryApi";
import * as types from "../../common/state/actionTypes";
import { Error } from "../../errors/errorType";
import { logError } from "../../errors/actions/errorActions";

export function loadDeviceInventorySuccess(deviceInventory, deviceId) {
  return {
    type: types.LOAD_DEVICE_INVENTORY_SUCCESS,
    deviceInventory,
    deviceId,
  };
}

export function loadDeviceInventoryProductCountSuccess(productTotals, deviceId) {
    return {
        type: types.LOAD_DEVICE_INVENTORY_PRODUCT_COUNT_SUCCESS,
        productTotals,
        deviceId,
    };
}

export function loadDeviceInventoryHistorySuccess(
  deviceInventoryHistory,
  deviceId
) {
  return {
    type: types.LOAD_DEVICE_INVENTORY_HISTORY_SUCCESS,
    deviceInventoryHistory,
    deviceId,
  };
}

export function loadDeviceInventorySummarySuccess(
    deviceInventorySummary,
    deviceId
) {
    return {
        type: types.LOAD_DEVICE_INVENTORY_SUMMARY_SUCCESS,
        deviceInventorySummary,
        deviceId,
    };
}

export function loadDeviceInventoryHistoryMoreSuccess(
  deviceInventoryHistory,
  deviceId
) {
  return {
    type: types.LOAD_DEVICE_INVENTORY_HISTORY_MORE_SUCCESS,
    deviceInventoryHistory,
    deviceId,
  };
}

export function refreshDeviceInventoryHistorySuccess(
  deviceInventoryHistory,
  deviceId
) {
  return {
    type: types.REFRESH_DEVICE_INVENTORY_HISTORY_SUCCESS,
    deviceInventoryHistory,
    deviceId,
  };
}

export function refreshDeviceInventorySummarySuccess(
    deviceInventorySummary,
    deviceId
) {
    return {
        type: types.REFRESH_DEVICE_INVENTORY_SUMMARY_SUCCESS,
        deviceInventorySummary,
        deviceId,
    };
}
export function loadDeviceInventory(deviceId) {
  return function (dispatch) {
    return deviceInventoryApi
      .getDeviceInventory(deviceId)
      .then((deviceInventory) => {
        dispatch(loadDeviceInventorySuccess(deviceInventory, deviceId));
      })
      .catch((error) => {
        const err = new Error(
          "deviceInventoryActions.js, loadDeviceInventory()",
          error.toString()
        );
        dispatch(logError(err));
      });
  };
}

export function loadDeviceInventoryProductCounts(deviceId) {
    return function (dispatch) {
        return deviceInventoryApi
        .getDeviceInventoryProductCounts
            .then((productTotals) => {
                dispatch(loadDeviceInventoryProductCountSuccess(productTotals, deviceId));
            })
            .catch((error) => {
                const err = new Error(
                    "deviceInventoryActions.js, loadDeviceInventoryProductCounts()",
                    error.toString()
                );
                dispatch(logError(err));
            });
    };
}
export function inventoryReviseExpiry(reviseExpiry) {
  return function (dispatch) {
    return deviceInventoryApi.reviseExpiry(reviseExpiry).catch((error) => {
      const params = [
        {
          reviseExpiry: reviseExpiry,
        },
      ];
      const err = new Error(
        "deviceInventoryActions.js, inventoryReviseExpiry",
        error.toString(),
        params
      );

      dispatch(logError(err));
    });
  };
}

export function loadDeviceInventoryHistory(deviceId, recordsPerPage, token) {
  return function (dispatch) {
    return deviceInventoryApi
      .getDeviceInventoryHistory(deviceId, recordsPerPage, token)
      .then((deviceInventoryHistory) => {
        dispatch(
          loadDeviceInventoryHistorySuccess(deviceInventoryHistory, deviceId)
        );
      })
      .catch((error) => {
        const err = new Error(
          "deviceInventoryActions.js, loadDeviceInventoryHistory()",
          error.toString()
        );
        dispatch(logError(err));
      });
  };
}

export function loadDeviceInventoryHistoryMore(
  deviceId,
  recordsPerPage,
  token
) {
  return function (dispatch) {
    return deviceInventoryApi
      .getDeviceInventoryHistory(deviceId, recordsPerPage, token)
      .then((deviceInventoryHistory) => {
        dispatch(
          loadDeviceInventoryHistoryMoreSuccess(
            deviceInventoryHistory,
            deviceId
          )
        );
      })
      .catch((error) => {
        const err = new Error(
          "deviceInventoryActions.js, loadDeviceInventoryHistory()",
          error.toString()
        );
        dispatch(logError(err));
      });
  };
}

export function loadDeviceInventorySummary(deviceId) {
    return function (dispatch) {
        return deviceInventoryApi
            .getDeviceInventorySummary(deviceId)
            .then((deviceInventorySummary) => {
                dispatch(loadDeviceInventorySummarySuccess(deviceInventorySummary, deviceId));
            })
            .catch((error) => {
                const err = new Error(
                    "deviceInventoryActions.js, loadDeviceInventorySummary()",
                    error.toString()
                );
                dispatch(logError(err));
            });
    };
}

export function refreshDeviceInventory(deviceId, recordsPerPage, token) {
  return function (dispatch) {
    return deviceInventoryApi
      .getDeviceInventory(deviceId)
      .then((deviceInventory) => {
        dispatch(loadDeviceInventorySuccess(deviceInventory, deviceId));
      })
      .catch((error) => {
        const err = new Error(
          "deviceInventoryActions.js, refreshDeviceInventory()",
          error.toString()
        );

        dispatch(logError(err));
      });
  };
}

export function refreshDeviceInventorySummary(deviceId) {
    return function (dispatch) {
        return deviceInventoryApi
            .getDeviceInventorySummary(deviceId)
            .then((deviceInventorySummary) => {
                dispatch(loadDeviceInventorySummarySuccess(deviceInventorySummary, deviceId));
            })
            .catch((error) => {
                const err = new Error(
                    "deviceInventoryActions.js, refreshDeviceInventorySummary()",
                    error.toString()
                );

                dispatch(logError(err));
            });
    };
}

export function refreshDeviceInventoryHistory(deviceId,
    recordsPerPage,
    token) {
    return function (dispatch) {
        return deviceInventoryApi
            .getDeviceInventoryHistory(deviceId, recordsPerPage, token)
            .then((deviceInventoryHistory) => {
                dispatch(loadDeviceInventoryHistorySuccess(deviceInventoryHistory, deviceId));
            })
            .catch((error) => {
                const err = new Error(
                    "deviceInventoryActions.js, refreshDeviceInventoryHistory()",
                    error.toString()
                );

                dispatch(logError(err));
            });
    };
}

export function scanInventorySp(deviceId) {
    return function (dispatch) {
        return deviceInventoryApi.scanInventorySp(deviceId).catch((error) => {
            const params = [
                {
                    deviceId: deviceId,
                },
            ];
            const err = new Error(
                "deviceInventoryActions.js, scanInventorySp()",
                error.toString(),
                params
            );

            dispatch(logError(err));
        });
    };
}

export function checkScan2(deviceId) {
    return function (dispatch) {
        return deviceInventoryApi.checkScan2(deviceId).catch((error) => {
            const params = [
                {
                    deviceId: deviceId,
                },
            ];
            const err = new Error(
                "deviceInventoryActions.js, checkScan2()",
                error.toString(),
                params
            );

            dispatch(logError(err));
        });
    };
}
/*
export function checkScan(deviceId) {
    return function (dispatch) {
        return deviceInventoryApi
            .checkScan(deviceId)
            .then((deviceInventory) => {
                dispatch(loadDeviceInventorySuccess(deviceInventory, deviceId));
            })
            .catch((error) => {
                const err = new Error(
                    "deviceInventoryActions.js, checkScan()",
                    error.toString()
                );

                dispatch(logError(err));
            });
    };
}
*/
