import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function customerLocationReducer(
  state = initialState.customerLocations,
  action
) {
  switch (action.type) {
    case types.CUSTOMER_LOCATIONS_LOADED_SUCCESS: {
      return action.locations;
    }
    default:
      return state;
  }
}
