export const getAlarmContacts = (state, alarmId) => {
  const filtered = state.alarmContacts.filter((c) => c.alarmId === alarmId);

  if (filtered.length > 0) return filtered[0].contacts;

  return [];
};

export const getAvailableAlarmContacts = (state, alarmId) => {
  const filtered = state.availableAlarmContacts.filter(
    (c) => c.alarmId === alarmId
  );

  if (filtered.length > 0) return filtered[0].contacts;

  return [];
};

export const getTimezones = (state) => state.timezones;

export const getAvailabilitySchedule = (state) => state.availabilitySchedule;
