import * as types from "../../common/state/actionTypes";
import * as deviceTelemetryApi from "../api/deviceTelemetryApi";
import { logError } from "../../errors/actions/errorActions";
import { getTelemetrySeriesData } from "../../charts/selectors/deviceTelemetryChartDataSelector";

export const analysisDeviceTelemetryChartDataLoaded = (
  deviceId,
  telemetryData
) => {
  return {
    type: types.LOAD_ANALYSIS_DEVICE_TELEMETRY_DATA_SUCCESS,
    deviceId,
    telemetryData,
  };
};

export const resetAnalysisDeviceTelemetryChart = () => {
  return { type: types.RESET_ANALYIS_DEVICE_TELEMETRY_DATA };
};

export const loadRecentHistoricalDeviceTelemetrySuccess = (
  deviceId,
  telemetryData
) => {
  return {
    type: types.LOAD_RECENT_TELEMETRY_DATA_SUCCESS,
    deviceId,
    telemetryData,
  };
};

export function loadDeviceListAnalysisTelemetry(deviceId, startTime, endTime) {
  return function (dispatch) {
    return deviceTelemetryApi
      .getAnalysisTelemetryData(deviceId, startTime, endTime)
      .then((telemetryData) => {
        dispatch(
          analysisDeviceTelemetryChartDataLoaded(deviceId, telemetryData)
        );
      })
      .catch((error) => {
        const err = new Error(
          "deviceTelemtryActions.js, loadDeviceListAnalysisTelemetry()",
          error.toString()
        );
        dispatch(logError(err));
      });
  };
}

export function submitDeviceAnalysisReportRequest(reportParams) {
    return function (dispatch) {
        return deviceTelemetryApi
            .submitDeviceAnalysisReportRequest(reportParams)
            .catch((error) => {
                const params = [
                    {
                        reportParams: reportParams,
                    },
                ];
                const err = new Error(
                    "deviceTelemtryActions.js, submitDeviceAnalisysReportRequest()",
                    error.toString(),
                    params
                );
                dispatch(logError(err));
            });
    };
}

export function loadRecentHistoricalDeviceTelemetry(
  deviceId,
  startTime,
  endTime,
  hardwareId
) {
  return function (dispatch, getState) {
    const existingChartData = getTelemetrySeriesData(getState(), hardwareId);
    if (existingChartData.length > 0) {
      return;
    }

    return deviceTelemetryApi
      .getAnalysisTelemetryData(deviceId, startTime, endTime)
      .then((telemetryData) => {
        dispatch(
          loadRecentHistoricalDeviceTelemetrySuccess(hardwareId, telemetryData)
        );
      })
      .catch((error) => {
        const err = new Error(
          "deviceTelemtryActions.js, loadRecentHistoricalDeviceTelemetry()",
          error.toString()
        );
        dispatch(logError(err));
      });
  };
}
