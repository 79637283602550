import * as types from "../../common/state/actionTypes";
import * as deviceNotificationApi from "../api/deviceNotificationApi";
import { logError } from "../../errors/actions/errorActions";

export const deviceListNotificationUpdate = (notifications) => ({
  type: types.DEVICE_LIST_NOTIFICATION_UPDATE,
  notifications,
});

export const serviceBusNotificationSubscriptionDetail = (subscription) => ({
  type: types.SERVICE_BUS_SUBSCRIPTION_DETAILS_LOADED,
  subscription,
});

export function getServiceBusNoticationSubscription(deviceId, filters) {
  return function (dispatch) {
    return deviceNotificationApi
      .getServiceBusNotificationSubscription(deviceId, filters)
      .then((subscription) => {
        dispatch(serviceBusNotificationSubscriptionDetail(subscription));
      })
      .catch((error) => {
        const err = new Error(
          "deviceNotificationActions.js, getServiceBusNoticationSubscription()",
          error.toString()
        );
        dispatch(logError(err));
      });
  };
}

export const setIsConnectedStatus = (isConnected) => {
    return {
        type: types.SET_IS_CONNECTED,
        isConnected: isConnected
    };
};
