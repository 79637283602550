import * as deviceGroupApi from "../api/deviceGroupApi";
import * as types from "../../common/state/actionTypes";
import { Error } from "../../errors/errorType";
import { logError } from "../../errors/actions/errorActions";

export function loadDeviceGroupsSuccess(deviceGroups) {
  return { type: types.LOAD_DEVICE_GROUPS_SUCCESS, deviceGroups };
}

export function loadDeviceGroups(deviceId) {
  return function (dispatch) {
    return deviceGroupApi
      .getDeviceGroups(deviceId)
      .then((deviceGroups) => {
        dispatch(loadDeviceGroupsSuccess(deviceGroups));
      })
      .catch((error) => {
        const params = [
          {
            deviceId: deviceId,
          },
        ];
        const err = new Error(
          "deviceGroupActions.js, loadDeviceGroups()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function updateDeviceGroup(deviceGroupData) {
  return function (dispatch) {
    return deviceGroupApi.updateDeviceGroups(deviceGroupData).catch((error) => {
      const params = [
        {
          deviceGroupData: deviceGroupData,
        },
      ];
      const err = new Error(
        "deviceGroupActions.js, updateDeviceGroup()",
        error.toString(),
        params
      );

      dispatch(logError(err));
    });
  };
}
