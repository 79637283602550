import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function deviceListReducer(
  state = initialState.deviceList,
  action
) {
  switch (action.type) {
    case types.LOAD_DEVICE_LIST_SUCCESS: {
    	console.log("LOAD_DEVICE_LIST_SUCCESS");
      let loadedState = Object.assign({}, state);
      let count = action.devices.length;

      let countPerPage = loadedState.countPerPage;
      let totalPages = Math.ceil(count / countPerPage);

      let locations = action.devices.map((d) => d.location);
      const uniqueLocations = new Set(locations);

			// hide device with status enabled = false
			let devices = action.devices.filter(device => device.enabled);

      loadedState.devices = devices;
      loadedState.filteredDevices = devices.slice(0, countPerPage);
      loadedState.currentCount = count; //countPerPage;
      loadedState.countPerPage = countPerPage;
      loadedState.totalCount = count;
      loadedState.currentPage = 1;
      loadedState.totalPages = totalPages;
      loadedState.filteredPages = totalPages;
      loadedState.locations = [...uniqueLocations];

      return loadedState;
    }
    case types.DEVICE_LIST_FILTER_BY_STATUS: {
			console.log("DEVICE_LIST_FILTER_BY_STATUS");
      let alertState = Object.assign({}, state);
      let statusFilter = action.status.toLocaleLowerCase();
      let alertDevicesFiltered = state.devices.filter((device) => {
        return device.status.toLocaleLowerCase().includes(statusFilter);
      });

      alertState.statusFilter = statusFilter;
      alertState.filteredCount = alertDevicesFiltered.length;
      alertState.filteredPages = Math.ceil(
        alertState.filteredCount / alertState.countPerPage
      );

      alertState.filteredDevices = alertDevicesFiltered.slice(
        0,
        alertState.countPerPage
      );

      return alertState;
    }
    case types.DEVICE_LIST_FILTER_BY_DOOR: {
        console.log("DEVICE_LIST_FILTER_BY_DOOR");
      let doorState = Object.assign({}, state);

      let doorDevicesFiltered = state.devices.filter((device) => {
        return device.doorFridge === action.doorState;
      });

      doorState.filteredCount = doorDevicesFiltered.length;
      doorState.filteredPages = Math.ceil(
        doorState.filteredCount / doorState.countPerPage
      );

      doorState.filteredDevices = doorDevicesFiltered.slice(
        0,
        doorState.countPerPage
      );
      return doorState;
    }

    case types.DEVICE_LIST_FILTER_BY_AlARM: {
        console.log("DEVICE_LIST_FILTER_BY_AlARM");
      let alarmState = Object.assign({}, state);

      let alarmDevicesFiltered = state.devices.filter((device) => {
        return device.alarmed === true;
      });

      alarmState.filteredCount = alarmDevicesFiltered.length;
      alarmState.filteredPages = Math.ceil(
        alarmState.filteredCount / alarmState.countPerPage
      );

      alarmState.filteredDevices = alarmDevicesFiltered.slice(
        0,
        alarmState.countPerPage
      );
      return alarmState;
    }
    case types.DEVICE_LIST_FILTER_BY_OK: {
        console.log("DEVICE_LIST_FILTER_BY_OK");
      let okState = Object.assign({}, state);

      let okDevicesFiltered = state.devices.filter((device) => {
        return device.alarmed !== true;
      });

      okState.filteredCount = okDevicesFiltered.length;
      okState.filteredPages = Math.ceil(
        okState.filteredCount / okState.countPerPage
      );

      okState.filteredDevices = okDevicesFiltered.slice(
        0,
        okState.countPerPage
      );
      return okState;
    }
    case types.DEVICE_LIST_FILTER_CLEAR: {
        console.log("DEVICE_LIST_FILTER_CLEAR");
      let resetState = Object.assign({}, state);

      let count = state.devices.length;

      let countPerPage = resetState.countPerPage;
      let totalPages = Math.ceil(count / countPerPage);

      resetState.devices = state.devices;
      resetState.filteredDevices = state.devices.slice(0, countPerPage);
      resetState.filteredCount = resetState.filteredDevices.length;
      resetState.currentCount = countPerPage;
      resetState.countPerPage = countPerPage;
      resetState.totalCount = count;
      resetState.currentPage = 1;
      resetState.totalPages = totalPages;
      resetState.filteredPages = totalPages;
      resetState.statusFilter = "";

      return resetState;
    }
    case types.DEVICE_LIST_SEARCH_BY_VALUE: {
        console.log("DEVICE_LIST_SEARCH_BY_VALUE");
      let newState = Object.assign({}, state);
      let value = action.search.toLocaleLowerCase();
      let filteredDevices = state.devices.filter((device) => {
        return (
          device.hardwareId?.toLocaleLowerCase().includes(value) ||
          device.name?.toLocaleLowerCase().includes(value) ||
          device.location?.toLocaleLowerCase().includes(value) ||
          device.customerCode?.toLocaleLowerCase().includes(value)
        );
        // TODO: put back in OR change to whatever data we are filtering on
        //device.serialNumber.toLocaleLowerCase().includes(value) ||
        //
        //device.status.toLocaleLowerCase().includes(value) ||
        //device.categoryFilter.toLocaleLowerCase().includes(value)
      });

      newState.filteredCount = filteredDevices.length;
      newState.filteredPages = Math.ceil(
        newState.filteredCount / newState.countPerPage
      );

      newState.filteredDevices = filteredDevices.slice(
        0,
        newState.countPerPage
      );
      return newState;
    }
    case types.DEVICE_LIST_FILTER_BY_LOCATION: {
        console.log("DEVICE_LIST_FILTER_BY_LOCATION");
      let locState = Object.assign({}, state);
      let value = action.location.toLocaleLowerCase();
      let filteredDevices = state.devices.filter((device) => {
        return device.location.toLocaleLowerCase().includes(value);
      });

      locState.filteredCount = filteredDevices.length;
      locState.filteredPages = Math.ceil(
        locState.filteredCount / locState.countPerPage
      );

      locState.filteredDevices = filteredDevices.slice(
        0,
        locState.countPerPage
      );
      return locState;
    }
    case types.DEVICE_LIST_FILTER_BY_FAVOURITE: {
        console.log("DEVICE_LIST_FILTER_BY_FAVOURITE");
      let favouriteState = Object.assign({}, state);
      let favouriteDevicesFiltered = state.devices.filter((device) => {
        return device.favourite === true;
      });

      favouriteState.filteredCount = favouriteDevicesFiltered.length;
      favouriteState.filteredPages = Math.ceil(
        favouriteState.filteredCount / favouriteState.countPerPage
      );

      favouriteState.filteredDevices = favouriteDevicesFiltered.slice(
        0,
        favouriteState.countPerPage
      );

      return favouriteState;
    }
    case types.DEVICE_LIST_SET_SELECTED_DEVICE: {
        console.log("DEVICE_LIST_SET_SELECTED_DEVICE");
      let selectedDeviceState = Object.assign({}, state);
      selectedDeviceState.selectedDevice = action.device;

      return selectedDeviceState;
    }
    case types.DEVICE_LIST_UPDATE_DEVICE_DATA: {
        console.log("DEVICE_LIST_UPDATE_DEVICE_DATA");
      return {
        ...state,
        devices: state.devices.map((device) => {
          if (device.hardwareId === action.device.deviceId) {
            return {
              ...device,
              temperatureProduct: action.device.temperatureProduct,
              voltageMain: action.device.voltageMain,
            };
          }
          return device;
        }),
        filteredDevices: state.filteredDevices.map((device) => {
          if (device.hardwareId === action.device.deviceId) {
            return {
              ...device,
              temperatureProduct: action.device.temperatureProduct,
              voltageMain: action.device.voltageMain,
            };
          }
          return device;
        }),
      };
    }
    case types.DEVICE_LIST_UPDATE_DEVICE_LIST_DATA: {
        console.log("DEVICE_LIST_UPDATE_DEVICE_LIST_DATA");
      let deviceListUpdateState = Object.assign({}, state);

      let selectedDeviceId = -1;
      if (state.selectedDevice.id) {
        selectedDeviceId = state.selectedDevice.id;
      }
      deviceListUpdateState.devices = action.devices;
      const filteredDevices = state.filteredDevices.map((d) => {
        const updatedDevice = action.devices.find((e) => e.id === d.id);
        if (updatedDevice && updatedDevice.id !== selectedDeviceId) {
          return updatedDevice;
        }
        return d;
      });
      deviceListUpdateState.filteredDevices = filteredDevices;

      return deviceListUpdateState;
    }
    case types.DEVICE_LIST_LOAD_EXACT_PAGE: {
        console.log("DEVICE_LIST_LOAD_EXACT_PAGE");
      const exactPageState = Object.assign({}, state);
      const exactPage = action.page;
      let upperCountExact = exactPageState.countPerPage * exactPage;
      let lowerCountExact = upperCountExact - exactPageState.countPerPage;

      let pageDevices;
      let pageStatusFilter;
      if (exactPageState.statusFilter.length > 0) {
        pageStatusFilter = exactPageState.statusFilter.toLocaleLowerCase();
        pageDevices = exactPageState.devices.filter((device) => {
          return device.Status.toLocaleLowerCase().includes(pageStatusFilter);
        });
      } else {
        pageDevices = exactPageState.devices;
      }

      let exactDevices = pageDevices.slice(lowerCountExact, upperCountExact);
      exactPageState.filteredDevices = exactDevices;
      exactPageState.currentCount = upperCountExact;
      exactPageState.currentPage = exactPage;
      // window.history.pushState(
      //   { page: 1 },
      //   "title 1",
      //   `?page=${exactPageState.currentPage}`
      // );

      return exactPageState;
    }
    case types.DEVICE_LIST_LOAD_NEW_PAGE: {
        console.log("DEVICE_LIST_LOAD_NEW_PAGE");
      //Clone the previous state
      let loadNewPageState = Object.assign({}, state);

      let addPages = action.page;

      //add it to the current
      if (addPages === 1) {
        if (loadNewPageState.currentPage < loadNewPageState.totalPages)
          loadNewPageState.currentPage += 1;
      }

      if (addPages === -1) {
        if (loadNewPageState.currentPage > 1) loadNewPageState.currentPage -= 1;
      }

      let begin =
        (loadNewPageState.currentPage - 1) * loadNewPageState.countPerPage;
      let end = begin + loadNewPageState.countPerPage;

      let pageList = loadNewPageState.devices.slice(begin, end);
      loadNewPageState.filteredDevices = pageList;

      return loadNewPageState;
    }
    case types.DEVICE_LIST_NOTIFICATION_UPDATE: {
        console.log("DEVICE_LIST_NOTIFICATION_UPDATE");
      let notificationState = Object.assign({}, state);

      let deviceIndex = -1;
      let filteredDeviceIndex = -1;

      const notification = action.notifications;

      deviceIndex = notificationState.devices.findIndex(
        (d) => d.hardwareId === notification.deviceId
      );

      notificationState.devices = state.devices.map((device, index) => {
        if (deviceIndex === index) {
          // TODO: Assumption that all devices will have status, product temp, voltage and door, will go bang if they are missing
          return {
            ...device,
            status: notification.hasOwnProperty("status")
              ? notification.status
              : device.status,
            temperatureProduct: notification.hasOwnProperty(
              "temperatureProduct"
            )
              ? notification.temperatureProduct
              : device.temperatureProduct,
            fridgeVoltage: notification.hasOwnProperty("fridgeVoltage")
              ? notification.fridgeVoltage
              : device.fridgeVoltage,
            door: notification.hasOwnProperty("door")
              ? notification.door
              : device.door,
          };
        }

        return { ...device };
      });

      filteredDeviceIndex = notificationState.filteredDevices.findIndex(
        (d) => d.hardwareId === notification.deviceId
      );

      notificationState.filteredDevices = state.filteredDevices.map(
        (device, index) => {
          if (filteredDeviceIndex === index) {
            // TODO: Assumption that all devices will have status, product temp, voltage and door, will go bang if they are missing
            return {
              ...device,
              status: notification.hasOwnProperty("status")
                ? notification.status
                : device.status,
              temperatureProduct: notification.hasOwnProperty(
                "temperatureProduct"
              )
                ? notification.temperatureProduct
                : device.temperatureProduct,
              fridgeVoltage: notification.hasOwnProperty("fridgeVoltage")
                ? notification.fridgeVoltage
                : device.fridgeVoltage,
              door: notification.hasOwnProperty("door")
                ? notification.door
                : device.door,
            };
          }

          return { ...device };
        }
      );

      return notificationState;
    }
    case types.USER_DEVICE_FAVOURITE_ADDED: {
        console.log("USER_DEVICE_FAVOURITE_ADDED");
      return {
        ...state,
        devices: state.devices.map((device) => {
          if (device.id === action.deviceId) {
            return {
              ...device,
              favourite: true,
            };
          }
          return device;
        }),
        filteredDevices: state.filteredDevices.map((device) => {
          if (device.id === action.deviceId) {
            return {
              ...device,
              favourite: true,
            };
          }
          return device;
        }),
      };
    }
    case types.USER_DEVICE_FAVOURITE_DELETED: {
        console.log("USER_DEVICE_FAVOURITE_DELETED");
      return {
        ...state,
        devices: state.devices.map((device) => {
          if (device.id === action.deviceId) {
            return {
              ...device,
              favourite: false,
            };
          }
          return device;
        }),
        filteredDevices: state.filteredDevices.map((device) => {
          if (device.id === action.deviceId) {
            return {
              ...device,
              favourite: false,
            };
          }
          return device;
        }),
      };
    }
    case types.DEVICE_LIST_SHOW_HIGH_TEMP: {
        console.log("DEVICE_LIST_SHOW_HIGH_TEMP");
        let newState = Object.assign({}, state);
        let sortedDevices = [...state.devices];

        sortedDevices.filter(device => {
            return parseFloat(device.temperatureProduct) > parseFloat(device.maxTemperature);
        });

        newState.filteredCount = sortedDevices.length;
        newState.filteredPages = Math.ceil(
            newState.filteredCount / newState.countPerPage
        );

        newState.devices = sortedDevices;
        newState.filteredDevices = sortedDevices.slice(
            0,
            newState.countPerPage
        );

        return newState;
    }
    case types.DEVICE_LIST_IS_ENBALED: {
        console.log("DEVICE_LIST_IS_ENBALED");
        let newState = { ...state };
        let sortedDevices = [...state.devices];

        sortedDevices = action.isEnabled ? sortedDevices : sortedDevices.filter(device => device.enabled);

        sortedDevices.map(device => {
            console.log("Device " + device.name, device.enabled);
        });

        if (action.isEnabled) {
            sortedDevices = sortedDevices.sort((a, b) => {
            //   return (a.enabled === b.enabled) ? 0 : a.enabled ? 1 : -1;
                return +a.enabled - +b.enabled;
            });
        }

        newState.filteredCount = sortedDevices.length;
        newState.filteredPages = Math.ceil(
            newState.filteredCount / newState.countPerPage
        );

        newState.devices = sortedDevices;
        newState.filteredDevices = sortedDevices.slice(
            0,
            newState.countPerPage
        );

        return newState;
    }
    case types.DEVICE_LIST_FILTER_BY_NAME : {
        console.log("DEVICE_LIST_FILTER_BY_NAME ");
        let newState = Object.assign({}, state);
        let sortedDevices = [...state.devices];

        if(action.sortOrder === "ASC") {
            sortedDevices.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
            });
        } else {
            sortedDevices.sort((a, b) => {
                if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
                return 0;
            });
        }

        newState.filteredCount = sortedDevices.length;
        newState.filteredPages = Math.ceil(
            newState.filteredCount / newState.countPerPage
        );

        newState.devices = sortedDevices;
        newState.filteredDevices = sortedDevices.slice(
            0,
            newState.countPerPage
        );

        return newState;
    }
    case types.DEVICE_LIST_FILTER_BY_CUSOMTER_CODE : {
        console.log("DEVICE_LIST_FILTER_BY_CUSOMTER_CODE ");
        let newState = Object.assign({}, state);
        let sortedDevices = [...state.devices];

        if(action.sortOrder === "ASC") {
            sortedDevices.sort((a, b) => {
                if (a.customerCode.toLowerCase() < b.customerCode.toLowerCase()) return -1;
                if (a.customerCode.toLowerCase() > b.customerCode.toLowerCase()) return 1;
                return 0;
            });
        } else {
            sortedDevices.sort((a, b) => {
                if (a.customerCode.toLowerCase() > b.customerCode.toLowerCase()) return -1;
                if (a.customerCode.toLowerCase() < b.customerCode.toLowerCase()) return 1;
                return 0;
            });
        }

        newState.filteredCount = sortedDevices.length;
        newState.filteredPages = Math.ceil(
            newState.filteredCount / newState.countPerPage
        );

        newState.devices = sortedDevices;
        newState.filteredDevices = sortedDevices.slice(
            0,
            newState.countPerPage
        );

        return newState;
    }
    case types.DEVICE_LIST_FILTER_BY_VOLTAGE: {
        console.log("DEVICE_LIST_FILTER_BY_VOLTAGE");
        let newState = Object.assign({}, state);
        let sortedDevices = sortDevices(state.devices, "voltageMain", action.sortOrder, true);

        newState.filteredCount = sortedDevices.length;
        newState.filteredPages = Math.ceil(
            newState.filteredCount / newState.countPerPage
        );

        newState.devices = sortedDevices;
        newState.filteredDevices = sortedDevices.slice(
            0,
            newState.countPerPage
        );

        return newState;
    }
    case types.DEVICE_LIST_FILTER_BY_PROD_TEMP: {
        console.log("DEVICE_LIST_FILTER_BY_PROD_TEMP");
        let newState = Object.assign({}, state);
        let sortedDevices = sortDevices(state.devices, "temperatureProduct", action.sortOrder, true);

        newState.filteredCount = sortedDevices.length;
        newState.filteredPages = Math.ceil(
            newState.filteredCount / newState.countPerPage
        );

        newState.devices = sortedDevices;
        newState.filteredDevices = sortedDevices.slice(
            0,
            newState.countPerPage
        );

        return newState;
    }
    case types.DEVICE_LIST_SORT_BY_DOOR: {
        console.log("DEVICE_LIST_SORT_BY_DOOR");
        let newState = Object.assign({}, state);
        let sortedDevices = [...state.devices];

        sortedDevices = sortedDevices.sort((a, b) => {
            if (action.sortOrder === "ASC") {
                return a.doorFridge - b.doorFridge;
            } else {
                return b.doorFridge - a.doorFridge;
            }
        });

        newState.filteredCount = sortedDevices.length;
        newState.filteredPages = Math.ceil(
            newState.filteredCount / newState.countPerPage
        );

        // newState.devices = sortedDevices;
        newState.filteredDevices = sortedDevices.slice(
            0,
            newState.countPerPage
        );

        return newState;
    }

    default:
      return state;
  }
}

function sortDevices(devices, key, sortOrder, isNumeric = false) {
//   @TODO: Implement based on type string/number/boolean
  const sortedDevices = [...devices];
  sortedDevices.sort((a, b) => {
    if (isNumeric) {
      return sortOrder === "ASC" ? a[key] - b[key] : b[key] - a[key];
    } else {
      return sortOrder === "ASC" ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
    }
  });
  return sortedDevices;
}
