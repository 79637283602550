export const getDeviceAlarmCount = (state) => {
  const count = state.deviceList.devices.reduce(
    (acc, cur) => (cur.alarmed === true ? ++acc : acc),
    0
  );
  return count;
};

export const getDeviceOkCount = (state) => {
  const count = state.deviceList.devices.reduce(
    (acc, cur) => (cur.alarmed !== true ? ++acc : acc),
    0
  );
  return count;
};

export const getFilteredDevices = (state) => {
  return state.deviceList.filteredDevices;
};

export const getAllDevices = (state) => state.deviceList.devices;

export const getOpenDoorCount = (state) => {
  const count = state.deviceList.devices.reduce(
    (acc, cur) => (cur.doorFridge === true ? ++acc : acc),
    0
  );
  return count;
};

export const getSelectedDevice = (state) => state.deviceList.selectedDevice;

export const getFilteredPageListCount = (state) =>
  state.deviceList.filteredPages;

export const getDeviceLocations = (state) => state.deviceList.locations;
