// import { devicesLocalPrototype, deviceDetailsLocalPrototype } from '../../../tools/mockDataLocalPrototype'
import { devicesListDashboard } from "../fakeData/fakeDashboardDeviceList";

export default {
  // TODO: reset to empty array once connected back to the API
  applicationState: {
    initialDeviceListLoaded: false,
    initialDeviceId: null,
    isConnected: true,
  },
  devices: [], // devicesLocalPrototype,//[],
  deviceDetails: [], //deviceDetailsLocalPrototype,//[],
  deviceStatuses: [],
  deviceInputs: [],
  selectedDeviceStatus: {},
  dashboardStatusOverview: {},
  apiCallsInProgress: 0,
  loggedOnUser: "",
  devTools: {
    showIdentityServerInfo: false,
  },
  dashboardSearch: "",
  dashboardDevicesList: {
    devices: devicesListDashboard,
    filteredDevices: devicesListDashboard,
    currentCount: 0,
    countPerPage: 10,
    totalCount: 0,
    currentPage: 0,
    totalPages: 0,
    filteredPages: 0,
    filteredCount: 0,
    statusFilter: "",
    selectedDevice: {},
  },
  inputQueryParameters: [
    {
      deviceId: "",
      startDate: "",
      endDate: "",
      selectedInputs: [],
    },
  ],
  dashboardData: [
    {
      deviceId: "",
      dashboardTelemetryData: [],
      dataTableColumns: [],
      chartLines: [],
    },
  ],
  dashboardAnalysisData: [
    {
      deviceId: "",
      seriesData: [],
    },
  ],
  eventLogData: [
    {
      deviceId: "",
      eventLogs: [],
    },
  ],
  liveDeviceStatusData: [
    {
      deviceId: "",
      telemetryData: [],
      dataTableColumns: [],
      chartLines: [],
    },
  ],
  liveDeviceTelemetryData: [
    {
      deviceId: "",
      seriesData: [],
    },
  ],
  latestIncidents: [],
  incidentDetails: [],
  errors: [],
  deviceList: {
    devices: [],
    filteredDevices: [],
    currentCount: 0,
    countPerPage: 10,
    totalCount: 0,
    currentPage: 0,
    totalPages: 0,
    filteredPages: 0,
    filteredCount: 0,
    statusFilter: "",
    selectedDevice: {},
    locations: [],
  },
  deviceNotification: {
    serviceBusSubscription: {
      url: "",
      topic: "",
      signature: "",
      token: "",
    },
  },
  customer: {
    id: null,
    name: "",
  },
  deviceTelemetryChartData: [],
  analysisDeviceTelemetryChartData: {
    deviceId: "",
    series: [],
  },
  dataAnalysisParameters: {
    startDate: "",
    endDate: "",
  },
  incidentList: [],
  incidentAlarmList: [],
  incidentReportDropdownList: [],
  deviceContacts: [
    {
      deviceId: "",
      contactInfo: [],
    },
  ],
  deviceAlarmConfigList: [],
  newAlarmConfigDialog: {
    open: false,
  },
  generalDeviceSettings: {},
  customerLocations: [],
  deviceGroups: [],
  deviceInventory: [],
    deviceInventoryHistory: [],
    deviceInventorySummary: [],
    deviceScanCheck: [],
    scanCheckResult: "idle",
  alarmContacts: [],
  availableAlarmContacts: [],
  timezones: [],
  availabilitySchedule: [],
  currentUser: {},
  customers: [],
  deviceProvisioningTemplates: [],
};
