export const getTelemetrySeriesData = (state, deviceId) => {
  let seriesData = [];
  if (state.deviceTelemetryChartData.length > 0) {
    let device = state.deviceTelemetryChartData.find(
      (d) => d.deviceId === deviceId
    );
    if (device !== undefined) {
      seriesData = device.series;
    }
  }
  return seriesData;
  //state.deviceTelemetryChartData.series;
  //return seriesData;
};

export const getAnalysisTelemetrySeriesData = (state) =>
  state.analysisDeviceTelemetryChartData.series;
