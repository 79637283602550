import React, { Component} from "react";
import { connect } from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import { getFilteredPageListCount } from "./selectors/deviceListSelector";
import { loadNewPage, loadExactPage } from "./actions/deviceListActions";

class DeviceListPagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1
        };
    }
    setPageNumber(p){
 //       if (p >= 1 && p <= maxLimit) {
        var curr = Math.max(1, Math.min(p, this.props.filteredPageCount));
        this.setState({ currentPage: curr });
//        this.state.currentPage = Math.max(1, Math.min(p, this.props.filteredPageCount));
//        }
    };

    render() {
        let pageButtonsToDisplay;
    if (this.props.filteredPageCount > 8) {
      pageButtonsToDisplay = 8;
    } else {
      pageButtonsToDisplay = this.props.filteredPageCount;
    }
      
    const items = [];
    items.push(
        <Pagination.First key={-1} onClick={() => { this.props.loadExactPage(1); this.setPageNumber(1); }} />
    );
    items.push(
        <Pagination.Prev key={0} onClick={() => { this.props.loadNewPage(-1); this.setPageNumber(this.state.currentPage - 1); }} />
    );


    if (this.props.filteredPageCount <= pageButtonsToDisplay) {
        for (let number = 1; number <= pageButtonsToDisplay; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    className={`button pagination-link ${this.state.currentPage === number + 1 ? "is-current" : ""
                        }`}
                    aria-label="Page 1"
                    onClick={() => { this.props.loadExactPage(number); this.setPageNumber(number); }}
                    aria-current="page"
                >
                    {number}
                </Pagination.Item>
            );
        }
    } else {
        const leftside = this.state.currentPage - pageButtonsToDisplay / 2 > 1;
        const rightside = this.state.currentPage + pageButtonsToDisplay / 2 < this.props.filteredPageCount;
/*        items.push(
            <Pagination.First
                key="first"
                onClick={() => this.props.loadExactPage(1)}
            />
        );
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => this.props.loadNewPage(-1)}
            />
        );
        */
        if (leftside) {
            items.push(<Pagination.Ellipsis key="leftEllipsis" />);
        }
        const str = Math.max(1, Math.round(this.state.currentPage - pageButtonsToDisplay / 2));
        const end = Math.min(this.props.filteredPageCount, Math.round(this.state.currentPage + pageButtonsToDisplay / 2));
//        alert(this.state.currentPage);
//        alert(items.length);
        for (let i = str; i <= end; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    className={`button pagination-link ${this.state.currentPage === i + 1 ? "is-current" : ""
                        }`}
                    aria-label="Page 1"
                    onClick={() => { this.props.loadExactPage(i); this.setPageNumber(i); }}
                    aria-current="page"
                >
                    {i}
                </Pagination.Item>
            );
        }
        if (rightside) {
            items.push(<Pagination.Ellipsis key="rightEllipsis" />);
        }
    } 
    items.push(
      <Pagination.Next
            key={pageButtonsToDisplay + 2}
            onClick={() => { this.props.loadNewPage(1); this.setPageNumber(this.state.currentPage + 1); }}
      />
    );
    items.push(
      <Pagination.Last
            key={pageButtonsToDisplay + 3}
            onClick={() => { this.props.loadExactPage(this.props.filteredPageCount); this.setPageNumber(this.props.filteredPageCount); } }
      />
    );
    return (
      <div className="list-group-filter-pagination-button-container">
        <Pagination>{items}</Pagination>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
      filteredPageCount: getFilteredPageListCount(state),
    currentPage: 1,
  };
}

const mapDispatchToProps = {
  loadNewPage,
  loadExactPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceListPagination);
