import React, { Component } from "react";
import {
  loadDeviceIncidentList,
  submitDeviceIncidentReportRequest,
  loadIncidentReportDropdownList,
} from "./actions/incidentActions";
import { connect } from "react-redux";
import {
  getIncidentList,
  getTokenForMoreIncidents,
  getIncidentReportDropdownOptions,
} from "./selectors/deviceIncidentSelector";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import DeviceIncidentAlarmList from "./DeviceIncidentAlarmList";
import { getSelectedDevice } from "../deviceList/selectors/deviceListSelector";
import Button from "react-bootstrap/Button";
import { formatDate, formatDateIgnoreOffset } from "../utils/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faEdit } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Incidents.css";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import Select from "react-select";
import { InlineInputEdit } from "react-inline-input-edit";
import JSONPretty from "react-json-pretty";

const defaultSorted = [
  {
    dataField: "name",
    order: "asc",
  },
];

class DeviceIncidentList extends Component {
  constructor(props) {
    super(props);
    const mobileIncidentMap = new Map();

    this.state = {
      mobileIncidentMap: mobileIncidentMap,
      columns: [
        {
          dataField: "sortOrder",
          text: "Sort Order",
          hidden: true,
          editable: false,
        },
        {
          dataField: "incident",
          text: "Incident",
          hidden: true,
          editable: false,
        },
        {
          dataField: "activatedDate",
          text: "Incident Activated",
          formatter: this.dateFormatter,
          editable: false,
        },
        {
          dataField: "resolvedDate",
          text: "Incident Resolved",
          formatter: this.dateFormatter,
          editable: false,
        },
        {
          dataField: "incidentStatus",
          text: "Status",
          editable: false,
          headerStyle: (colum, colIndex) => {
            return { width: "80px" };
          },
        },
        {
          dataField: "customerReportId",
          text: "Report",
          hidden: false,
          editable: true,
          formatter: (cell, row) => {
            //   console.log("customerReportId formatter");
            if (
              this.props.reportDropdownOptions &&
              this.props.reportDropdownOptions.length > 0 &&
              cell !== null
            ) {
              // console.log(this.props.reportDropdownOptions);
              // console.log(cell);
              return this.props.reportDropdownOptions.find((x) => {
                return x.value.toString() === cell.toString();
              }).label;
            }
            return "";
          },
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              return this.props.reportDropdownOptions;
            },
          },
        },
        {
          dataField: "reportStartDate",
          text: "Report Start Time",
          hidden: true,
        },
        {
          dataField: "reportStartDateNoOffset",
          text: "Report Start Time",
          formatter: this.dateFormatter,
        },
        {
          dataField: "reportStartDateOffset",
          text: "reportStartDateOffset",
          hidden: true,
        },
        {
          dataField: "reportEndDate",
          text: "Report End Time",
          hidden: true,
        },
        {
          dataField: "reportEndDateNoOffset",
          text: "Report End Time",
          formatter: this.dateFormatter,
        },
        {
          dataField: "reportEndDateOffset",
          text: "reportEndDateOffset",
          hidden: true,
        },
        {
          dataField: "token",
          text: "Token",
          hidden: true,
          editable: false,
        },
        {
          dataField: "submitReport",
          text: "",
          hidden: false,
          editable: false,
          formatter: this.submitReportFormatter,
          headerStyle: (colum, colIndex) => {
            return { width: "60px" };
          },
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              this.submitReport(row);
            },
          },
        },
      ],
      reportDropdownOptions: [],
      incidentsMobile: [],
    };

    this.submitReport = this.submitReport.bind(this);
    this.submitReportNotify = this.submitReportNotify.bind(this);
    this.showIncidentDetails = this.showIncidentDetails.bind(this);

    this.mobileStartDateHandleFocus =
      this.mobileStartDateHandleFocus.bind(this);
    this.mobileEndDateHandleFocusOut =
      this.mobileEndDateHandleFocusOut.bind(this);
  }
  componentDidMount() {
    this.props.loadIncidentReportDropdownList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.device !== prevProps.device) {
      this.loadDeviceIncidentList();
    }
  }

  getIncidentReportDropdownOptions() {
    return this.props.reportDropdownOptions;
  }

  loadDeviceIncidentList() {
    this.props.loadDeviceIncidentList(
      this.props.device.id,
      this.props.recordsPerPage,
      this.props.token
    );
  }
  loadMoreIncidents() {
    this.loadDeviceIncidentList();
  }

  dateFormatter(cell) {
    return <span>{formatDate(cell)}</span>;
  }

  dateFormatterIgnorOffset(cell) {
    return <span>{formatDateIgnoreOffset(cell)}</span>;
  }

  reportFormatter(cell) {
    //console.log("reportFormatter, cell:" + cell);
  }

  submitReportNotify() {
    toast.success(
      "Your report has been scheduled and will be delivered by email in the next 15 minutes.",
      {
        hideProgressBar: true,
        autoClose: 10000,
      }
    );
  }

  submitReport(row) {
    const reportParams = {
      ReportId: parseInt(row.customerReportId),
      DeviceId: this.props.device.id,
      StartTime: row.reportStartDateNoOffset,
      EndTime: row.reportEndDateNoOffset,
      Offset: row.reportStartDateOffset,
    };

    this.props.submitDeviceIncidentReportRequest(reportParams);
    this.submitReportNotify();
  }

  submitReportFormatter(cell, row) {
    return (
      <div className="report-edit">
        <FontAwesomeIcon icon={faEdit} />
      </div>
    );
  }

  afterSaveCell(row, column, rowIndex, columnIndex) {
    //console.log("afterSaveCell, rowIndex:" + rowIndex);
  }

  showIncidentDetails(incident) {
    let incidentMap = this.state.mobileIncidentMap;
    if (incidentMap.get(incident) === undefined) {
      incidentMap.set(incident, true);
    } else {
      incidentMap.delete(incident);
    }

    this.setState({ mobileIncidentMap: incidentMap });
  }

  renderMobileReportSelect(reportName) {
    let select = "";

    if (reportName.length > 0) {
      if (
        this.props.reportDropdownOptions &&
        this.props.reportDropdownOptions.length > 0
      ) {
        let defaultValue = -1;
        let options = this.props.reportDropdownOptions;

        if (reportName.length > 0)
          defaultValue = this.props.reportDropdownOptions.findIndex(
            (x) => x.label === reportName
          );

        select = (
          <Select options={options} defaultValue={options[defaultValue]} />
        );
      }
    }

    return select;
  }

  submitReportMobile(row) {
    console.log(row);
    // const reportParams = {
    //   ReportId: parseInt(row.customerReportId),
    //   DeviceId: this.props.device.id,
    //   StartTime: row.reportStartDateNoOffset,
    //   EndTime: row.reportEndDateNoOffset,
    //   Offset: row.reportStartDateOffset,
    // };
    // this.props.submitDeviceIncidentReportRequest(reportParams);
    // this.submitReportNotify();
  }

  mobileStartDateHandleFocus(text) {
    console.log("mobileStartDateHandleFocus, text:" + text);
  }

  mobileEndDateHandleFocusOut(text) {
    console.log("mobileEndDateHandleFocusOut, text:" + text);
  }

  render() {
    const expandRow = {
      renderer: (row) => (
        <DeviceIncidentAlarmList
          deviceId={this.props.device.id}
          incident={row.incident}
        />
      ),
      showExpandColumn: true, // set to true to show + sign
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) =>
        isAnyExpands ? (
          <FontAwesomeIcon className="expander" icon={faMinus} />
        ) : (
          <FontAwesomeIcon className="expander" icon={faPlus} />
        ),
      expandColumnRenderer: ({ expanded, rowKey, expandable }) =>
        expanded ? (
          <FontAwesomeIcon className="expander" icon={faMinus} />
        ) : (
          <FontAwesomeIcon className="expander" icon={faPlus} />
        ),
    };

    return this.props.incidentList.length === 0 ? (
      <></>
    ) : (
      <div className="content-container">
        <h4>{this.props.device.name}</h4>
        <ToolkitProvider
          bootstrap4
          keyField="incident"
          data={this.props.incidentList}
          columns={this.state.columns}
          search={{ defaultSearch: "" }}
        >
          {(props) => (
            <div>
              <BootstrapTable
                {...props.baseProps}
                hover
                defaultSorted={defaultSorted}
                expandRow={expandRow}
                bordered
                condensed
                className="mobile-table"
                id="deviceIncidentListTable"
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  afterSaveCell: this.afterSaveCell,
                })}
              />
            </div>
          )}
        </ToolkitProvider>

        {/* <div id="deviceIncidentListTableMobile">
          <JSONPretty data={this.props.reportDropdownOptions}></JSONPretty>
          <ListGroup>
            {this.props.incidentList.map((inc) => (
              <div>
                <ListGroupItem key={inc.incident}>
                  <h6>Incident:</h6>
                  <table id="incidentTable" className="mobile-table">
                    <thead>
                      <tr>
                        <th>Activated</th>
                        <th>Resolved</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <td>{formatDate(inc.activatedDate)}</td>
                        </td>
                        <td>{formatDate(inc.resolvedDate)}</td>
                        <td>{inc.incidentStatus}</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <h6>Report</h6>
                  <div>{this.renderMobileReportSelect(inc.reportName)}</div>
                  <table className="mobile-table">
                    <thead>
                      <tr>
                        <th>Start</th>
                        <th>End</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <InlineInputEdit
                            text={formatDate(inc.reportStartDateNoOffset)}
                            inputWidth="100px"
                            inputHeight="25px"
                            inputMaxLength={50}
                            onFocus={() => this.mobileStartDateHandleFocus()}
                            onFocusOut={() =>
                              this.mobileEndDateHandleFocusOut()
                            }
                          />
                        </td>

                        <td>
                          <InlineInputEdit
                            text={formatDate(inc.resolvedDate)}
                            inputWidth="100px"
                            inputHeight="25px"
                            inputMaxLength={50}

                            //  onFocus={_handleFocus}
                            // onFocusOut={_handleFocusOut}
                          />
                        </td>
                        <td>
                          <div className="report-edit">
                            <FontAwesomeIcon
                              icon={faEdit}
                              onClick={() => this.submitReportMobile(inc)}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <Button
                    onClick={() => this.showIncidentDetails(inc.incident)}
                  >
                    Show Details
                  </Button>
                  <br />
                  {this.state.mobileIncidentMap.get(inc.incident) !==
                    undefined && (
                    <div>
                      <br />
                      <DeviceIncidentAlarmList
                        deviceId={this.props.device.id}
                        incident={inc.incident}
                      />
                    </div>
                  )}
                </ListGroupItem>
                <br />
              </div>
            ))}
          </ListGroup>
          <br />
        </div> */}
        <Button variant="primary" onClick={() => this.loadMoreIncidents()}>
          More
        </Button>
      </div>
    );
  }
}

DeviceIncidentList.defaultProps = {
  incidentList: [],
  device: {},
  token: "",
  reportDropdownOptions: [],
};

function mapStateToProps(state, ownProps) {
  return {
    incidentList: getIncidentList(state),
    device: getSelectedDevice(state),
    token: getTokenForMoreIncidents(state),
    reportDropdownOptions: getIncidentReportDropdownOptions(state),
  };
}

const mapDispatchToProps = {
  loadDeviceIncidentList,
  submitDeviceIncidentReportRequest,
  loadIncidentReportDropdownList,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceIncidentList);
