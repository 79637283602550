import React, { Component } from "react";
import { connect } from "react-redux";
import {
  loadTimezones,
  loadAvailabilitySchedule,
  upsertContact,
} from "./actions/alarmConfigActions";
import {
  getTimezones,
  getAvailabilitySchedule,
} from "./selectors/alarmContactSelector";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import "./DeviceConfig.css";
import AlarmContactEmail from "./AlarmContactEmail";
import AlarmContactPhone from "./AlarmContactPhone";
class AlarmContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      forceChildControlsReRender: 0, // not ideal but makes validation easier on child components - TODO put validation in redux
      contactId: this.props.contactEditing.contactId,
      contactName: this.props.contactEditing.contactName,
      timezoneId: this.props.contactEditing.timezoneId,
      alarmContactUpdate: {},
      emailContacts: this.props.contactEditing.emailContacts,
      phoneContacts: this.props.contactEditing.phoneContacts,
      errors: {},
    };
    this.saveContact = this.saveContact.bind(this);
    this.updateTimezone = this.updateTimezone.bind(this);
    this.updateEmailAvailability = this.updateEmailAvailability.bind(this);
    this.updatePhoneAvailability = this.updatePhoneAvailability.bind(this);
    this.updateSms = this.updateSms.bind(this);
    this.updateVoiceMessage = this.updateVoiceMessage.bind(this);
    this.handleEmailChanged = this.handleEmailChanged.bind(this);
    this.updatePhone = this.updatePhone.bind(this);
    this.closeAddContact = this.closeAddContact.bind(this);
  }

  componentDidMount() {
    this.props.loadTimezones();
    this.props.loadAvailabilitySchedule();
  }

  updateContactName(e) {
    this.setState({ contactName: e.target.value });
  }

  updateTimezone(e) {
    let timezoneId = null;
    timezoneId = e.target.value;
    this.setState({ timezoneId: timezoneId });
  }

  updateEmail(e) {
    this.setState({ email: e.target.value });
  }

  updateEmailAvailability(availabilityId, id) {
    let emails = this.state.emailContacts;
    let index = emails.findIndex((e) => {
      return e.id === id;
    });
    if (index > -1) {
      emails[index].availabilityId = availabilityId;
      this.setState({ emailContacts: emails });
    }
  }

  getPhonesIndexFromId(id) {
    let index = -1;

    let phones = this.state.phoneContacts;
    index = phones.findIndex((p) => {
      return p.id === id;
    });

    return index;
  }

  getEmailIndexFromId(id) {
    let index = -1;

    let emails = this.state.emailContacts;
    index = emails.findIndex((e) => {
      return e.id === id;
    });

    return index;
  }

  updatePhone(e, id) {
    let phones = this.state.phoneContacts;
    const index = this.getPhonesIndexFromId(id);

    if (index > -1) {
      phones[index].phoneNumber = e.target.value;
      this.setState({ phoneContacts: phones });
    }
  }

  updatePhoneAvailability(availabilityId, id) {
    let phones = this.state.phoneContacts;
    const index = this.getPhonesIndexFromId(id);

    if (index > -1) {
      phones[index].availabilityId = availabilityId;
      this.setState({ phoneContacts: phones });
    }
  }

  updateSms(smsFlag, id) {
    let phones = this.state.phoneContacts;
    const index = this.getPhonesIndexFromId(id);

    if (index > -1) {
      phones[index].smsFlag = smsFlag;
      this.setState({ phoneContacts: phones });
    }
  }

  updateVoiceMessage(voiceFlag, id) {
    let phones = this.state.phoneContacts;
    const index = this.getPhonesIndexFromId(id);

    if (index > -1) {
      phones[index].voiceFlag = voiceFlag;
      this.setState({ phoneContacts: phones });
    }
  }

  handleEmailChanged(e, id) {
    let emails = this.state.emailContacts;
    const index = this.getEmailIndexFromId(id);

    if (index > -1) {
      emails[index].email = e.target.value;
      this.setState({ emailContacts: emails });
    }
  }

  addAlarmContactEmailComponent() {
    let components = this.state.emailContacts;
    components.push({
      id: this.state.emailContacts.length,
      email: "",
      availabilityId: -1,
      errors: {
        email: "",
        availabilityId: "",
      },
    });

    this.setState({ emailContacts: components });
  }

  addAlarmContactPhoneComponent() {
    let components = this.state.phoneContacts;
    components.push({
      id: this.state.phoneContacts.length,
      phoneNumber: "",
      availabilityId: -1,
      smsFlag: "N",
      voiceFlag: "N",
      errors: {
        phoneNumber: "",
        availabilityId: "",
      },
    });

    this.setState({ phoneContacts: components });
  }

  saveContact() {
    this.setState({ erors: {} });
    const newErrors = this.validateInput();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors });
      return;
    }

    let emails = [];

    this.state.emailContacts.forEach((e) => {
      const alarmContactEmail = {
        contactId: this.state.contactId,
        email: e.email,
        availabilityScheduleId: e.availabilityId,
        contactEmailId: this.state.contactId === -1 ? -1 : e.id,
      };

      emails.push(alarmContactEmail);
    });

    let phoneNos = [];

    this.state.phoneContacts.forEach((p) => {
      const alarmContactPhone = {
        contactId: this.state.contactid,
        phoneNumber: p.phoneNumber,
        availabilityScheduleId: p.availabilityId,
        smsFlag: p.smsFlag,
        voiceFlag: p.voiceFlag,
        contactPhoneId: this.state.contactId === -1 ? -1 : p.id,
      };

      phoneNos.push(alarmContactPhone);
    });

    const alarmContactUpdate = {
      alarmContact: {
        contactId: this.state.contactId,
        name: this.state.contactName,
        customerId: -1,
        timezoneId: this.state.timezoneId,
      },
      emails: emails,
      phoneNumbers: phoneNos,
    };

    this.setState({ alarmContactUpdate: alarmContactUpdate });

    this.props.upsertContact(alarmContactUpdate).then((newContactId) => {
      this.closeAddContact();
      this.props.addNewContact(newContactId);
    });
  }

  validateInput() {
    const newErrors = {};

    if (this.state.contactName === "") {
      newErrors.contactName = "Please enter a name";
    }

    if (this.state.timezoneId === null) {
      newErrors.timezoneId = "Please choose a timezone";
    }

    const emailsEntered = this.state.emailContacts.filter(
      (e) => e.email.length > 0
    ).length;

    const phonesEntered = this.state.phoneContacts.filter(
      (p) => p.phoneNumber.length > 0
    ).length;

    if (emailsEntered === 0 && phonesEntered === 0) {
      newErrors.missingEmailPhone =
        "Please enter at least one email or one phone number";
    }

    // let emails = this.state.emailContacts;
    // let emailErrors = 0;
    // emails.forEach((e) => {
    //   if (e.email === "") {
    //     e.errors.email = "Please enter an email address";
    //     emailErrors++;
    //   } else {
    //     e.errors.email = "";
    //   }

    //   if (e.availabilityId === -1) {
    //     e.errors.availabilityId = "Please select availability";
    //     emailErrors++;
    //   } else {
    //     e.errors.availabilityId = "";
    //   }
    // });

    // if (emailErrors > 0) {
    //   newErrors.emailErrors = emailErrors;
    // }

    let phones = this.state.phoneContacts;
    let phoneErrors = 0;
    phones.forEach((p) => {
      if (
        p.phoneNumber.length > 0 &&
        !this.validatePhoneNumber(p.phoneNumber)
      ) {
        p.errors.phoneNumber =
          "Please enter a phone number in interational format e.g. +614321123123";
        phoneErrors++;
      } else {
        p.errors.phoneNumber = "";
      }

      if (p.availabilityId === -1) {
        p.errors.availabilityId = "Please select availability";
        phoneErrors++;
      } else {
        p.errors.availabilityId = "";
      }
    });

    if (phoneErrors > 0) {
      newErrors.phoneErrors = phoneErrors;
    }

    this.setState({
      // emailcontacts: emails,
      phoneContacts: phones,
      forceChildControlsReRender: this.state.forceChildControlsReRender + 1,
    });

    return newErrors;
  }

  validatePhoneNumber(phoneNo) {
    // starts with a '+'
    // numbers only
    // no spaces
    // no brackets
    let re = /^\+\d+$/;
    return re.test(phoneNo);
  }

  closeAddContact() {
    this.props.closeAddContact();
  }

  render() {
    return (
      <div>
        <Form className="form-group-container">
          <Row className="form-row">
            <Col className="form-col-label-alarm-contact">Name:</Col>
            <Col className="form-col-input-alarm-contact">
              <Form.Control
                size="sm"
                value={this.state.contactName}
                onChange={(e) => this.updateContactName(e)}
                isInvalid={!!this.state.errors.contactName}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {this.state.errors.contactName}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="form-row">
            <Col className="form-col-label-alarm-contact">Timezone:</Col>
            <Col className="form-col-input-alarm-contact">
              <select
                className="form-control form-control-sm"
                onChange={this.updateTimezone}
                value={this.state.timezoneId}
              >
                {this.props.timezones.map((item, index) => {
                  return <option value={item.timezoneId}>{item.name}</option>;
                })}
              </select>
              {this.state.errors.timezoneId && (
                <div className="invalid">{this.state.errors.timezoneId}</div>
              )}
            </Col>
          </Row>
          <br />
          <Row className="form-row invalid">
            {this.state.errors.missingEmailPhone}
          </Row>
          <Row className="form-row">Email Address(es)</Row>
          {this.state.emailContacts.map((item) => {
            return (
              <AlarmContactEmail
                id={item.id}
                key={item.id}
                email={item.email}
                updateEmail={this.handleEmailChanged}
                updateEmailAvailability={this.updateEmailAvailability}
                availabilityId={item.availabilityId}
                errors={item.errors}
                forceChildControlsReRender={
                  this.state.forceChildControlsReRender
                }
              ></AlarmContactEmail>
            );
          })}
          <Row className="form-row">
            <Col></Col>
            <Col></Col>
            <Col>
              <Button
                className="btn-sm plus-button"
                onClick={() => this.addAlarmContactEmailComponent()}
              >
                +
              </Button>
            </Col>
          </Row>
          <Row className="form-row">Phone Number(s)</Row>
          {this.state.phoneContacts.map((item) => {
            return (
              <AlarmContactPhone
                id={item.id}
                key={item.id}
                smsFlag={item.smsFlag}
                voiceFlag={item.voiceFlag}
                phoneNumber={item.phoneNumber}
                availabilitySchedule={this.props.availabilitySchedule}
                availabilityId={item.availabilityId}
                updatePhone={this.updatePhone}
                updatePhoneAvailability={this.updatePhoneAvailability}
                updateSms={this.updateSms}
                updateVoiceMessage={this.updateVoiceMessage}
                errors={item.errors}
                forceChildControlsReRender={
                  this.state.forceChildControlsReRender
                }
              ></AlarmContactPhone>
            );
          })}
          <Row className="form-row">
            <Col></Col>
            <Col></Col>
            <Col>
              <Button
                className="btn-sm plus-button"
                onClick={() => this.addAlarmContactPhoneComponent()}
              >
                +
              </Button>
            </Col>
          </Row>
          <Row className="form-row">
            <Col></Col>
            <Col></Col>
            <Col>
              <Button
                className="cancel-button"
                onClick={() => this.closeAddContact()}
              >
                Cancel
              </Button>
              <Button className="save-button" onClick={this.saveContact}>
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

AlarmContact.defaultProps = {
  timezones: [],
  availabilitySchedule: [],
};

function mapStateToProps(state, ownProps) {
  return {
    timezones: getTimezones(state),
    availabilitySchedule: getAvailabilitySchedule(state),
  };
}

const mapDispatchToProps = {
  loadTimezones,
  loadAvailabilitySchedule,
  upsertContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmContact);
