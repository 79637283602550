import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function deviceGroupsReducer(
  state = initialState.deviceGroups,
  action
) {
  switch (action.type) {
    case types.LOAD_DEVICE_GROUPS_SUCCESS: {
      // remove the all groups setting, this is always selected
      //   let deviceGroups = action.deviceGroups.filter(
      //     (g) => g.deviceGroupId !== 1
      //   );
      //   deviceGroups[2].memberFlag = "Y";
      //return deviceGroups;
      //return action.deviceGroups.filter((g) => g.deviceGroupId !== 1);
      return action.deviceGroups;
    }
    default:
      return state;
  }
}
