import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function deviceInventoryHistoryReducer(
  state = initialState.deviceInventoryHistory,
  action
) {
  switch (action.type) {
    case types.LOAD_DEVICE_INVENTORY_HISTORY_SUCCESS: {
      const deviceInventoryHistory = {
        deviceId: action.deviceId,
        inventoryHistory: action.deviceInventoryHistory.stockChangedEventList,
        token: action.deviceInventoryHistory.token,
      };

      if (state.length === 0) {
        let firstState = [];
        firstState.push(deviceInventoryHistory);
        return firstState;
      }

      const deviceIndex = state.findIndex(
        (inv) => inv.deviceId === action.deviceId
      );
      if (deviceIndex > -1) {
        let newState = JSON.parse(JSON.stringify(state));
        newState[deviceIndex].inventoryHistory =
          action.deviceInventoryHistory.stockChangedEventList;
        newState[deviceIndex].token = action.deviceInventoryHistory.token;

        return newState;
      } else {
        return [...state, deviceInventoryHistory];
      }
    }
    case types.LOAD_DEVICE_INVENTORY_HISTORY_MORE_SUCCESS: {
      const deviceIndex = state.findIndex(
        (inv) => inv.deviceId === action.deviceId
      );
      if (deviceIndex > -1) {
        let newState = JSON.parse(JSON.stringify(state));
        const currentHistory = newState[deviceIndex].inventoryHistory;
        const newHistory = currentHistory.concat(
          action.deviceInventoryHistory.stockChangedEventList
        );
        newState[deviceIndex].inventoryHistory = newHistory;
        newState[deviceIndex].token = action.deviceInventoryHistory.token;

        return newState;
      }
      return state;
    }
    case types.REFRESH_DEVICE_INVENTORY_HISTORY_SUCCESS: {
      const deviceInventoryHistory = {
        deviceId: action.deviceId,
        inventoryHistory: action.deviceInventoryHistory.stockChangedEventList,
        token: action.deviceInventoryHistory.token,
      };

      if (state.length === 0) {
        let firstState = [];
        firstState.push(deviceInventoryHistory);
        return firstState;
      }

      const deviceIndex = state.findIndex(
        (inv) => inv.deviceId === action.deviceId
      );
      if (deviceIndex > -1) {
        let newState = JSON.parse(JSON.stringify(state));
        newState[deviceIndex].inventoryHistory =
          action.deviceInventoryHistory.stockChangedEventList;
        newState[deviceIndex].token = action.deviceInventoryHistory.token;

        return newState;
      }
      return state;
    }
    default:
      return state;
  }
}
