import React, { Component } from "react";
import { connect } from "react-redux";
import { getTelemetrySeriesData } from "./selectors/deviceTelemetryChartDataSelector";
import CustomChartLayout from "./CustomChartLayout";
//import ApexCharts from "apexcharts";
import { getSelectedDevice } from "../deviceList/selectors/deviceListSelector";
import { isEmpty } from "../utils/generalUtils";
import { chartColours } from "./chartColours";
import JSONPretty from "react-json-pretty";

class DeviceTelemetryChart extends Component {
    getYaxis(seriesData) {
        const doorSeries = {
            opposite: true,
            seriesName: "Door - Fridge",
            tickAmount: 1,
            decimalsInFloat: 1,
            min: -0.1,
            max: 1.1,
            title: {
                text: "Door / Compressor",
            },
            show: true,
            showAlways: true,
        };

        const tempFridgeSeries = {
            seriesName: "Temperature - Fridge",
            tickAmount: 4,
            show: true,
            showAlways: true,
            decimalsInFloat: 2,
            min: 0.0,
            title: {
                text: "Temperature / Voltage",
            },
        };

        const yAxis = seriesData.map((s) => {
            switch (s.name) {
                case "Door - Fridge":
                    return doorSeries;
                case "Temperature - Fridge":
                    return tempFridgeSeries;
                case "Voltage - Main":
                case "Voltage - Main(Out)":
                case "Voltage - Battery":
                case "Temperature - Device Chip":
                case "Temperature - Product":
                case "Temperature - Air": {
                    const sharedTempSeries = {
                        seriesName: "Temperature - Fridge",
                        show: false,
                        min: 0.0,
                    };
                    return sharedTempSeries;
                }
                case "Compressor" :
                case "Door - Magnet": {
                    const sharedDoorSeries = {
                        opposite: true,
                        decimalsInFloat: 0,
                        min: -0.1,
                        max: 1.1,
                         seriesName: "Door - Fridge",
                        title: {
                            text: "Door - Magnet",
                        },
                        show: false,
                    };
                    return sharedDoorSeries;
                }
                default: {
                    const series = {
                        seriesName: s.name,
                        show: false,
                        min: 0.0,
                    };

                    return series;
                }
            }
        });

        return yAxis;
    }

  getOptions(seriesData) {
    const yAxis = this.getYaxis(seriesData);

      const jf = 1;
  

    const options = {
        id: "telemetryChart",
        height: 750,
        type: "line",
        zoom: {
          enabled: false,
        },
      colors: chartColours,
      // markers: {
      //   size: [0, 0, 2, 2, 2, 2, 5, 10],
      // },
      animations: {
        enabled: false,
        easing: "linear",
        speed: 1000,
        animateGradually: {
          enabled: false,
          //delay: 150,
        },
        dynamicAnimation: {
          enabled: false,
          speed: 1000,
        },
      },
      dataLabels: {
         enabled: false,
      },
      stroke: {
        curve: "straight",
        lineCap: "round",
        width: [1, 1, 1],
      },
      title: {
        text: "Device Telemetry",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      yaxis: yAxis.length > 0 ? yAxis : [],
      xaxis: {
        type: "time",
        labels: {
          format: "HH:mm",
        },
        title: {
          text: "Time",
        },
      },
    };

    return options;
  }

  componentDidUpdate() {
    // console.log("Device Telemetry Chart, componentDidUpdate");
    // console.log(this.props.seriesData);
    // if (this.props.seriesData.length > 0)
    //   ApexCharts.exec(
    //     "telemetryChart",
    //     "hideSeries",
    //     "Temperature - Device Chip"
    //   );
  }

  render() {
    let device = "No Device Selected";
    if (!isEmpty(this.props.device)) {
      device = this.props.device.name;
    }
    let options = [];
    options = this.getOptions(this.props.seriesData);



    return (
      <div className="content-container">
        <h4>{device}</h4>
        <CustomChartLayout
          options={options}
        series={this.props.seriesData}
 
        />
        {/* // Debug info */}
        {/* <h1>Debug Info</h1>
        <h1>this.props.seriesData</h1>
        <div>{JSON.stringify(this.props.seriesData)}</div>
        <h2>seriesData</h2>
        <div>
          <JSONPretty data={this.props.seriesData}></JSONPretty>
        </div>
        <h2>options</h2>
        <JSONPretty data={options}></JSONPretty>
        <div>{JSON.stringify(options)}</div> */}
      </div>
    );
  }
}

DeviceTelemetryChart.defaultProps = {
  seriesData: [],
  device: {},
};

function mapStateToProps(state, ownProps) {
  const device = getSelectedDevice(state);
  const seriesData = getTelemetrySeriesData(state, device.hardwareId);

  return {
    seriesData: seriesData,
    device: device,
  };
}
export default connect(mapStateToProps, null)(DeviceTelemetryChart);
