import React, { Component } from "react";
import { connect } from "react-redux";
import { getSelectedDevice } from "../deviceList/selectors/deviceListSelector";
import { getDeviceGroups } from "../deviceGroup/selectors/deviceGroupSelector";
import { isEmpty } from "../utils/generalUtils";
import {
  loadGeneralDeviceSettings,
  updateGeneralDeviceSettings,
  pushConfigUi,
} from "./actions/generalSettingsActions";
import { getGeneralDeviceSettings } from "./selectors/generalSettingsSelectors";
import { getCustomerLocations } from "../customer/selectors/customerSelector";
import {
  loadCustomerLocations,
  deleteLocation,
} from "../customer/actions/customerActions";
import {
  loadDeviceGroups,
  updateDeviceGroup,
} from "../deviceGroup/actions/deviceGroupActions";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SelectSearch, { fuzzySearch } from "react-select-search";
import "./SelectSearchStyles.css";
import Multiselect from "multiselect-react-dropdown";
import Modal from "react-bootstrap/Modal";
import CustomerLocation from "../customer/CustomerLocation";
import JSONPretty from "react-json-pretty";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./DeviceConfig.css";
class GeneralSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readOnly: true,
      name: "",
      customerCode: "",
      customerLocationId: 3,
      minProductTemperature: 0,
      maxProductTemperature: 0,
      customerRef1: "",
      customerRef2: "",
      notes: "",
      errors: {},
      customerLocationDropdownOptions: [],
      deviceGroups: [],
      options: [
        { name: "Option 1", id: 1 },
        { name: "Option 2", id: 2 },
      ],
      showCustomerLocationDialog: false,
      selectedLocation: {},
      showWarningDeleteDialog: false,
      activeFlag: "Y",
    };

    this.multiselectRef = React.createRef();

    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.disableEditMode = this.disableEditMode.bind(this);
    this.onSelectDeviceGroup = this.onSelectDeviceGroup.bind(this);
    this.onRemoveDeviceGroup = this.onRemoveDeviceGroup.bind(this);
    this.handleShowCustomerLocationDialog =
      this.handleShowCustomerLocationDialog.bind(this);
    this.handleHideCustomerLocationDialog =
      this.handleHideCustomerLocationDialog.bind(this);
    this.addNewCustomerLocation = this.addNewCustomerLocation.bind(this);
    this.showWarningDeleteDialog = this.showWarningDeleteDialog.bind(this);
    this.closeWarningDeleteDialog = this.closeWarningDeleteDialog.bind(this);
    this.deleteCustomerLocation = this.deleteCustomerLocation.bind(this);
    this.loadCustomerLocations = this.loadCustomerLocations.bind(this);

    this.renderValue = this.renderValue.bind(this);
    this.renderDropdownOption = this.renderDropdownOption.bind(this);
  }

  showWarningDeleteDialog() {
    this.setState({ showWarningDeleteDialog: true });
  }

  closeWarningDeleteDialog() {
    this.setState({ showWarningDeleteDialog: false });
  }

  deleteCustomerLocation() {
    // TODO: 3 == default - Not defined, for customerID 2, need to confirm best to set default or find this setting per customer
    if (this.state.customerLocationId === 3) return;

    const locationToDelete = this.state.customerLocationId;

    this.saveLocationSettings(0);
    this.props.deleteLocation(locationToDelete);
    this.closeWarningDeleteDialog();
  }

  saveLocationSettings(locationId) {
    const generalSettings = {
      name: this.state.name,
      customerCode: this.state.customerCode,
      customerLocationId: locationId,
      minProductTemperature: this.state.minProductTemperature,
      maxProductTemperature: this.state.maxProductTemperature,
      notes: this.state.notes,
      customerRef1: this.state.customerRef1, // todo
      customerRef2: this.state.customerRef2, // todo
      activeFlag: this.state.activeFlag,
    };

    debugger;
    this.props.updateGeneralDeviceSettings(
      this.props.device.id,
      generalSettings
    );
  }

  addNewCustomerLocation() {
    this.setState({
      selectedLocation: {},
      showCustomerLocationDialog: true,
    });
  }
  handleShowCustomerLocationDialog() {
    this.setState({ showCustomerLocationDialog: true });
  }

  handleHideCustomerLocationDialog() {
    this.setState({ showCustomerLocationDialog: false });
  }

  toggleEditMode() {
    this.setState((prevState) => ({ readOnly: !prevState.readOnly }));
  }

  disableEditMode() {
    this.setState({ readOnly: true, errors: {} });
  }

  componentDidMount() {
    this.loadCustomerLocations();
  }

  loadCustomerLocations() {
    this.props
      .loadCustomerLocations()
      .then(() => this.setUpCustomerLocationsDropDownData());
  }

  componentDidUpdate(prevProps) {
    if (this.props.device !== prevProps.device) {
      this.props.loadGeneralDeviceSettings(this.props.device.id).then(() => {
        const selectedLocation = this.props.customerLocations.find(
          (c) =>
            c.customerLocationId ===
            this.props.generalSettings.customerLocationId
        );

        this.setState({
          name: this.props.generalSettings.name,
          customerLocationId: this.props.generalSettings.customerLocationId,
          customerCode: this.props.generalSettings.customerCode ?? "",
          minProductTemperature:
            this.props.generalSettings.minProductTemperature,
          maxProductTemperature:
            this.props.generalSettings.maxProductTemperature,
          customerRef1: this.props.generalSettings.customerRef1 ?? "",
          customerRef2: this.props.generalSettings.customerRef2 ?? "",
          notes: this.props.generalSettings.notes ?? "",
          selectedLocation: selectedLocation ?? {},
          activeFlag: this.props.generalSettings.activeFlag ?? "Y",
        });
      });

      this.props.loadDeviceGroups(this.props.device.id).then(() => {
        this.setState({
          deviceGroups: this.props.deviceGroups,
        });
      });
    }

    if (this.props.customerLocations !== prevProps.customerLocations) {
      this.setUpCustomerLocationsDropDownData();
    }
  }

  setUpCustomerLocationsDropDownData() {
    const options = this.props.customerLocations.map((l) => {
      return {
        name: l.description,
        value: l.customerLocationId,
        address: l.address,
        physicalRegion: l.physicalRegion,
        customerRef: l.customerRef,
      };
    });

    this.setState({
      customerLocationDropdownOptions: options,
    });
  }

  renderDropdownOption(props, option, snapshot, className) {
    return (
      <button {...props} className={className} type="button">
        <div>
          <div className="location-option-name">{option.name}</div>
          <div className="location-option-address">{option.address}</div>
          <div className="location-option">{option.physicalRegion}</div>
          <div className="location-option">{option.customerRef}</div>
        </div>
      </button>
    );
  }

  updateName(e) {
    this.setState({ name: e.target.value });
  }
  updateCustomerCode(e) {
    this.setState({ customerCode: e.target.value });
  }

  handleCustomerLocationChange = (customerLocationId) => {
    this.setState({ customerLocationId });
  };

  updateMinProductTemperature(e) {
    this.setState({ minProductTemperature: e.target.value });
  }

  updateMaxProductTemperature(e) {
    this.setState({ maxProductTemperature: e.target.value });
  }

  updateCustomerRef1(e) {
    this.setState({ customerRef1: e.target.value });
  }

  updateCustomerRef2(e) {
    this.setState({ customerRef2: e.target.value });
  }

  updatePeriodicReportFlag(e) {
    this.setState({ periodicReportFlag: e.target.value });
  }

  updateNotes(e) {
    this.setState({ notes: e.target.value });
  }

    updateDeviceActiveFlag(e) {
        this.setState({ activeFlag: e.target.checked ? "Y" : "N" });
  }

  onSelectDeviceGroup(selectedList, selectedItem) {
    const deviceGroupData = {
      deviceId: this.props.device.id,
      deviceGroupId: selectedItem.deviceGroupId,
      memberFlag: "Y",
    };
    this.props.updateDeviceGroup(deviceGroupData);
  }

  onRemoveDeviceGroup(selectedList, removedItem) {
    const deviceGroupData = {
      deviceId: this.props.device.id,
      deviceGroupId: removedItem.deviceGroupId,
      memberFlag: "N",
    };
    this.props.updateDeviceGroup(deviceGroupData);
  }
  saveSettings() {
    if (this.state.readOnly) return;

    const newErrors = this.validateInput();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors });
      return;
    }

    const generalSettings = {
      name: this.state.name,
      customerCode: this.state.customerCode,
      customerLocationId: this.state.customerLocationId,
      minProductTemperature: this.state.minProductTemperature,
      maxProductTemperature: this.state.maxProductTemperature,
      notes: this.state.notes,
      customerRef1: this.state.customerRef1, // todo
      customerRef2: this.state.customerRef2, // todo
      activeFlag: this.state.activeFlag,
    };

    this.disableEditMode();

    this.props.updateGeneralDeviceSettings(
      this.props.device.id,
      generalSettings
    );
  }

  validateInput() {
    const newErrors = {};
    if (
      this.state.minProductTemperature === "" ||
      isNaN(this.state.minProductTemperature)
    ) {
      newErrors.minProductTemperature = "Please enter a number";
    }

    if (
      this.state.maxProductTemperature === "" ||
      isNaN(this.state.maxProductTemperature)
    ) {
      newErrors.maxProductTemperature = "Please enter a number";
    }

    return newErrors;
  }

  pushConfigUi() {
    this.props.pushConfigUi(this.props.device.id).then(() => {
      toast.success("Config settings successfully sent.", {
        hideProgressBar: true,
        autoClose: 10000,
      });
    });
  }

  renderValue(valueProps, snapshot) {
    //const inputValue = this.state.selectedLocationInput;

    const inputValue = snapshot.focus ? snapshot.search : snapshot.displayValue;

    return (
      <input
        {...valueProps}
        value={inputValue}
        className="select-search__input"
      />
    );
  }

  render() {
    let device = "No Device Selected";
    if (!isEmpty(this.props.device)) {
      device = `${this.props.device.hardwareId} - ${this.props.device.name}`;
    }
    return (
      <div className="content-container">
        <h4>{device}</h4>
            <Form className="form-group-container">

                <Form.Row>

                    <Form.Group className="col-12 col-md-9">
            <Form.Label>Name</Form.Label>
            <Form.Control
              size="sm"
              readOnly={this.state.readOnly}
              value={this.state.name}
              onChange={(e) => this.updateName(e)}
            />
          </Form.Group>

                <Form.Group className="col-12 col-md-3 align-self-end">
                    <div className="checkbox-wrapper">
                        <Form.Label htmlFor="is-active">Active Unit</Form.Label>
                        <Form.Check
                            id="is-active"
                            size="sm"
                            type="switch"
                            readOnly={this.state.readOnly}
                            disabled={this.state.readOnly}
                            onChange={(e) => {
                                this.updateDeviceActiveFlag(e);
                            }}
                            checked={this.state.activeFlag === "Y" ? true : false}
                        />
                    </div>
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.activeFlag}
                    </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
            <Form.Group className="col-12 col-md-6">
            <Form.Label>Customer Code</Form.Label>
            <Form.Control
              size="sm"
              readOnly={this.state.readOnly}
              value={this.state.customerCode}
              onChange={(e) => this.updateCustomerCode(e)}
            />
                    </Form.Group>
                </Form.Row>
          <Form.Group>
            <Form.Label>Customer Location</Form.Label>

            <SelectSearch
              options={this.state.customerLocationDropdownOptions}
              value={this.state.customerLocationId}
              renderValue={this.renderValue}
              renderOption={this.renderDropdownOption}
              search
              filterOptions={fuzzySearch}
              // name="location"
              onChange={this.handleCustomerLocationChange}
              placeholder="Choose customer location"
              disabled={this.state.readOnly}
              // printOptions="always" // keeps dropdown open - useful for styling/debugging
            />
          </Form.Group>
          <Form.Row>
            <Col className="float-right">
              <Button
                className="float-right"
                size="sm"
                variant="primary"
                onClick={this.showWarningDeleteDialog}
              >
                Delete
              </Button>
              <Button
                size="sm"
                variant="primary"
                className="form-group-button float-right"
                onClick={this.handleShowCustomerLocationDialog}
              >
                Edit
              </Button>
              <Button
                size="sm"
                variant="primary"
                className="form-group-button float-right"
                onClick={this.addNewCustomerLocation}
              >
                Add
              </Button>
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Group className="col-12 col-md-3">
                <Form.Label>Minimum Product Temperature</Form.Label>
                <Form.Control
                size="sm"
                readOnly={this.state.readOnly}
                value={this.state.minProductTemperature}
                onChange={(e) => this.updateMinProductTemperature(e)}
                isInvalid={!!this.state.errors.minProductTemperature}
                />
                <Form.Control.Feedback type="invalid">
                {this.state.errors.minProductTemperature}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-12 col-md-3">
                <Form.Label>Maximum Product Temperature</Form.Label>
                <Form.Control
                size="sm"
                readOnly={this.state.readOnly}
                value={this.state.maxProductTemperature}
                onChange={(e) => this.updateMaxProductTemperature(e)}
                isInvalid={!!this.state.errors.maxProductTemperature}
                />
                <Form.Control.Feedback type="invalid">
                {this.state.errors.maxProductTemperature}
                </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="col-12 col-md-6">
                <Form.Label>Customer Ref 1</Form.Label>
                <Form.Control
                size="sm"
                readOnly={this.state.readOnly}
                value={this.state.customerRef1}
                onChange={(e) => this.updateCustomerRef1(e)}
                />
            </Form.Group>
            <Form.Group className="col-12 col-md-6">
                <Form.Label>Customer Ref 2</Form.Label>
                <Form.Control
                size="sm"
                readOnly={this.state.readOnly}
                value={this.state.customerRef2}
                onChange={(e) => this.updateCustomerRef2(e)}
                />
            </Form.Group>
          </Form.Row>
          <Form.Group>
            <Form.Label>Notes</Form.Label>

            <Form.Control
              size="sm"
              as="textarea"
              rows={5}
              readOnly={this.state.readOnly}
              value={this.state.notes}
              onChange={(e) => this.updateNotes(e)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Device Groups</Form.Label>
            <Multiselect
              ref={this.multiselectRef}
              options={this.state.deviceGroups}
              displayValue="description"
              showCheckbox={true}
              closeOnSelect={false}
              disable={this.state.readOnly}
              selectedValues={this.state.deviceGroups.filter(
                (g) => g.memberFlag === "Y"
              )}
              onSelect={this.onSelectDeviceGroup}
              onRemove={this.onRemoveDeviceGroup}
            ></Multiselect>
          </Form.Group>
          <Form.Row>
            <Col>
              <Button onClick={() => this.pushConfigUi()}>Push Config</Button>
            </Col>
            <Col className="float-right">
              <Button
                className="float-right"
                size="sm"
                variant="primary"
                onClick={this.saveSettings}
              >
                Save
              </Button>
              <Button
                size="sm"
                variant="primary"
                className="form-group-button float-right"
                onClick={this.toggleEditMode}
              >
                Edit
              </Button>
            </Col>
          </Form.Row>
        </Form>
        <br />
        <Modal
          show={this.state.showCustomerLocationDialog}
          onHide={this.handleHideCustomerLocationDialog}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Customer Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomerLocation
              location={this.state.selectedLocation}
              close={this.handleHideCustomerLocationDialog}
              updateCustomerLocation={this.handleCustomerLocationChange}
            ></CustomerLocation>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showWarningDeleteDialog}
          onHide={this.closeWarningDeleteDialog}
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Are you sure you want to delete this location?</div>
            <Button
              className="float-right actions-button"
              onClick={this.closeWarningDeleteDialog}
            >
              No
            </Button>
            <Button
              className="float-right actions-button"
              onClick={this.deleteCustomerLocation}
            >
              Yes
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

GeneralSettings.defaultProps = {
  device: {},
  generalSettings: {},
  customerLocations: [],
  deviceGroups: [],
};

function mapStateToProps(state) {
  const device = getSelectedDevice(state);
  return {
    device: device,
    generalSettings: getGeneralDeviceSettings(state),
    customerLocations: getCustomerLocations(state),
    deviceGroups: getDeviceGroups(state),
  };
}

const mapDispatchToProps = {
  loadGeneralDeviceSettings,
  loadCustomerLocations,
  updateGeneralDeviceSettings,
  loadDeviceGroups,
  updateDeviceGroup,
  deleteLocation,
  pushConfigUi,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
