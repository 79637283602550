import { callAdminApiPost, callAdminApiGet } from "../../api/apiUtils";

export async function getDeviceGroups(deviceId) {
  const url = `api/devicegroup/groups/${deviceId}`;

  return callAdminApiGet(url);
}

export async function updateDeviceGroups(deviceGroupData) {
  const url = `api/devicegroup/updategroups`;
  return callAdminApiPost(url, deviceGroupData);
}
