import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function incidentListReducer(
  state = initialState.deviceAlarmConfigList,
  action
) {
  switch (action.type) {
    case types.LOAD_DEVICE_ALARM_CONFIG_LIST_SUCCESS: {
      return action.alarmConfigList;
    }
    case types.RESET_DEVICE_ALARM_CONFIG_LIST: {
      return [];
    }
    default:
      return state;
  }
}
