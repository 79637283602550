import * as statusApi from "../api/statusApi";
import { Error } from "../../errors/errorType";
import { logError } from "../../errors/actions/errorActions";

export function getPortalStatus() {
  return function (dispatch) {
    return statusApi.getPortalStatus().catch((error) => {
      const params = [];
      const err = new Error(
        "statusActions.js, getPortalStatus()",
        error.toString(),
        params
      );

      dispatch(logError(err));
    });
  };
}
