import { callAdminApiGet } from "../../../api/apiUtils";
import authService from "../../../api-authorization/AuthorizeService";

export async function addFavourite(deviceId) {
  const user = await authService.getUser();
  const url = `api/users/addfavourite/${user.userId}/${deviceId}`;

  return callAdminApiGet(url);
}

export async function deleteFavourite(deviceId) {
  const user = await authService.getUser();
  const url = `api/users/deletefavourite/${user.userId}/${deviceId}`;

  return callAdminApiGet(url);
}
