import React, { useState, useRef, useEffect } from 'react';
import Chart from 'react-apexcharts';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { chartColours } from "./chartColours";

function CustomChartLayout({ options, series, onHover }) {
  const chartRef = useRef(null);
  const tooltipRef = useRef(null);
  const [tooltipData, setTooltipData] = useState(null);

	// Calculate the maximum value from the series data
  const maxValue = Math.max(
    ...series.flatMap(serie => serie.data.map(point => point.y))
  );

  const minValue = Math.min(
		...series.flatMap(serie => serie.data.map(point => point.y))
	);


	useEffect(() => {
			setTooltipData({
			time: '--:--',
			dataset: [
			],
			default: "Please hover over a data point to see more information."
			});
	}, []);

// Define the event handler in the options object
	var customOptions = {
		...options,
//		yaxis: {
//      ...options.yaxis,
//      max: maxValue + 1,
//    },
		tooltip: {
			enabled: true,
			intersect: true,
			shared: false,
		},
		markers: {
			size: 4,
		},
		chart: {
			...options.chart,

			events: {
				dataPointMouseEnter: (event, chartContext, { seriesIndex, dataPointIndex }) => {
					const data = chartContext.w.config.series[seriesIndex].data[dataPointIndex];
					const xValue = data.x;
					const allDataPoints = chartContext.w.config.series.map(series => series.data.find(point => point.x === xValue));
					const labels = chartContext.w.config.series.map(series => series.name);
					let formattedData = {
							time: xValue,
							dataset: [],
					};

					formattedData.dataset = allDataPoints.map((point, index) => ({
							label: labels[index],
							value: point.y
					}));

					setTooltipData(formattedData);
				},
			},
		},
	};


//	customOptions.max = maxValue + 1;
//	customOptions.min = 2;
	const jf = 0;


  return (
    <div className="chart-container">
      <div className="chart" ref={chartRef}>
        <Chart
          options={customOptions}
          series={series}
          type="line"
          height={750}
        />
      </div>
      <div className="aside" ref={tooltipRef}>
        {tooltipData && (
          <div className="aside-inner">
            <div className="aside-inner-head">
                <p>{tooltipData.time}</p>
            </div>
					  {tooltipData.dataset.length ? (
            <div className='aside-inner-body'>
							  {tooltipData.dataset.map((data, index) => (
								  <Row className="mb-0 justify-content-between">
									  <Col className="col-auto col-left">
										  <p><span className={'dot dot' + index}></span>{data.label}:</p>
									  </Col>
									  <Col className="col-3 col-details-right">
										  <p><strong>{data.value}</strong>&nbsp;</p>
										  </Col>
									  </Row>
                ))}
            </div>
						) : (
							<div className='aside-inner-body'>
								<p>{tooltipData.default}</p>
							</div>
						)}
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomChartLayout;
