import React, { Component } from "react";
import { Route } from "react-router";
import store from "./components/store/store";
import { Provider } from "react-redux";
import { Layout } from "./components/Layout";
import { UsersRouter } from "./components/users/UsersRouter";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import {
  ApplicationPaths,
  UserRoles,
} from "./components/api-authorization/ApiAuthorizationConstants";
import DashboardPage from "./components/dashboard/DashboardPage";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./custom.css";
// import KennyPage from "./components/prototype/KennyPage";
// import AzureServiceBus from "./components/prototype/AzureServiceBus";
// import AzureSignalrFunctionSubscription from "./components/prototype/AzureSignalrFunctionSubscription";
// import AzureServiceBusSubscriptionDirect from "./components/prototype/AzureServiceBusSubscriptionDirect";
import DeviceIncidentsPage from "./components/incidents/DeviceIncidentsPage";
// import DeviceListRedesignPage from "./components/prototype/DeviceListRedesignPage";
import TestPage from "./components/prototype/TestPage";
import ProvisioningPage from "./components/provisioning/ProvisioningPage";
import OtaPage from "./components/ota/OtaPage";
import PasswordResetPage from "./components/users/PasswordResetPage";
import StatusPage from "./components/status/StatusPage";
export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <div>
        <Provider store={store}>
          <Layout>
            {/* <Route exact path="/" component={Home} /> */}
            <AuthorizeRoute exact path="/" component={DashboardPage} />
            {/* <Route path="/dashboard" component={DashboardPage} /> */}
            {/* <Route path="/counter" component={Counter} />
            <AuthorizeRoute path="/fetch-data" component={FetchData} /> */}
            <AuthorizeRoute
              path="/users"
              roles={[UserRoles.Administrator, UserRoles.SuperAdmin]}
              component={UsersRouter}
            />
            <AuthorizeRoute
              path="/provisioning"
              roles={[UserRoles.Administrator, UserRoles.SuperAdmin]}
              component={ProvisioningPage}
            />
            <AuthorizeRoute
              path="/ota"
              roles={[UserRoles.SuperAdmin]}
              component={OtaPage}
            />
            <Route
              path={ApplicationPaths.ApiAuthorizationPrefix}
              component={ApiAuthorizationRoutes}
            />
            <Route path="/deviceincidents" component={DeviceIncidentsPage} />
            <Route path="/test" component={TestPage} />
            <Route path="/user/passwordreset" component={PasswordResetPage} />
            <Route path="/status" component={StatusPage} />
            {/* <Route path="/kenny" component={KennyPage} />
            <Route path="/azureservicebus" component={AzureServiceBus} />
            <Route
              path="/azurefunction"
              component={AzureSignalrFunctionSubscription}
            />
            <Route
              path="/azuredirect"
              component={AzureServiceBusSubscriptionDirect}
            />
            <Route path="/deviceincidents" component={DeviceIncidentsPage} /> */}
            {/* <Route
              path="/devicelistupdate"
              component={DeviceListRedesignPage}
            /> */}
            {/* <HelpWidget></HelpWidget> */}
            <ToastContainer transition={Slide} />
          </Layout>
        </Provider>
      </div>
    );
  }
}
