import * as types from "../../common/state/actionTypes";
import * as deviceListApi from "../api/deviceListApi";
import { logError } from "../../errors/actions/errorActions";

export const searchByValue = (search) => ({
  type: types.DEVICE_LIST_SEARCH_BY_VALUE,
  search,
});

export const filterByLocation = (location) => ({
  type: types.DEVICE_LIST_FILTER_BY_LOCATION,
  location,
});

export const filterByAlarm = () => ({
  type: types.DEVICE_LIST_FILTER_BY_AlARM,
});

export const filterByOk = () => ({
  type: types.DEVICE_LIST_FILTER_BY_OK,
});

export const filterByStatus = (status) => ({
  type: types.DEVICE_LIST_FILTER_BY_STATUS,
  status,
});

export const clearFilter = () => ({
  type: types.DEVICE_LIST_FILTER_CLEAR,
});

export const filterByFavourite = () => ({
  type: types.DEVICE_LIST_FILTER_BY_FAVOURITE,
});

export const filterByDoor = (doorState) => ({
  type: types.DEVICE_LIST_FILTER_BY_DOOR,
  doorState,
});

export const setSelectedDevice = (device) => ({
  type: types.DEVICE_LIST_SET_SELECTED_DEVICE,
  device,
});

export const loadExactPage = (page) => ({
  type: types.DEVICE_LIST_LOAD_EXACT_PAGE,
  page,
});

export const loadNewPage = (page) => ({
  type: types.DEVICE_LIST_LOAD_NEW_PAGE,
  page,
});

export const updateDeviceData = (device) => ({
  type: types.DEVICE_LIST_UPDATE_DEVICE_DATA,
  device,
});
export const deviceListLoadedSuccess = (devices) => ({
  type: types.LOAD_DEVICE_LIST_SUCCESS,
  devices: devices,
});

export const deviceListUpdateData = (devices) => ({
  type: types.DEVICE_LIST_UPDATE_DEVICE_LIST_DATA,
  devices: devices,
});

export function loadDeviceList(deviceGroupId) {
  return function (dispatch) {
    return deviceListApi
      .getDeviceList(deviceGroupId)
      .then((devices) => {
        dispatch(deviceListLoadedSuccess(devices));
      })
      .catch((error) => {
        const err = new Error(
          "deviceListActions.js, loadDeviceList()",
          error.toString()
        );
        dispatch(logError(err));
      });
  };
}

export const showHighTempOnly = (sortOrder) => ({
    type: types.DEVICE_LIST_SHOW_HIGH_TEMP,
    sortOrder,
});

export const isEnabled = (isEnabled) => ({
    type: types.DEVICE_LIST_IS_ENBALED,
    isEnabled,
});

export const filterByName = (sortOrder) => ({
    type: types.DEVICE_LIST_FILTER_BY_NAME,
    sortOrder,
});

export const filterByCustomerCode = (sortOrder) => ({
    type: types.DEVICE_LIST_FILTER_BY_CUSOMTER_CODE,
    sortOrder,
});

export const filterByVoltage = (sortOrder) => ({
    type: types.DEVICE_LIST_FILTER_BY_VOLTAGE,
    sortOrder,
});

export const filterByProdTemp = (sortOrder) => ({
    type: types.DEVICE_LIST_FILTER_BY_PROD_TEMP,
    sortOrder,
});

export const sortByDoor = (sortOrder) => ({
    type: types.DEVICE_LIST_SORT_BY_DOOR,
    sortOrder,
});
