export const getCustomer = (state) => state.customer;

export const getCustomerLocations = (state) => {
  return state.customerLocations;
};

// export const getCustomerLocaton = (state, customerLocationId) => {
//   return state.customerLocations.find(
//     (c) => c.customerLocationId === customerLocationId
//   );
// };
