import React, { Component } from "react";
import { connect } from "react-redux";
import { getDeviceCustomerContactInfo } from "./selectors/customerContactSelector";
import { getSelectedDevice } from "../deviceList/selectors/deviceListSelector";
import PropTypes from "prop-types";
import "./DeviceCustomerContact.css";
import JSONPretty from "react-json-pretty";
import Button from "react-bootstrap/Button";
import { loadDeviceCustomerContactInfo } from "./actions/customerContactActions";

const TempSrc =
  "https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=-35.1315587,138.5078798322273+(FridgeReporter%20Location)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed";
class DeviceCustomerContactInformation extends Component {
  styleLine(info) {
    switch (info.recordType) {
      case "T":
        return <h4>{info.itemLine}</h4>;
      case "H":
        return (
          <>
            <br />
            <h6>{info.itemLine}</h6>
          </>
        );
      case "D":
        return <div className="item-line">{info.itemLine}</div>;
      case "L":
        return (
          <div>
            {/* <div className="item-line">{info.itemLine}</div> */}
            <div
              className="map-container"
              dangerouslySetInnerHTML={{
                __html: this.buildIframe(info.itemLine),
              }}
            />
          </div>
        );
      default:
        return <div>{info.itemLine}</div>;
    }
  }

  buildIframe(src) {
    return `<iframe width='100%' height='500' frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src='${src}' />`;
  }

  refresh() {
    this.props.loadDeviceCustomerContactInfo(this.props.deviceId);
  }

  render() {
    return (
      <div className="content-container">
        <ul>
          {this.props.contactInfo.map((info) => {
            return (
              <li style={{ listStyleType: "none" }} key={info.sortOrder}>
                {this.styleLine(info)}
              </li>
            );
          })}
        </ul>
        {/* <JSONPretty data={this.props.contactInfo}></JSONPretty> */}
        <Button onClick={() => this.refresh()}>Refresh</Button>
      </div>
    );
  }
}

DeviceCustomerContactInformation.defaultProps = {
  contactInfo: [],
};

DeviceCustomerContactInformation.propTypes = {
  deviceId: PropTypes.number,
  contactInfo: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  const device = getSelectedDevice(state);
  const deviceId = device.id;
  return {
    contactInfo: getDeviceCustomerContactInfo(state, deviceId),
    deviceId: deviceId,
  };
}

const mapDispatchToProps = {
  loadDeviceCustomerContactInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceCustomerContactInformation);
