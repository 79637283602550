import React, { Component } from "react";
import { connect } from "react-redux";
import { getSelectedDevice } from "../deviceList/selectors/deviceListSelector";
import { getProductTotals } from "./selectors/deviceInventorySelector";

class InventoryHeading extends Component {
  render() {
    return (
      <>
        <h5>
          Inventory Items: {this.props.totalItems} {"  "}
          {this.props.productCounts.map((item, key) => {
            return (
              <span key={key}>
                {item.productName}: {item.totalItems}{" "}
              </span>
            );
          })}
        </h5>
      </>
    );
  }
}

InventoryHeading.defaultProps = {
  device: {},
  totalItems: 0,
  productCounts: [],
};

function mapStateToProps(state) {
  const device = getSelectedDevice(state);
  const totals = getProductTotals(state, device.id);
  return {
    device: device,
    totalItems: totals.totalItems,
    productCounts: totals.productCounts,
  };
}

export default connect(mapStateToProps, null)(InventoryHeading);
