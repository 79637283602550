import React, { Component } from "react";
import OtaGrid from "../ota/OtaGrid";
class OtaPage extends Component {
  render() {
    return (
      <div className="content-container">
        <div className="container">
          <h2>Ota Updates</h2>
          <div className="row">
            <div className="col">
              <OtaGrid></OtaGrid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OtaPage;
