import {
  callAdminApiPost,
  callAdminApiGet,
  callFridgeInsightsApiGet,
} from "../../api/apiUtils";
import { FridgeInsightsDeviceApi } from "../../configuration/config";

export async function getDeviceInventory(deviceId) {
  const url = `api/deviceinventory/getdeviceinventory/${deviceId}`;

  return callAdminApiGet(url);
}


export async function getDeviceInventoryProductCounts(deviceId) {
    const url = `api/deviceinventory/getdeviceinventoryproductcounts/${deviceId}`;

    return callAdminApiGet(url);
}

export async function getDeviceInventoryHistory(
  deviceId,
  recordsPerPage,
  token
) {
  const data = {
    deviceId: deviceId,
    recordsPerPage: recordsPerPage,
    token: token,
  };

  const url = `api/deviceinventory/getdeviceinventoryhistory`;

  return callAdminApiPost(url, data);
}

export async function getDeviceInventorySummary(deviceId) {
    const url = `api/deviceinventory/getdeviceinventorysummary/${deviceId}`;

    return callAdminApiGet(url);
}

export async function reviseExpiry(reviseExpiry) {
  const url = "api/deviceinventory/reviseExpiry";

  return callAdminApiPost(url, reviseExpiry);
}

export async function scanInventory(deviceHardwareId) {
  const url = `${FridgeInsightsDeviceApi}/${deviceHardwareId}/scan`;

  return callFridgeInsightsApiGet(url);
}

export async function scanInventorySp(deviceId) {
    const url = `api/deviceinventory/scaninventorysp/${deviceId}`;

    return callAdminApiGet(url);
}

export async function checkScan(deviceId) {
    const url = `api/deviceinventory/checkscan/${deviceId}`;

    return callAdminApiGet(url);
}

export async function checkScan2(deviceId) {

    const url = `api/deviceinventory/checkscan2/${deviceId}`;

    return callAdminApiGet(url);
}