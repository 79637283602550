import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function dataAnalysisReducer(
  state = initialState.dataAnalysisParameters,
  action
) {
  switch (action.type) {
    case types.SAVE_DATA_ANALYSIS_START_DATE: {
      return {
        ...state,
        startDate: action.startDate,
      };
    }
    case types.SAVE_DATA_ANALYSIS_END_DATE: {
      return {
        ...state,
        endDate: action.endDate,
      };
    }
    default:
      return state;
  }
}
