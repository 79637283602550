import React, { Component } from "react";
import { connect } from "react-redux";
import {
  loadDeviceProvisioningTemplates,
  reprovisionDeviceCheck,
  provisionDevice,
  getNextDeviceId,
  commissionNewDevice,
  checkDeviceProvisioningStatus,
} from "./actions/provisioningActions";
import { getDeviceProvisioningTemplates } from "./selectors/provisioningSelector";
import { getCurrentUser } from "../users/selectors/usersSelector";
import {
  setCustomerDetails,
  getAllCustomers,
} from "../customer/actions/customerActions";
import { getAllDevices } from "../deviceList/selectors/deviceListSelector";
import { getCustomers } from "../customer/selectors/customersSelector";
import { loadDeviceList } from "../deviceList/actions/deviceListActions";
import SelectSearch, { fuzzySearch } from "react-select-search";
import "./Provisioning.css";
import { isEmpty } from "../utils/generalUtils";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import JSONPretty from "react-json-pretty";

const DeviceIdNotSet = "Device ID Has Not Been Set";
class ProvisioningPage extends Component {
  constructor(props) {
    super(props);

    //   // can't use Super Admin at the moment, because the device list sproc is tied to a userid, not customerid
    //   this.state = {
    //     isSuperAdmin: false,
    //     customerId: 0,
    //     customerName: "",
    this.state = {
      deviceDropdownOptions: [],
      selectedHardwareId: "",
      customerId: -1,
      deviceCanBeProvisioned: false,
      provisionKey: "",
      boxSerial: "",
      displayReprovisionResult: false,
      reprovisionResult: "",
      provisionNewDevice: false,
      parameterTemplateId: 2,
      provisionResponse: "",
      deviceProvisioningStatus: "",
      dbCreated: "",
      fisp_device_reprovision_check_response: "",
      fisp_device_provision_new_ui_response: "",
      fisp_device_provision_next_id_response: "",
      fisp_device_provisioned_check: "",
    };

    this.renderValue = this.renderValue.bind(this);
    this.renderDropdownOption = this.renderDropdownOption.bind(this);
    this.handleDeviceChange = this.handleDeviceChange.bind(this);
    this.checkDeviceCanBeReprovisioned =
      this.checkDeviceCanBeReprovisioned.bind(this);
    this.setParameterTemplateId = this.setParameterTemplateId.bind(this);
    this.checkDeviceProvisioningStatus =
      this.checkDeviceProvisioningStatus.bind(this);
  }

  //   this.setSelectedCustomer = this.setSelectedCustomer.bind(this);
  // }

  componentDidMount() {
    // Super Admin stuff
    // if (this.props.currentUser?.role) {
    //   if (this.props.currentUser.role === UserRoles.SuperAdmin) {
    //     this.props.getAllCustomers();
    //     this.setState({ isSuperAdmin: true });
    //   } else {
    //     this.setState({ customerId: this.props.currentUser.customerId });
    //   }
    // }

    this.setState({ customerId: this.props.currentUser.customerId });
    this.props.loadDeviceProvisioningTemplates(
      this.props.currentUser.customerId
    );
    this.setupDeviceDropdownOptions();
  }

  // Super Admin
  // setSelectedCustomer(e) {
  //   const customerId = parseInt(e.target.value);
  //   let customer = this.props.customers.find((c) => c.id === customerId);

  //   const storeCustomer = {
  //     id: customer.id,
  //     name: customer.name,
  //   };
  //   this.props.setCustomerDetails(storeCustomer);
  //   // TODO: needs to be customer specific
  //   const deviceGroupId = 1;
  //   this.props.loadDeviceList(deviceGroupId);
  //   this.props.loadDeviceProvisioningTemplates(customer.id);

  //   this.setState({ customerId: e.target.value, customerName: customer.name });
  // }

  updateProvisionKey(e) {
    this.setState({ provisionKey: e.target.value });
  }

  updateBoxSerial(e) {
    this.setState({ boxSerial: e.target.value });
  }

  setupDeviceDropdownOptions() {
    const options = this.props.devices.map((d) => {
      let name = "NAME NOT SET";
      if (!isEmpty(d.name)) {
        name = d.name;
      }

      let customerCode = "CUSTOMER CODE NOT SET";
      if (!isEmpty(d.customerCode)) {
        customerCode = d.customerCode;
      }

      let location = "LOCATION NOT SET";
      if (!isEmpty(d.location)) {
        location = d.location;
      }

      return {
        name: name,
        value: d.hardwareId,
        hardwareId: d.hardwareId,
        customerCode: customerCode,
        location: location,
      };
    });

    const provisionNew = {
      name: "Provision New Device",
      value: DeviceIdNotSet,
      hardwareid: DeviceIdNotSet,
      customerCode: "NEW",
      locaction: "NEW",
    };
    options.unshift(provisionNew);

    this.setState({
      deviceDropdownOptions: options,
    });
  }
  renderValue(valueProps, snapshot) {
    //const inputValue = this.state.selectedLocationInput;

    const inputValue = snapshot.focus ? snapshot.search : snapshot.displayValue;

    return (
      <input
        {...valueProps}
        value={inputValue}
        className="select-search__input"
      />
    );
  }

  renderDropdownOption(props, option, snapshot, className) {
    return (
      <button {...props} className={className} type="button">
        <div>
          <div className="device-option-name">{option.name}</div>
          <div className="device-option-hardwareid">{option.hardwareId}</div>
          <div className="device-option-customer-code">
            {option.customerCode}
          </div>
          <div className="device-option-customer-location">
            {option.location}
          </div>
        </div>
      </button>
    );
  }

  handleDeviceChange = (hardwareId) => {
    let provisionNew = false;
    let deviceCanBeProvisioned = false;

    if (hardwareId === DeviceIdNotSet) {
      provisionNew = true;
      deviceCanBeProvisioned = true;
    }

    this.setState({
      selectedHardwareId: hardwareId,
      provisionNewDevice: provisionNew,
      deviceCanBeProvisioned: deviceCanBeProvisioned,
      provisionKey: "",
      provisionResponse: "",
      boxSerial: "",
      deviceProvisioningStatus: "",
      dbCreated: "",

      fisp_device_reprovision_check_response: "",
      fisp_device_provision_new_ui_response: "",
      fisp_device_provision_next_id_response: "",
      fisp_device_provisioned_check: "",
    });
  };

  checkDeviceCanBeReprovisioned() {
    this.props
      .reprovisionDeviceCheck(
        this.state.customerId,
        this.state.selectedHardwareId
      )
      .then((result) => {
        let reprovsionResult =
          "Device is not ready to be reprovisioned. Make sure the device is switched off and check again after 5 minutes";
        if (result === 0) {
          reprovsionResult = "Device is ready to be reprovisioned";
        }
        this.setState({
          fisp_device_reprovision_check_response: result,
          deviceCanBeProvisioned: result === 0,
          reprovisionResult: reprovsionResult,
          displayReprovisionResult: true,
        });
      });
  }

  provisionDevice() {
    const deviceProvisionSettings = {
      customerId: this.state.customerId,
      hardwareId: this.state.selectedHardwareId,
      boxSerial: this.state.boxSerial,
      provisionKey: this.state.provisionKey,
      userId: this.props.currentUser.userId,
      dbCreated: this.state.dbCreated,
    };

    this.props.provisionDevice(deviceProvisionSettings).then((result) => {
      this.setState({
        fisp_device_provision_new_ui_response: result,
        provisionResponse: result,
      });
    });
  }

  getNextDeviceId() {
    this.props.getNextDeviceId(this.state.customerId).then((result) => {
      this.setState({
        selectedHardwareId: result.newId,
        dbCreated: result.dbCreated,
        fisp_device_provision_next_id_response: JSON.stringify(result),
      });
    });
  }

  setParameterTemplateId(e) {
    const parameterTemplateId = e.target.value;

    this.setState({ parameterTemplateId: parameterTemplateId });
  }

  commissionDevice() {
    const deviceCommissionNewSettings = {
      customerId: this.state.customerId,
      hardwareId: this.state.selectedHardwareId,
      deviceId: 0,
      parameterTemplateId: this.state.parameterTemplateId,
    };

    this.props.commissionNewDevice(deviceCommissionNewSettings);
  }

  checkDeviceProvisioningStatus() {
    this.props
      .checkDeviceProvisioningStatus(
        this.state.customerId,
        this.state.selectedHardwareId
      )
      .then((result) => {
        let deviceProvisioningStatus = "Device is not provisioned";
        if (result === 0) {
          deviceProvisioningStatus = "Device is provisioned and running";
        }
        this.setState({
          deviceProvisioningStatus: deviceProvisioningStatus,
          fisp_device_provisioned_check: result,
        });
      });
  }

  render() {
    return (
      <div className="content-container">
        <div className="container">
          <h2>Provisioning</h2>
          <div className="row">
            <div className="col">
              {/* {this.state.isSuperAdmin && (
                <>
                  <h4>Selected Customer: {this.state.customerName}</h4>
                  <select
                    className="form-control form-control-sm"
                    onChange={this.setSelectedCustomer}
                    value={this.state.customerId}
                  >
                    {this.props.customers.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                  <br />
                  <br />
                </>
              )} */}

              <div>
                {/* <JSONPretty data={this.props.currentUser} />
                <JSONPretty data={this.props.provisioningTemplates} />
                <JSONPretty data={this.props.devices} /> */}
                {/* <JSONPretty data={this.state} /> */}
                <SelectSearch
                  options={this.state.deviceDropdownOptions}
                  value={this.state.selectedHardwareId}
                  renderValue={this.renderValue}
                  renderOption={this.renderDropdownOption}
                  search
                  filterOptions={fuzzySearch}
                  onChange={this.handleDeviceChange}
                  placeholder="Select device"
                />
                <br />
                {!this.state.provisionNewDevice && (
                  <div>
                    <Button
                      className="display-reprovision-result-button"
                      onClick={this.checkDeviceCanBeReprovisioned}
                      disabled={
                        this.state.selectedHardwareId === DeviceIdNotSet ||
                        this.state.selectedHardwareId.length === 0
                      }
                    >
                      Check Device Can Be Reprovisioned
                    </Button>
                    {this.state.displayReprovisionResult && (
                      <div className="display-reprovision-result">
                        {this.state.reprovisionResult}{" "}
                      </div>
                    )}
                    <br />
                    <br />
                  </div>
                )}
                {this.state.provisionNewDevice && (
                  <div>
                    <Button onClick={() => this.getNextDeviceId()}>
                      Get Device Id
                    </Button>
                    <br />
                    <br />
                  </div>
                )}
                {this.state.deviceCanBeProvisioned && (
                  <div>
                    <h4>Provision Device: {this.state.selectedHardwareId}</h4>
                    <Form className="form-group-container">
                      <Form.Group>
                        <Form.Label>Provision Key</Form.Label>
                        <Form.Control
                          size="sm"
                          value={this.state.provisionKey}
                          onChange={(e) => this.updateProvisionKey(e)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Box Serial</Form.Label>
                        <Form.Control
                          size="sm"
                          value={this.state.boxSerial}
                          onChange={(e) => this.updateBoxSerial(e)}
                        />
                      </Form.Group>
                      <Button
                        disabled={
                          this.state.provisionKey.length === 0 ||
                          this.state.boxSerial.length === 0 ||
                          this.state.selectedHardwareId === DeviceIdNotSet
                        }
                        onClick={() => this.provisionDevice()}
                      >
                        Provision Device
                      </Button>
                      <Form.Group>
                        {/* <Form.Label>
                          Provision status: {this.state.provivisionResponse}
                        </Form.Label> */}
                        {this.state.provisionResponse === 0 && (
                          <div>
                            <br />
                            <h6>Provisioning Status:</h6>
                            Provisioning started successfully
                            <br />
                            <br />
                            <Button
                              onClick={this.checkDeviceProvisioningStatus}
                            >
                              Check Provisioning Progress
                            </Button>
                            {this.state.deviceProvisioningStatus.length > 0 && (
                              <div>
                                <br />
                                {this.state.deviceProvisioningStatus}
                              </div>
                            )}
                          </div>
                        )}

                        {this.state.provisionResponse === -1 && (
                          <div>
                            <br />
                            <h6>Provisioning Status:</h6>
                            An error occurred provisioning the device
                          </div>
                        )}
                      </Form.Group>
                    </Form>
                  </div>
                )}

                {this.state.provisionNewDevice && (
                  <div>
                    <Form className="form-group-container">
                      <Form.Group>
                        <Form.Label>Device Templates</Form.Label>
                        <select
                          className="form-control form-control-sm"
                          onChange={this.setParameterTemplateId}
                          value={this.state.parameterTemplateId}
                        >
                          {this.props.provisioningTemplates.map(
                            (item, index) => {
                              return (
                                <option value={item.templateId}>
                                  {item.description}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </Form.Group>
                      <Button onClick={() => this.commissionDevice()}>
                        Commission Device
                      </Button>
                    </Form>
                  </div>
                )}

                {/* <br />
                <div>

                  <h4>Debug Stored Proc Responses</h4>
                  <div>
                    fisp_device_reprovision_check:{" "}
                    {this.state.fisp_device_reprovision_check_response}
                  </div>
                  <div>
                    fisp_device_provision_new_ui:{" "}
                    {this.state.fisp_device_provision_new_ui_response}
                  </div>
                  <div>
                    fisp_device_provision_next_id:{" "}
                    {this.state.fisp_device_provision_next_id_response}
                  </div>
                  <div>
                    fisp_device_provisioned_check:{" "}
                    {this.state.fisp_device_provisioned_check}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProvisioningPage.defaultProps = {
  customers: [],
  provisioningTemplates: [],
  currentUser: {},
  devices: [],
};

function mapStateToProps(state) {
  return {
    customers: getCustomers(state),
    provisioningTemplates: getDeviceProvisioningTemplates(state),
    currentUser: getCurrentUser(state),
    devices: getAllDevices(state),
  };
}

const mapDispatchToProps = {
  loadDeviceProvisioningTemplates,
  getAllCustomers,
  setCustomerDetails,
  loadDeviceList,
  reprovisionDeviceCheck,
  provisionDevice,
  getNextDeviceId,
  commissionNewDevice,
  checkDeviceProvisioningStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvisioningPage);
