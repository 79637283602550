import React, { Component } from "react";
import usersService from "./UsersService";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Button } from "reactstrap";
import { connect } from "react-redux";
import { getCustomer } from "../customer/selectors/customerSelector";
class UsersAdd extends Component {
  componentDidMount() {}

  handleClickCancel = () => {
    const { history } = this.props;

    history.push("/users");
  };

  handleValidSubmit = (event, values) => {
    const { history } = this.props;
    values.customerId = this.props.customer.id;

    (async () => {
      await usersService.addUser(values);
      history.push("/users");
    })();
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <h4 id="tabelLabel">Add User</h4>

            <AvForm onValidSubmit={this.handleValidSubmit}>
              <AvField
                name="firstName"
                label="FirstName"
                required
                errorMessage="FieldInvalid"
                validate={{
                  required: { value: true, errorMessage: "FieldRequired" },
                  minLength: { value: 2 },
                }}
              />
              <AvField name="lastName" label="Last name" required />
              <AvField name="email" type="email" label="Email" required />
              <AvField name="role" type="select" label="Role" required>
                <option value="">---Select Value---</option>
                <option value="administrator">Administrator</option>
                <option value="user">User</option>
              </AvField>
              {/* <AvField
                name="password"
                type="password"
                label="Password"
                required
              />
              <AvField
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                required
                validate={{ match: { value: "password" } }}
              /> */}
              <FormGroup>
                <Button>Save</Button>&nbsp;
                <Button onClick={this.handleClickCancel}>Cancel</Button>
              </FormGroup>
            </AvForm>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customer: getCustomer(state),
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UsersAdd);
