import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function applicationReducer(
  state = initialState.applicationState,
  action
) {
  switch (action.type) {
    case types.LOAD_DEVICE_LIST_SUCCESS: {
      let initialDeviceId = state.initialDeviceId;
      if (
        state.initialDeviceListLoaded === false &&
        initialDeviceId === null &&
        action.devices.length > 0
      ) {
        initialDeviceId = action.devices[0].id;
      }

      return {
        ...state,
        initialDeviceListLoaded: true,
        initialDeviceId: initialDeviceId,
      };
    }
    case types.SET_IS_CONNECTED: {
        let newState = Object.assign({}, state);
        newState.isConnected = action.isConnected;
        return newState;
    }
    default:
      return state;
  }
}
