import React, { Component } from "react";
import { connect } from "react-redux";
import { getPortalStatus } from "./actions/statusActions";

class StatusPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      status: "",
    };
  }

  componentDidMount() {
    this.props.getPortalStatus().then((result) => {
      this.setState({
        isLoaded: true,
        status: result.status, // TODO: modify this if the object shape from the server is different
      });
    });
  }

  render() {
    const { error, isLoaded, status } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return <div>{status}</div>;
    }
  }
}
const mapDispatchToProps = {
  getPortalStatus,
};

export default connect(null, mapDispatchToProps)(StatusPage);
