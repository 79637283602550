import * as types from "../../common/state/actionTypes";
import * as customerContactApi from "../api/customerContactApi";
import { logError } from "../../errors/actions/errorActions";

export const deviceCustomerContactLoadedSuccess = (deviceId, contactInfo) => ({
  type: types.LOAD_DEVICE_CUSTOMER_CONTACT_INFO_SUCCESS,
  deviceId: deviceId,
  contactInfo: contactInfo,
});

export function loadDeviceCustomerContactInfo(deviceId) {
  return function (dispatch) {
    return customerContactApi
      .getDeviceCustomerContactInformation(deviceId)
      .then((contactInfo) => {
        dispatch(deviceCustomerContactLoadedSuccess(deviceId, contactInfo));
      })
      .catch((error) => {
        const err = new Error(
          "customerContactActions.js, getDeviceCustomerContactInfo()",
          error.toString()
        );
        dispatch(logError(err));
      });
  };
}
