import { callAdminApiGet } from "../../api/apiUtils";
import { callAdminApiPost } from "../../api/apiUtils";


export async function getAnalysisTelemetryData(deviceId, startTime, endTime) {
  // todo: temp
  //   deviceId = 452;
  //   startTime = "2021-02-19T16:00:00.000Z";
  //   endTime = "2021-02-19T18:00:00.000Z";
  const url = `api/devicetelemetry/${deviceId}/${startTime}/${endTime}`;

  return callAdminApiGet(url);
}
// https://localhost:44396/api/devicetelemetry/452/2021-02-19T16:00:00.000Z/2021-02-19T18:00:00.000Z


export async function submitDeviceAnalysisReportRequest(reportParams) {
    const url = "api/devicetelemetry/analysisrequest";

    return callAdminApiPost(url, reportParams);
}