import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  saveUserFavourite,
  deleteUserFavourite,
} from "../users/favourite/actions/userFavouritesActions";
import "./device.css";

class DeviceFavourite extends Component {
  favouriteClicked() {
    if (this.props.favourite) {
      this.props.deleteUserFavourite(this.props.deviceId);
    } else {
      this.props.saveUserFavourite(this.props.deviceId);
    }
  }
  render() {
    let className = "favourite-unselected";
    if (this.props.favourite) className = "favourite-selected";

    return (
      <div onClick={() => this.favouriteClicked()} className={className}>
        <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
      </div>
    );
  }
}

const mapDispatchToProps = {
  saveUserFavourite,
  deleteUserFavourite,
};

export default connect(null, mapDispatchToProps)(DeviceFavourite);
