import React, { Component } from "react";
import {
  loadDeviceIncidentAlarmList,
  acknowledgIncidentAlarm,
} from "./actions/incidentActions";
import { connect } from "react-redux";
import { getDeviceIncidentAlarmList } from "./selectors/deviceIncidentSelector";
import Table from "react-bootstrap/Table";
import { formatDate } from "../utils/dateUtils";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

class DeviceIncidentAlarmList extends Component {
  constructor(props) {
    super(props);

    this.acknowledgeAlarm = this.acknowledgeAlarm.bind(this);
  }
  componentDidMount() {
    this.props.loadDeviceIncidentAlarmList(
      this.props.deviceId,
      this.props.incident
    );
  }

  acknowledgeAlarm(alarmId) {
    this.props
      .acknowledgIncidentAlarm(
        this.props.incident,
        alarmId,
        this.props.deviceId
      )
      .then(() => {
        this.props.loadDeviceIncidentAlarmList(
          this.props.deviceId,
          this.props.incident
        );
      });
  }

  render() {
    return (
      <div>
        {/* <h1>DeviceIncidentAlarmList</h1>
        {JSON.stringify(this.props.incidentAlarmList)} */}
        <Table responsive size="sm" className="table table-hover">
          <thead>
            <tr>
              <th>Alarm Status</th>
              <th>AlarmId</th>
              <th>Alarm Name</th>
              <th>Activated Date</th>
              <th>Resolved Date</th>
              <th>Acknowledged By</th>
              <th>Acknowledged Date</th>
              <th className="acknowledge-alarm-th"></th>
            </tr>
          </thead>
          <tbody>
            {this.props.incidentAlarmList.map((alarm, key) => {
              return (
                <tr key={key}>
                  <td>{alarm.alarmStatus}</td>
                  <td>{alarm.alarmId}</td>
                  <td>{alarm.alarmName}</td>
                  <td>{formatDate(alarm.activatedDate)}</td>
                  <td>{formatDate(alarm.resolvedDate)}</td>
                  <td>{alarm.acknowledgedBy}</td>
                  <td>{formatDate(alarm.acknowledgedTime)}</td>
                  <td>
                    {alarm.alarmStatus === "Active" &&
                      alarm.acknowledgedBy === null && (
                        <Button
                          variant="success"
                          onClick={() => this.acknowledgeAlarm(alarm.alarmId)}
                        >
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                          ></FontAwesomeIcon>
                        </Button>
                      )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

DeviceIncidentAlarmList.defaultProps = {
  incidentAlarmList: [],
  incident: "",
};

function mapStateToProps(state, ownProps) {
  const incident = ownProps.incident;
  return {
    incidentAlarmList: getDeviceIncidentAlarmList(state, incident),
  };
}

const mapDispatchToProps = {
  loadDeviceIncidentAlarmList,
  acknowledgIncidentAlarm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceIncidentAlarmList);
