import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function alarmContactsReducer(
  state = initialState.availableAlarmContacts,
  action
) {
  switch (action.type) {
    case types.LOAD_ALARM_AVIALABLE_CONTACTS_SUCCESS: {
      const alarmContacts = {
        alarmId: action.alarmId,
        contacts: action.alarmContacts,
      };

      if (state.length === 0) {
        let firstState = [];
        firstState.push(alarmContacts);
        return firstState;
      }

      const alarmIndex = state.findIndex(
        (alarm) => alarm.alarmId === action.alarmId
      );

      let newState = JSON.parse(JSON.stringify(state));

      if (alarmIndex > -1) {
        newState[alarmIndex].contacts = action.alarmContacts;
      } else {
        newState.push(alarmContacts);
      }

      return newState;
    }
    default:
      return state;
  }
}
