import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function deviceContactsReducer(
  state = initialState.deviceContacts,
  action
) {
  switch (action.type) {
    case types.LOAD_DEVICE_CUSTOMER_CONTACT_INFO_SUCCESS: {
      if (state.length === 1 && state[0].contactInfo.length === 0) {
        const deviceContactData = [];
        const newContactData = {
          deviceId: action.deviceId,
          contactInfo: action.contactInfo,
        };
        deviceContactData.push(newContactData);
        return deviceContactData;
      }

      const updatedContactInfoData = [];
      const filtered = state.filter((e) => e.deviceId !== action.deviceId);
      const contactData = {
        deviceId: action.deviceId,
        contactInfo: action.contactInfo,
      };
      updatedContactInfoData.push(contactData);

      return [...filtered, ...updatedContactInfoData];
    }
    default:
      return state;
  }
}
