import { callAdminApiGet, callAdminApiPost } from "../../api/apiUtils";
import authService from "../../api-authorization/AuthorizeService";

export async function getCustomerLocations() {
  const user = await authService.getUser();
  //  console.log(`userId:${user.userId}, customerId:${user.customerId}`);
  const url = `api/customer/locations/${user.customerId}`;

  return callAdminApiGet(url);
}

export async function upsertLocation(customerLocation) {
  const user = await authService.getUser();
  const url = `api/customer/upsertlocation/${user.customerId}`;

  return callAdminApiPost(url, customerLocation);
}

export async function deleteLocation(customerLocationId) {
  const url = `api/customer/deletelocation/${customerLocationId}`;

  return callAdminApiPost(url, {});
}

export async function getAllCustomers() {
  const url = `api/customer/getallcustomers`;

  return callAdminApiGet(url);
}
// export async function getSubCustomerFilter(customerId) {
//   const url = `api/customer/subcustomerfilter/${customerId}`;

//   return callAdminApiGet(url);
// }
