export const addInputDataToGraphSeries = (
  state,
  deviceData,
  dataInputProperty,
  inputDisplayName,
  time
) => {
  if (deviceData.hasOwnProperty(dataInputProperty)) {
    let series = state.series.filter((s) => {
      return s.name === inputDisplayName;
    });

    if (series.length === 0) {
      series.push({
        name: inputDisplayName,
        data: [],
      });
    }

    const dataValue = deviceData[dataInputProperty];

    const newData = {
      x: time,
      y: dataValue,
    };

    let combinedSeriesData = [];

    if (state.dataPointCount >= state.maxDataPointCount) {
      // remove first data point and tag new on to the end
      combinedSeriesData = [
        ...series[0].data.slice(1, state.maxDataPointCount),
        newData,
      ];
    } else {
      combinedSeriesData = [...series[0].data, newData];
    }

    const updatedSeries = {
      name: inputDisplayName,
      data: combinedSeriesData,
    };

    return updatedSeries;
  }
  return;
};

export const getGraphSeries = (state, deviceData, time) => {
  let graphSeries = [];

  const doorSeries = addInputDataToGraphSeries(
    state,
    deviceData,
    "doorFridge",
    "Door - Fridge",
    time
  );
  if (doorSeries) {
    graphSeries.push(doorSeries);
  }

  const tempFridgeSeries = addInputDataToGraphSeries(
    state,
    deviceData,
    "temperatureFridge",
    "Temperature - Fridge",
    time
  );
  if (tempFridgeSeries) graphSeries.push(tempFridgeSeries);

  const compressorSeries = addInputDataToGraphSeries(
    state,
    deviceData,
    "compressorFridge",
    "Compressor",
    time
  );
  if (compressorSeries) {
    graphSeries.push(compressorSeries);
  }

  const voltageMainSeries = addInputDataToGraphSeries(
    state,
    deviceData,
    "voltageMain",
    "Voltage - Main",
    time
  );
  if (voltageMainSeries) {
    graphSeries.push(voltageMainSeries);
  }

  // TODO: Temporarily remove from chart
  // const voltageMainOutSeries = addInputDataToGraphSeries(
  //   state,
  //   deviceData,
  //   "voltageMainOut",
  //   "Voltage - Main(Out)",
  //   time
  // );

  // if (voltageMainOutSeries) {
  //   graphSeries.push(voltageMainOutSeries);
  // }

  const voltageBatterySeries = addInputDataToGraphSeries(
    state,
    deviceData,
    "voltageBattery",
    "Voltage - Battery",
    time
  );
  if (voltageBatterySeries) {
    graphSeries.push(voltageBatterySeries);
  }

  // TODO: Temporarily remove from chart
  // const tempDeviceChipSeries = addInputDataToGraphSeries(
  //   state,
  //   deviceData,
  //   "temperatureDeviceChip",
  //   "Temperature - Device Chip",
  //   time
  // );
  // if (tempDeviceChipSeries) {
  //   graphSeries.push(tempDeviceChipSeries);
  // }

  const tempProductSeries = addInputDataToGraphSeries(
    state,
    deviceData,
    "temperatureProduct",
    "Temperature - Product",
    time
  );
  if (tempProductSeries) {
    graphSeries.push(tempProductSeries);
  }

  const doorMagnetSeries = addInputDataToGraphSeries(
    state,
    deviceData,
    "doorMagnet",
    "Door - Magnet",
    time
  );
  if (doorMagnetSeries) {
    graphSeries.push(doorMagnetSeries);
  }

  const tempAirSeries = addInputDataToGraphSeries(
    state,
    deviceData,
    "temperatureAir",
    "Temperature - Air",
    time
  );
  if (tempAirSeries) {
    graphSeries.push(tempAirSeries);
  }

  return graphSeries;
};
