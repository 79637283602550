import { isEmpty } from "../../utils/generalUtils";

export const getStartDate = (state) => state.dataAnalysisParameters.startDate;

export const getEndDate = (state) => state.dataAnalysisParameters.endDate;

export const getDates = (state) => {
  let startDate = "Start Date";

  let endDate = "End Date";

  if (!isEmpty(state.dataAnalysisParameters.startDate))
    startDate = state.dataAnalysisParameters.startDate;

  if (!isEmpty(state.dataAnalysisParameters.endDate)) {
    endDate = state.dataAnalysisParameters.endDate;
  }

  return { startDate: startDate, endDate: endDate };
};
