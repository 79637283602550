import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function deviceInventorySummaryReducer(
    state = initialState.deviceInventorySummary,
    action
) {
    switch (action.type) {
        case types.LOAD_DEVICE_INVENTORY_SUMMARY_SUCCESS: {
            const deviceInventorySummary = {
                deviceId: action.deviceId,
                inventorySummary: action.deviceInventorySummary.inventorySummaryList
            };

            if (state.length === 0) {
                let firstState = [];
                firstState.push(deviceInventorySummary);
                return firstState;
            }

            const deviceIndex = state.findIndex(
                (inv) => inv.deviceId === action.deviceId
            );
            if (deviceIndex > -1) {
                let newState = JSON.parse(JSON.stringify(state));
                newState[deviceIndex].inventorySummary =
                    action.deviceInventorySummary.inventorySummaryList;

                return newState;
            } else {
                return [...state, deviceInventorySummary];
            }
        }
        case types.REFRESH_DEVICE_INVENTORY_SUMMARY_SUCCESS: {
            const deviceInventorySummary = {
                deviceId: action.deviceId,
                inventorySummary: action.deviceInventorySummary.inventorySummaryList,
            };

            if (state.length === 0) {
                let firstState = [];
                firstState.push(deviceInventorySummary);
                return firstState;
            }

            const deviceIndex = state.findIndex(
                (inv) => inv.deviceId === action.deviceId
            );
            if (deviceIndex > -1) {
                let newState = JSON.parse(JSON.stringify(state));
                newState[deviceIndex].inventorySummary =
                    action.deviceInventorySummary.inventorySummaryList;

                return newState;
            }
            return state;
        }
        default:
            return state;
    }
}
