import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function newAlarmConfigDialogReducer(
  state = initialState.newAlarmConfigDialog,
  action
) {
  switch (action.type) {
    case types.TOGGLE_NEW_ALARM_CONFIG_DIALOG: {
      return {
        ...state,
        open: action.open,
      };
    }
    default:
      return state;
  }
}
