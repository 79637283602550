import * as generalSettingsApi from "../api/generalSettingsApi";
import * as types from "../../common/state/actionTypes";
import { Error } from "../../errors/errorType";
import { logError } from "../../errors/actions/errorActions";

export function loadGeneralDeviceSettingsSuccess(settings) {
  return { type: types.LOAD_GENERAL_DEVICE_SETTINGS_SUCCESS, settings };
}

export function updateGeneralSettingLocation(customerLocationId) {
  return {
    type: types.UPDATE_GENERAL_DEVICE_SETTINGS_LOCATION,
    customerLocationId: customerLocationId,
  };
}

export function loadGeneralDeviceSettings(deviceId) {
  return function (dispatch) {
    return generalSettingsApi
      .getGeneralDevicesettings(deviceId)
      .then((settings) => {
        dispatch(loadGeneralDeviceSettingsSuccess(settings));
      })
      .catch((error) => {
        const params = [
          {
            deviceId: deviceId,
          },
        ];
        const err = new Error(
          "generalSettingsActions.js, loadGeneralDeviceSettings()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function updateGeneralDeviceSettings(deviceId, settings) {
  return function (dispatch) {
    return generalSettingsApi
      .updateGeneralDeviceSettings(deviceId, settings)
      .catch((error) => {
        const params = [
          {
            deviceId: deviceId,
            generalSettings: settings,
          },
        ];
        const err = new Error(
          "generalSettingsActions.js, updateGeneralDeviceSettings()",
          error.toString(),
          params
        );

        dispatch(logError(err));
      });
  };
}

export function pushConfigUi(deviceId) {
  return function (dispatch) {
    return generalSettingsApi.pushConfigUi(deviceId).catch((error) => {
      const params = [
        {
          deviceId: deviceId,
        },
      ];
      const err = new Error(
        "generalSettingsActions.js, pushConfigUi()",
        error.toString(),
        params
      );

      dispatch(logError(err));
    });
  };
}
