import React, { Component } from "react";
import { connect } from "react-redux";
import { getSelectedDevice } from "../deviceList/selectors/deviceListSelector";
//import { getProductTotals } from "./selectors/deviceInventorySelector";
import { isEmpty } from "../utils/generalUtils";
import Table from "react-bootstrap/Table";
import Check from "react-bootstrap/FormCheck";
import "./Inventory.css";
import {
  inventoryReviseExpiry,
    refreshDeviceInventory,
    refreshDeviceInventorySummary,
    refreshDeviceInventoryHistory,
    loadDeviceInventory,
    loadDeviceInventoryProductCounts,
    loadDeviceInventoryHistory,
    loadDeviceInventorySummary,
    scanInventorySp,
    checkScan2,
} from "./actions/deviceInventoryActions";
import {
  getDeviceInventory,
  getUnidentifiedTags,
    getLastScanTime,
    getProductCounts,
} from "./selectors/deviceInventorySelector";
import { getDeviceInventoryHistoryToken } from "./selectors/deviceInventoryHistorySelector";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import * as inventoryApi from "./api/deviceInventoryApi";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { ModalBody } from "react-bootstrap";
import Datetime from "react-datetime";
import moment from "moment";
import InventoryHistory from "./InventoryHistory";
import InventorySummary from "./InventorySummary";
import { formatDate } from "../utils/dateUtils";
import InventoryHeading from "./InventoryHeading";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "bootstrap/js/src/collapse.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
class Inventory extends Component {
  constructor(props) {
      super(props);

      this.Ref = React.createRef();

    this.state = {
      reviseExpiryTags: [],
      showDialog: false,
      revisedReason: "",
        revisedDate: "",
        timer: "00:00:00",
        scanningState: "idle",
scanCheckResult: "idle",
    };

    this.refreshInventory = this.refreshInventory.bind(this);
    this.scanInventorySp = this.scanInventorySp.bind(this);
    this.reviseExpiryCheckboxChanged =
      this.reviseExpiryCheckboxChanged.bind(this);
    this.reviseExpiryPopup = this.reviseExpiryPopup.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.setRevisedReason = this.setRevisedReason.bind(this);
    this.setRevisedDate = this.setRevisedDate.bind(this);
  }

    scanInventorySp() {
        inventoryApi.scanInventorySp(this.props.device.id);
        this.setState({ scanningState: "scanning" });
        this.beginTimer(this.getDeadTime());
    }

    checkScan() {
        //inventoryApi.checkScan(this.props.device.id);
        //this.setState({ scanCheckResult: res });
//        inventoryApi.checkScan2(this.props.device.id).then(result => this.state.scanCheckResult = result);
        inventoryApi.checkScan2(this.props.device.id).then((result) => this.newresult({ result }));
    }

    newresult(res) {
        if (res.result.deviceInventoryState === "REQUESTED") {
            this.setState({ scanningState: "scanning" });
        } else {
            //change button display
            this.setState({ scanningState: "idle" });
            //stop the timer
            if (this.Ref.current) clearInterval(this.Ref.current);
            //refresh results
            this.refreshInventory();
        }
    }

 // scanInventory() {
 //   inventoryApi.scanInventory(this.props.device.hardwareId);
 // }
 //   scanInventorySp() {
 //       this.props.scanInventorySp(this.props.device.id).then(() => {
 //           toast.success("Scan started.", {
 //               hideProgressBar: true,
 //               autoClose: 10000,
 //           });
 //       });
 //   }

  refreshInventory() {
    // TODO: hardcoded records per page
      this.props.refreshDeviceInventory(this.props.device.id, 10, "token");
      this.props.refreshDeviceInventorySummary(this.props.device.id);
      this.props.refreshDeviceInventoryHistory(this.props.device.id, 10, "token");
  }



  reviseExpiryCheckboxChanged(tagId, e) {
    let tags = this.state.reviseExpiryTags;
    if (e.target.checked) {
      tags.push(tagId);
      this.setState({ reviseExpiryTags: tags });
    } else {
      const index = this.state.reviseExpiryTags.indexOf(tagId);
      if (index > -1) {
        tags.splice(index, 1);
        this.setState({ reviseExpiryTags: tags });
      }
    }
  }

  isValidDate(date) {
    return moment.isDate(date) || moment.isMoment(date);
  }

  setRevisedDate(revisedDate) {
    if (this.isValidDate(revisedDate))
      this.setState({ revisedDate: revisedDate });
  }

  setRevisedReason(e) {
    this.setState({ revisedReason: e.target.value });
  }

  toggleModal() {
    this.setState({ showDialog: !this.state.showDialog });
  }

  reviseExpiryPopup() {
    this.toggleModal();
    this.state.reviseExpiryTags.forEach((tag) => {
      const reviseExpiryData = {
        tagId: tag,
        revisedExpiryDate: formatDate(this.state.revisedDate),
        reason: this.state.revisedReason,
      };
      this.props
        .inventoryReviseExpiry(reviseExpiryData)
        .then(() => this.props.loadDeviceInventory(this.props.device.id));
    });
  }


    getTimeRemaining = (e) => {
        //This to check if we are up to maximum waiting time

        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total,
            hours,
            minutes,
            seconds,
        };
    };

    timerEvent = (e) => {
        let { total, hours, minutes, seconds } = this.getTimeRemaining(e);
        if (total >= 0) {
            //here we check status

            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable

            this.checkScan();

        }
    };

    beginTimer = (e) => {
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next
        this.setState({ timer: "00:10:00" });

        // Sets the 5 second interval
        if (this.Ref.current) clearInterval(this.Ref.current);
        const id = setInterval(() => {
            this.timerEvent(e);
        }, 5000);
        this.Ref.current = id;
    };

    getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if
        // you extend to add more time
        // 600 seconds = 10 minutes
        deadline.setSeconds(deadline.getSeconds() + 600);
        return deadline;
    };




    renderScanButtons() {
        if (this.state.scanningState === "idle") {
            return (<div><Button onClick={this.scanInventorySp}>Scan</Button><Button className="button-spacer" onClick={this.refreshInventory}>Refresh</Button></div>);
        }
        else {
            return (<div>                    <Button disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                &nbsp;Scanning...
            </Button>
                <Button className="button-spacer" onClick={this.refreshInventory} disabled>
                    Refresh
                </Button>
            </div>)
        }
    }



  render() {
    let device = "No Device Selected";
      let lastCheck = "Last check: ";
    if (!isEmpty(this.props.device)) {
      device = this.props.device.name;
        lastCheck += this.props.lastScanTime;
    }
    return (
      <div className="content-container">
        <Row>
          <Col>
            <h4>{device}</h4>
          </Col>
          <Col xs={5}>
                    <h4>{lastCheck}</h4>
          </Col>
                <Col>
                    {this.renderScanButtons()}
          </Col>
        </Row>

            <Tabs defaultActiveKey="inventorySummary">
                <Tab eventKey="inventorySummary" title="Summary">
                    <br />
                    {/* Adding a key on here to force a complete re-render of this component when the device changes
        This isn't ideal but the table component does not seem to update when the underlying stock data changes,
        hence forcing an update of this component */}
                    <InventorySummary key={this.props.device.id > -1 ? `inventorySummary-${this.props.device.id}` : ''}></InventorySummary>
                </Tab>

          <Tab eventKey="latestInventory" title="Latest Inventory">
            <br />
                    <div className="scroll-table-container-large">
                    <>
                            <h5>
                                Inventory Items: <b>{this.props.totals.totalItems}</b> &nbsp;&nbsp;&nbsp;
                                {this.props.totals.productCounts.map((item, key) => {
                                return (
                                    <span key={key}>
                                        {item.productName}: {item.totalItems} &nbsp;&nbsp;&nbsp;
                                    </span>
                                );
                            })}
                        </h5>
                    </>
              <Table responsive borderless size="sm">
                <thead>
                  <tr>
                    <th></th>
                    <th>Product</th>
                    <th></th>
                    <th>Serial Number</th>
                    <th>Batch</th>
                    <th>Expiry Date</th>
                    <th>Revised Reason</th>
                    <th>First Seen Here</th>
                    <th>CustRef1</th>
                    <th>CustRef2</th>
                    <th>CustRef3</th>
                    <th>CustRef4</th>
                    <th>CustRef5</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.inventory.map((item, key) => {
                    let dataTarget = ".multi-collapse" + key;
                    let dataTargetClass = "collapse multi-collapse" + key;
                    let ariaControls = "multiCollapse" + key;
                    // console.log(item);
                    return (
                      <>
                        <tr
                          key={`latestInventory-${key}`}
                          data-toggle="collapse"
                          data-target={dataTarget}
                          aria-controls={ariaControls}
                        >
                          <td>
                            {item.showRevisedExpiryCheckbox && (
                              <FontAwesomeIcon
                                className="expander"
                                icon={faPlus}
                              />
                            )}
                          </td>
                          <td className={item.productCssClassName}>
                            {item.productName}
                          </td>
                          <td>
                            {item.showRevisedExpiryCheckbox && (
                              <Check
                                on="true"
                                size="sm"
                                type="checkbox"
                                onChange={(e) =>
                                  this.reviseExpiryCheckboxChanged(
                                    item.tagId,
                                    e
                                  )
                                }
                                key={`check-${item.tagId}`}
                              />
                            )}
                          </td>
                          <td>{item.serialNumber}</td>
                          <td>{item.batch}</td>
                          <td>{item.expiryDate}</td>
                          <td>{item.revisedReason}</td>
                          <td>{item.firstSeenHere}</td>
                          <td>{item.customerRef1}</td>
                          <td>{item.customerRef2}</td>
                          <td>{item.customerRef3}</td>
                          <td>{item.customerRef4}</td>
                          <td>{item.customerRef5}</td>
                        </tr>
                        <tr className={dataTargetClass} id={ariaControls}>
                          <td></td>
                          <td colSpan={3}>
                            <div className="inventory-child-row-header">
                              EPC
                            </div>
                            <div>{item.epcId}</div>
                            <br />
                          </td>
                          <td colSpan={3}>
                            <div className="inventory-child-row-header">
                              Tag ID
                            </div>
                            <div>{item.tagId}</div>
                          </td>
                          <td></td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <Button className="button-bottom-spacer" onClick={this.toggleModal}>
              Revise Expiry
            </Button>
            <Modal show={this.state.showDialog} toggle={this.toggleModal}>
              <ModalBody>
                <Form className="form-group-container">
                  <Row>
                    <Col>Revised Expiry Date:</Col>
                    <Col>
                      <Datetime
                        size="sm"
                        dateFormat="yyyy-MM-DD"
                        timeFormat="HH:mm"
                        value={this.state.revisedDate}
                        onChange={this.setRevisedDate}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>Revised Reason:</Col>
                    <Col>
                      <Form.Control
                        value={this.state.revisedReason}
                        onChange={(e) => this.setRevisedReason(e)}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button color="primary" onClick={this.reviseExpiryPopup}>
                        Save
                      </Button>{" "}
                      <Button color="secondary" onClick={this.toggleModal}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
            <h5>Unidentified Tags</h5>
            <div className="scroll-table-container-small">
              <Table responsive borderless size="sm">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>EPC</th>
                    <th>Tag ID</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.unidentifiedTags.map((item, key) => {
                    return (
                      <tr key={`unidentified-${key}`}>
                        <td className={item.productCssClassName}>
                          {item.productName}
                        </td>
                        <td>{item.epcId}</td>
                        <td>{item.tagId}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Tab>
          <Tab eventKey="stockChangeEvents" title="Stock Change Events">
            <br />
            {/* Adding a key on here to force a complete re-render of this component when the device changes
        This isn't ideal but the table component does not seem to update when the underlying stock data changes,
        hence forcing an update of this component */}
            <InventoryHistory key={this.props.device.id > -1 ? `inventoryHistory-${this.props.device.id}` : ''}></InventoryHistory>
          </Tab>
        </Tabs>

        {/* <JSONPretty data={this.props.inventory}></JSONPretty> */}
      </div>
    );
  }
}

Inventory.defaultProps = {
  device: {},
  inventory: [],
  unidentifiedTags: [],
  lastScanTime: "",
  token: "token",
  totals: [],
    productCounts: [],
scanCheckResult: "idle",
};


function mapStateToProps(state) {
  const device = getSelectedDevice(state);
  //const totals = getProductTotals(state, device.id);
  return {
    device: device,
    inventory: getDeviceInventory(state, device.id),
    unidentifiedTags: getUnidentifiedTags(state, device.id),
      totals: getProductCounts(state, device.id),
    lastScanTime: getLastScanTime(state, device.id),
      token: getDeviceInventoryHistoryToken(state, device.id),
   };
}

const mapDispatchToProps = {
  inventoryReviseExpiry,
    refreshDeviceInventory,
    refreshDeviceInventorySummary,
    refreshDeviceInventoryHistory,
    loadDeviceInventory,
    loadDeviceInventoryProductCounts,
    loadDeviceInventoryHistory,
    loadDeviceInventorySummary,
    scanInventorySp,
    checkScan2,
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
