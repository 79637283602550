import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateDeviceAlarmConfig,
  toggleNewAlarmConfigDialog,
  loadDeviceAlarmConfigList,
  deleteDeviceAlarmConfig,
  setDeviceAlarmMute,
  getAlarmActions,
  putAlarmAction,
} from "./actions/alarmConfigActions";
import { isEmpty } from "../utils/generalUtils";
import { formatDate } from "../utils/dateUtils";
import authService from "../api-authorization/AuthorizeService";
import { UserRoles } from "../api-authorization/ApiAuthorizationConstants";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Datetime from "react-datetime";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import AlarmContactConfiguration from "./AlarmContactConfiguration";
import "./DeviceConfig.css";

// TODO: potentially make an API call, confirm if they are likely to change
const alarmCheckTypeList = [
  {
    checkTypeId: 1,
    checkType: ">",
  },
  {
    checkTypeId: 2,
    checkType: ">=",
  },
  {
    checkTypeId: 3,
    checkType: "<",
  },
  {
    checkTypeId: 4,
    checkType: "<=",
  },
  {
    checkTypeId: 5,
    checkType: "=",
  },
];

class EditAlarmConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readOnly: !this.props.newAlarm,
      alarmSettingDescription: this.props.alarmSettingDescription,
      checkType: this.props.checkType,
      checkTypeId: this.props.checkTypeId,
      threshold: this.props.threshold,
      restoreCheckType: this.props.restoreCheckType,
      restoreCheckTypeId: this.props.restoreCheckTypeId,
      restoreThreshold: this.props.restoreThreshold,
      alarmHoldOff: this.props.alarmHoldOff,
      restoreHoldoff: this.props.restoreHoldoff,
      repeatInterval: this.props.repeatInterval,
      mutedUntil: this.props.mutedUntil,
      mutedFlag: this.props.mutedFlag,
      mutedFlagBoolean: this.props.mutedFlagBoolean,
      muteInputProps: {
        placeholder: "",
        disabled: true,
        className: "form-control form-control-sm",
      },
      errors: {},
      showAlarmContactDialog: false,
      showAlarmActionDialog: false,
      alarmActions: [],
      cloudAlarmFlag: this.props.cloudAlarmFlag,
        hasAdminRights: false,
        isAuthenticated: false,

    };
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.saveAlarmConfig = this.saveAlarmConfig.bind(this);
    this.disableEditMode = this.disableEditMode.bind(this);
    this.deleteAlarmConfig = this.deleteAlarmConfig.bind(this);
    this.setMutedUntil = this.setMutedUntil.bind(this);
    this.handleSnoozeCheckboxChanged =
      this.handleSnoozeCheckboxChanged.bind(this);

    this.handleShowAlarmContactDialog =
      this.handleShowAlarmContactDialog.bind(this);
    this.handleCloseAlarmContactDialog =
      this.handleCloseAlarmContactDialog.bind(this);

    this.handleShowAlarmActionDialog =
      this.handleShowAlarmActionDialog.bind(this);
    this.handleCloseAlarmActionDialog =
      this.handleCloseAlarmActionDialog.bind(this);
    this.saveAlarmActions = this.saveAlarmActions.bind(this);
  }

  componentDidMount() {
    let mutedUntil = "";
    if (!isEmpty(this.props.mutedUntil)) {
      mutedUntil = this.props.mutedUntil;
      this.setState({ mutedUntil: this.props.mutedUntil });

    }

    const muteInputProps = {
      placeholder: mutedUntil,
      disabled: this.state.readOnly,
      className: "form-control form-control-sm",
    };

      this.setState({ muteInputProps: muteInputProps });

      this.populateState();
    }

    async populateState() {
        const user = await authService.getUser();

        let hasAdminRights = false;
        let isAuthenticated = false;

        if (user) {
            isAuthenticated = true;
            if (
                user.role === UserRoles.Administrator ||
                user.role === UserRoles.SuperAdmin
            ) {
                hasAdminRights = true;
            }
//            this.props.setCurrentUser(user);
        }

        this.setState({
            hasAdminRights,
            isAuthenticated,
        });
    }

  toggleEditMode() {
    let inputDisabled = true;
    if (this.state.readOnly && this.state.mutedFlagBoolean) {
      inputDisabled = false;
    }

    const muteInputProps = {
      placeholder: this.props.mutedUntil,
      disabled: inputDisabled,
      className: "form-control form-control-sm",
    };

    this.setState((prevState) => ({
      readOnly: !prevState.readOnly,
      muteInputProps: muteInputProps,
    }));
  }

  disableEditMode() {
    const muteInputProps = {
      placeholder: formatDate(this.props.mutedUntil),
      disabled: true,
      className: "form-control form-control-sm",
    };

    this.setState({ readOnly: true, muteInputProps: muteInputProps });
  }

  handleCloseAlarmContactDialog() {
    this.setState({ showAlarmContactDialog: false });
  }

  handleShowAlarmContactDialog() {
    this.setState({ showAlarmContactDialog: true });
  }

  handleCloseAlarmActionDialog() {
    this.setState({ showAlarmActionDialog: false });
  }

  handleShowAlarmActionDialog() {
    if (this.props.alarmId !== null) {
      this.props.getAlarmActions(this.props.alarmId).then((a) => {
        this.setState({
          alarmActions: a,
          showAlarmActionDialog: true,
        });
      });
    }
  }

  alarmActionChanged(e) {
    let alarmActions = this.state.alarmActions;
    alarmActions.forEach((a) => {
      if (a.alarmActionId === parseInt(e.target.id)) {
        a.setItem = "Y";
      } else {
        a.setItem = "N";
      }
    });
    this.setState({ alarmActions: alarmActions });
  }

  saveAlarmActions() {
    const alarmAction = this.state.alarmActions.find((a) => a.setItem === "Y");
    this.props
      .putAlarmAction(this.props.alarmId, alarmAction.alarmActionId)
      .then(() => {
        this.props.loadDeviceAlarmConfigList(this.props.deviceId);
      });

    this.setState({ showAlarmActionDialog: false });
  }

  renderAlarmCheckTypeDropDown() {
    return alarmCheckTypeList.map((checkType, i) => {
      return (
        <option key={i} value={checkType.checkTypeId}>
          {checkType.checkType}
        </option>
      );
    });
  }

  updateAlarmSettingDescription(e) {
    this.setState({ alarmSettingDescription: e.target.value });
  }

  updateAlarmCheckType(e) {
    this.setState({ checkTypeId: e.target.value });
  }

  updateThreshold(e) {
    this.setState({ threshold: e.target.value });
  }

  updateRestoreCheckType(e) {
    this.setState({ restoreCheckTypeId: e.target.value });
  }

  updateRestoreThreshold(e) {
    this.setState({ restoreThreshold: e.target.value });
  }

  updateAlarmHoldOff(e) {
    this.setState({ alarmHoldOff: e.target.value });
  }

  updateRestoreHoldoff(e) {
    this.setState({ restoreHoldoff: e.target.value });
  }

  updateRepeatInterval(e) {
    this.setState({ repeatInterval: e.target.value });
  }

  isValidDate(date) {
    return moment.isDate(date) || moment.isMoment(date);
  }

  setMutedUntil(muteDate) {
    if (this.isValidDate(muteDate)) {
      this.setState({ mutedUntil: muteDate });
    }
  }

  handleSnoozeCheckboxChanged(e) {
    let muteInputDisabled = true;
    let mutedFlag = "N";
    if (e.target.checked === true) {
      muteInputDisabled = false;
      mutedFlag = "Y";
    }

    const muteInputProps = {
      placeholder: formatDate(this.props.mutedUntil),
      disabled: muteInputDisabled,
      className: "form-control form-control-sm",
    };

    this.setState({
      mutedFlagBoolean: e.target.checked,
      muteInputProps: muteInputProps,
      mutedFlag: mutedFlag,
    });
  }

  saveAlarmConfig() {
    if (this.state.readOnly) return;

    const newErrors = this.validateInput();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors });
      return;
    }

    const alarmConfig = {
      alarmId: parseInt(this.props.alarmId),
      sortOrder: parseInt(this.props.sortOrder),
      alarmSettingDescription: this.state.alarmSettingDescription,
      logicalInputId: parseInt(this.props.logicalInputId),
      checkTypeId: parseInt(this.state.checkTypeId),
      threshold: parseFloat(this.state.threshold),
      alarmHoldOff: parseInt(this.state.alarmHoldOff),
      restoreThreshold: parseFloat(this.state.restoreThreshold),
      restoreHoldoff: parseInt(this.state.restoreHoldoff),
      restoreCheckTypeId: parseInt(this.state.restoreCheckTypeId),
      withoutNotification: this.props.withoutNotification,
      repeatInterval: parseInt(this.state.repeatInterval),
    };

    this.disableEditMode();

    if (this.props.newAlarm) {
      this.props.toggleNewAlarmConfigDialog(false);
    }

      if (this.state.mutedFlagBoolean === true) {
          if (this.state.mutedUntil) {
              const alarmMuteConfig = {
                  alarmId: parseInt(this.props.alarmId),
                  deviceId: this.props.deviceId,
                  muteFlag: this.state.mutedFlag,
                  muteUntil: formatDate(this.state.mutedUntil),
              };

              // console.log(`Date as is: ${this.state.mutedUntil}`);
              // console.log(`Date formatDate: ${formatDate(this.state.mutedUntil)}`);
              this.props.setDeviceAlarmMute(alarmMuteConfig);
          }
      } else {
          //this.state.mutedFlag = "N";
          this.setState({ mutedFlag: "N" });
          this.setState({ mutedUntil: null });

          const alarmMuteConfig = {
              alarmId: parseInt(this.props.alarmId),
              deviceId: this.props.deviceId,
              muteFlag: this.state.mutedFlag,
              muteUntil: formatDate(this.state.mutedUntil),
          };
          this.props.setDeviceAlarmMute(alarmMuteConfig);

      }

    this.props
      .updateDeviceAlarmConfig(this.props.deviceId, alarmConfig)
      .then(() => {
        this.props.loadDeviceAlarmConfigList(this.props.deviceId, true);
      });
  }

  deleteAlarmConfig() {
    if (this.props.alarmId === null) return;

    this.props.deleteDeviceAlarmConfig(this.props.alarmId).then(() => {
      this.props
        .loadDeviceAlarmConfigList(this.props.deviceId)
        .then(() => this.updateStateDisplayed());
    });
  }

  updateStateDisplayed() {
    this.setState({
      alarmSettingDescription: this.props.alarmSettingDescription,
      checkType: this.props.checkType,
      checkTypeId: this.props.checkTypeId,
      threshold: this.props.threshold,
      restoreCheckType: this.props.restoreCheckType,
      restoreCheckTypeId: this.props.restoreCheckTypeId,
      restoreThreshold: this.props.restoreThreshold,
      alarmHoldOff: this.props.alarmHoldOff,
      restoreHoldoff: this.props.restoreHoldoff,
      repeatInterval: this.props.repeatInterval,
      mutedUntil: this.props.mutedUntil,
      mutedFlag: this.props.mutedFlag,
      mutedFlagBoolean: this.props.mutedFlagBoolean,
    });
  }

  validateInput() {
    const newErrors = {};
    if (this.state.alarmSettingDescription === "") {
      newErrors.alarmSettingDescription = "Please enter a name for the alarm";
    }

    if (this.state.threshold === "" || isNaN(this.state.threshold)) {
      newErrors.threshold = "Please enter a number";
    }

    if (
      this.state.restoreThreshold === "" ||
      isNaN(this.state.restoreThreshold)
    ) {
      newErrors.restoreThreshold = "Please enter a number";
    }

    if (this.state.restoreHoldoff === "" || isNaN(this.state.alarmHoldOff)) {
      newErrors.alarmHoldOff = "Please enter a number";
    }

    if (this.state.restoreHoldoff === "" || isNaN(this.state.restoreHoldoff)) {
      newErrors.restoreHoldoff = "Please enter a number";
    }

    if (this.state.repeatInterval === "" || isNaN(this.state.repeatInterval)) {
      newErrors.repeatInterval = "Please enter a number";
    }

    return newErrors;
  }

  render() {
    return (
      <>
        <Form className="form-group-container">
          <Row className="form-row">
            <Col className="form-col-description">Alarm Name</Col>
            <Col className="form-col">Set Condition</Col>
            <Col className="form-col"></Col>
            <Col className="form-col">Reset Condition</Col>
            <Col className="form-col"></Col>
          </Row>
          <Row className="form-row">
            <Col className="form-col-description">
              <Form.Control
                size="sm"
                readOnly={this.state.readOnly}
                value={this.state.alarmSettingDescription}
                onChange={(e) => this.updateAlarmSettingDescription(e)}
                isInvalid={!!this.state.errors.alarmSettingDescription}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.alarmSettingDescription}
              </Form.Control.Feedback>
            </Col>
            <Col className="form-col">
              <Form.Control
                size="sm"
                readOnly={this.state.readOnly}
                as="select"
                value={this.state.checkTypeId}
                onChange={(e) => this.updateAlarmCheckType(e)}
              >
                {this.renderAlarmCheckTypeDropDown()}
              </Form.Control>
            </Col>
            <Col className="form-col">
              <Form.Control
                size="sm"
                value={this.state.threshold}
                readOnly={this.state.readOnly}
                onChange={(e) => this.updateThreshold(e)}
                isInvalid={!!this.state.errors.threshold}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.threshold}
              </Form.Control.Feedback>
            </Col>
            <Col className="form-col">
              <Form.Control
                size="sm"
                readOnly={this.state.readOnly}
                as="select"
                value={this.state.restoreCheckTypeId}
                onChange={(e) => this.updateRestoreCheckType(e)}
              >
                {this.renderAlarmCheckTypeDropDown()}
              </Form.Control>
            </Col>
            <Col className="form-col">
              <Form.Control
                size="sm"
                readOnly={this.state.readOnly}
                value={this.state.restoreThreshold}
                onChange={(e) => this.updateRestoreThreshold(e)}
                isInvalid={!!this.state.errors.restoreThreshold}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.restoreThreshold}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="form-row">
            <Col className="form-col-description">
              <Form.Check
                size="sm"
                type="checkbox"
                label="Notifications"
                disabled={true}
                checked={this.props.notificationsFlag === "Y"}
              />
              <Button
                size="sm"
                variant="primary"
                onClick={this.handleShowAlarmContactDialog}
              >
                Edit
              </Button>
            </Col>
            <Col className="form-col">Hold off time</Col>
            <Col className="form-col"></Col>
            <Col className="form-col">Hold off time</Col>
            <Col className="form-col"></Col>
          </Row>
          <Row className="form-row">
            <Col className="form-col-description">
              <Form.Check
                size="sm"
                //readOnly={this.state.readOnly}
                type="checkbox"
                label="Actions"
                checked={this.props.actionsFlag === "Y"}
                disabled
              />
              {/* <div className="form-label">Actions</div> */}
              <Button
                size="sm"
                variant="primary"
                onClick={this.handleShowAlarmActionDialog}
              >
                Edit
              </Button>
            </Col>
            <Col className="form-col">
              <Form.Control
                size="sm"
                readOnly={this.state.readOnly}
                value={this.state.alarmHoldOff}
                onChange={(e) => this.updateAlarmHoldOff(e)}
                isInvalid={!!this.state.errors.alarmHoldOff}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.alarmHoldOff}
              </Form.Control.Feedback>
            </Col>
            <Col className="form-col"></Col>
            <Col className="form-col">
              <Form.Control
                size="sm"
                readOnly={this.state.readOnly}
                value={this.state.restoreHoldoff}
                onChange={(e) => this.updateRestoreHoldoff(e)}
                isInvalid={!!this.state.errors.restoreHoldoff}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.restoreHoldoff}
              </Form.Control.Feedback>
            </Col>
            <Col className="form-col"></Col>
          </Row>
          <Row className="formRow"></Row>
          <Row className="form-row">
            <Col className="form-col-description">Repeat Alarm every</Col>
            <Col className="form-col">
              <Form.Control
                size="sm"
                readOnly={this.state.readOnly}
                value={this.state.repeatInterval}
                onChange={(e) => this.updateRepeatInterval(e)}
                isInvalid={!!this.state.errors.repeatInterval}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.repeatInterval}
              </Form.Control.Feedback>
            </Col>
            <Col className="form-col">minutes</Col>
            <Col className="form-col"></Col>
            <Col className="form-col"></Col>
          </Row>
          <Row className="form-row">
            <Col className="form-col-description">
              <Form.Check
                size="sm"
                type="checkbox"
                label="Snooze Enabled"
                disabled={this.state.readOnly}
                checked={this.state.mutedFlagBoolean}
                onChange={this.handleSnoozeCheckboxChanged}
              />
            </Col>
            <Col className="form-col">snooze until</Col>
            <Col className="form-col-large">
              <Datetime
                size="sm"
                readOnly={this.state.readOnly}
                inputProps={this.state.muteInputProps}
                dateFormat="yyyy-MM-DD"
                timeFormat="HH:mm"
                onChange={this.setMutedUntil}
              />
            </Col>
            <Col className="form-col"></Col>
          </Row>
          <Row className="form-row-buttons">
            <Col className="form-col-description">
               {this.state.hasAdminRights &&
                this.props.cloudAlarmFlag === null &&
                this.props.alarmId !== null && (
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={this.deleteAlarmConfig}
                    className="form-group-button"
                  >
                    Delete
                  </Button>
                )}
            </Col>
            <Col className="form-col"></Col>
            <Col className="form-col"></Col>
            <Col className="form-col"></Col>
            <Col className="form-col-description">
              {!this.props.newAlarm && (
                <Button
                  size="sm"
                  variant="primary"
                  onClick={this.toggleEditMode}
                  className="form-group-button"
                >
                  Edit
                </Button>
              )}
              <Button
                size="sm"
                variant="primary"
                onClick={this.saveAlarmConfig}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
        <Modal
          show={this.state.showAlarmContactDialog}
          onHide={this.handleCloseAlarmContactDialog}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Notification Contacts for {this.state.alarmSettingDescription}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AlarmContactConfiguration
              deviceId={this.props.deviceId}
              alarmId={this.props.alarmId}
            ></AlarmContactConfiguration>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showAlarmActionDialog}
          onHide={this.handleCloseAlarmActionDialog}
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>Alarm Actions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <JSONPretty data={this.state.alarmActions}></JSONPretty> */}
            <div className="container">
              {this.state.alarmActions.map((a) => {
                return (
                  <div className="form-check radio-button-container">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={a.setItem === "Y"}
                      id={a.alarmActionId}
                      onChange={(e) => this.alarmActionChanged(e)}
                    />
                    <label className="radio-button-label">
                      {a.description}
                    </label>
                  </div>
                );
              })}
              <div className="alarm-actions-button-row">
                <Button onClick={this.saveAlarmActions}>Save</Button>
                <Button
                  className="actions-button"
                  onClick={this.handleCloseAlarmActionDialog}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  updateDeviceAlarmConfig,
  toggleNewAlarmConfigDialog,
  loadDeviceAlarmConfigList,
  deleteDeviceAlarmConfig,
  setDeviceAlarmMute,
  getAlarmActions,
  putAlarmAction,
};

export default connect(null, mapDispatchToProps)(EditAlarmConfiguration);
