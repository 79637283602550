import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { getAvailabilitySchedule } from "./selectors/alarmContactSelector";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Check from "react-bootstrap/FormCheck";
import "./DeviceConfig.css";

class AlarmContactPhone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialSelectedValue: {},
    };
    this.updatePhoneAvailability = this.updatePhoneAvailability.bind(this);
    this.updateSms = this.updateSms.bind(this);
    this.updateVoiceMessage = this.updateVoiceMessage.bind(this);
  }

  componentDidMount() {
    if (
      this.props.availabilitySchedule.length > 0 &&
      this.props.availabilityId === -1
    ) {
      this.props.updatePhoneAvailability(
        this.props.availabilitySchedule[0].availabilityScheduleId,
        this.props.id
      );
    }
  }

  updatePhone(e) {
    this.props.updatePhone(e, this.props.id);
  }

  updatePhoneAvailability(e) {
    this.props.updatePhoneAvailability(e.target.value, this.props.id);
  }

  updateSms(e) {
    let smsFlag = "N";
    if (e.target.checked === true) {
      smsFlag = "Y";
    }

    this.props.updateSms(smsFlag, this.props.id);
  }

  updateVoiceMessage(e) {
    let voice = "N";
    if (e.target.checked === true) {
      voice = "Y";
    }

    this.props.updateVoiceMessage(voice, this.props.id);
  }

  render() {
    return (
      <>
        <Row className="form-row">
          <br />
          <Col className="form-col-label-alarm-contact">Phone:</Col>
          <Col className="form-col-input-alarm-contact">
            <Form.Control
              value={this.props.phoneNumber}
              size="sm"
              onChange={(e) => this.updatePhone(e)}
              isInvalid={!!this.props.errors.phoneNumber}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {this.props.errors.phoneNumber}
            </Form.Control.Feedback>
          </Col>
          <Col className="form-col-label-alarm-contact">Availability</Col>
          <Col className="form-col-input-alarm-contact-availability">
            <select
              className="form-control form-control-sm"
              onChange={this.updatePhoneAvailability}
              value={this.props.availabilityId}
            >
              {this.props.availabilitySchedule.map((item, index) => {
                return (
                  <option value={item.availabilityScheduleId}>
                    {item.description}
                  </option>
                );
              })}
            </select>

            {this.props.errors.availabilityId && (
              <div className="invalid">{this.props.errors.availabilityId}</div>
            )}
          </Col>
        </Row>
        <Row className="form-row">
          <Col>
            <Check
              className="form-check-wide"
              label="Send SMS"
              onChange={this.updateSms}
              checked={this.props.smsFlag === "Y"}
            ></Check>
            <Check
              className="form-check-wide"
              label="Call by voice message"
              checked={this.props.voiceFlag === "Y"}
              onChange={this.updateVoiceMessage}
            ></Check>
          </Col>
        </Row>
      </>
    );
  }
}

AlarmContactPhone.defaultProps = {
  availabilitySchedule: [],
  availabilityId: -1,
  initialSelectedValue: {},
};

function mapStateToProps(state, ownProps) {
  const availabilitySchedule = getAvailabilitySchedule(state);

  if (availabilitySchedule.length > 0) {
    const selected = availabilitySchedule[0];
    if (ownProps.availabilityId === -1) {
      ownProps.initialSelectedValue = {
        value: selected.availabilityId,
        label: selected.description,
      };
    }
  }

  return {
    availabilitySchedule: availabilitySchedule,
  };
}
export default connect(mapStateToProps, null)(AlarmContactPhone);
