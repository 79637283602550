import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function customerReducer(
  state = initialState.customers,
  action
) {
  switch (action.type) {
    case types.CUSTOMER_LIST_LOADED: {
      return action.customers;
    }
    default:
      return state;
  }
}
