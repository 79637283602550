import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function currentUserReducer(
  state = initialState.currentUser,
  action
) {
  switch (action.type) {
    case types.SET_CURRENT_USER: {
      // todo: potentially reduce this down rather than have the whole user object in state
      return action.user;
    }
    // case types.RESET_DEVICE_ALARM_CONFIG_LIST: {
    //   return [];
    // }
    default:
      return state;
  }
}
