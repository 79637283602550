import * as types from "../../../common/state/actionTypes";
import * as favouriteApi from "../api/userFavouritesApi";
import { logError } from "../../../errors/actions/errorActions";

export const userFavouriteAdded = (deviceId) => ({
  type: types.USER_DEVICE_FAVOURITE_ADDED,
  deviceId,
});

export const userFavouriteDeleted = (deviceId) => ({
  type: types.USER_DEVICE_FAVOURITE_DELETED,
  deviceId,
});

export function saveUserFavourite(deviceId) {
  return function (dispatch) {
    return favouriteApi
      .addFavourite(deviceId)
      .then(() => {
        dispatch(userFavouriteAdded(deviceId));
      })
      .catch((error) => {
        const err = new Error(
          "userFavouritesActions.js, saveUserFavourite()",
          error.toString()
        );
        dispatch(logError(err));
      });
  };
}

export function deleteUserFavourite(deviceId) {
  return function (dispatch) {
    return favouriteApi
      .deleteFavourite(deviceId)
      .then(() => {
        dispatch(userFavouriteDeleted(deviceId));
      })
      .catch((error) => {
        const err = new Error(
          "userFavouritesActions.js, deleteUserFavourite()",
          error.toString()
        );
        dispatch(logError(err));
      });
  };
}
