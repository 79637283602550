import React, { Component } from "react";
import { connect } from "react-redux";
import {
  loadAlarmContacts,
  loadAvailableAlarmContacts,
  loadContact,
  addAlarmContact,
  deleteAlarmContact,
  getFullAlarmContact,
  addAlarmContactToAllAlarmsForDevice,
  removeAlarmContactToAllAlarmsForDevice,
  loadDeviceAlarmConfigList,
} from "./actions/alarmConfigActions";
import {
  getAlarmContacts,
  getAvailableAlarmContacts,
} from "./selectors/alarmContactSelector";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import AlarmContact from "./AlarmContact";

class AlarmContactConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      forceChildControlReRender: 0, // TODO: not ideal needs better way of updating the control
      addContactToAllAlarmsClassname: "add-contact-all-alarms-container-hide",
      removeContactFromAllAlarmsClassname:
        "remove-contact-all-alarms-container-hide",
      showAvailableContacts: false,
      showAddEditContact: false,
      contactIdDeleted: null,
      contactEditing: {
        contactId: -1,
        contactName: "",
        timezoneId: null,
        alarmContactUpdate: {},
        emailContacts: [
          {
            id: 0,
            email: "",
            availabilityId: -1,
            errors: {
              email: "",
              availabilityId: "",
            },
          },
        ],
        phoneContacts: [
          {
            id: 0,
            phoneNumber: "",
            availabilityId: -1,
            smsFlag: "N",
            voiceFlag: "N",
            errors: {
              phoneNumber: "",
              availabilityId: "",
            },
          },
        ],
        errors: {},
      },
      selectedContactId: null,
    };

    this.multiselectRef = React.createRef();
    this.editContact = this.editContact.bind(this);
    this.removeContact = this.removeContact.bind(this);
    this.setSelectedContact = this.setSelectedContact.bind(this);
    this.addAlarmContact = this.addAlarmContact.bind(this);
    this.deleteAlarmContact = this.deleteAlarmContact.bind(this);
    //this.hideAddEditContact = this.showAddEditContact.bind(this);
  }

  componentDidMount() {
    this.props.loadAlarmContacts(this.props.alarmId);
    this.props.loadAvailableAlarmContacts(this.props.alarmId);
  }

  editContact(contactId) {
    this.props.getFullAlarmContact(contactId).then((c) => {
      let emails = [];
      c.emails.forEach((e) => {
        const email = {
          id: e.contactEmailId,
          email: e.email,
          availabilityId: e.availabilityScheduleId,
          errors: {
            email: "",
            availabilityId: "",
          },
        };

        emails.push(email);
      });

      let phones = [];
      c.phoneNumbers.forEach((p) => {
        const phone = {
          id: p.contactPhoneId,
          phoneNumber: p.phoneNumber,
          availabilityId: p.availabilityScheduleId,
          smsFlag: p.smsFlag,
          voiceFlag: p.voiceFlag,
          errors: {
            phoneNumber: "",
            availabilityId: "",
          },
        };

        phones.push(phone);
      });

      const contact = {
        contactId: c.alarmContact.contactId,
        contactName: c.alarmContact.name,
        timezoneId: c.alarmContact.timezoneId,
        emailContacts: emails,
        phoneContacts: phones,
      };

      this.setState({
        contactEditing: contact,
        forceChildControlReRender: this.state.forceChildControlReRender + 1,
        showAddEditContact: true,
      });
    });
  }
  removeContact(alarmId, contactId) {
    alert(`removeContact alarmId:${alarmId}, contactId:${contactId}`);
  }

  setSelectedContact(selectedOption) {
    let selectedContactId = null;
    if (selectedOption !== null) {
      selectedContactId = selectedOption.value;
    }

    this.setState({ selectedContactId: selectedContactId });
  }

  addNewContact(contactId) {
    if (contactId !== undefined) {
      this.setState({ selectedContactId: contactId });
      this.props.addAlarmContact(this.props.alarmId, contactId);
      this.setState({
        addContactToAllAlarmsClassname: "add-contact-all-alarms-container-show",
      });
    }
  }

  addAlarmContact() {
    if (this.state.selectedContactId !== null) {
      this.props.addAlarmContact(
        this.props.alarmId,
        this.state.selectedContactId
      );
      this.setState({
        addContactToAllAlarmsClassname: "add-contact-all-alarms-container-show",
      });
    }
  }

  deleteAlarmContact(alarmId, contactId) {
    this.props.deleteAlarmContact(alarmId, contactId).then(() => {
      this.props.loadAvailableAlarmContacts(alarmId);
    });
    this.setState({
      removeContactFromAllAlarmsClassname:
        "remove-contact-all-alarms-container-show",
      contactIdDeleted: contactId,
    });
  }

  showAvailableContacts() {
    this.setState({ showAvailableContacts: true });
  }

  hideAvailableContacts() {
    this.setState({ showAvailableContacts: false });
  }

  showAddEditContact() {
    this.setState({ showAddEditContact: true });
  }

  showAddNewContact() {
    const resetContact = {
      contactId: -1,
      contactName: "",
      timezoneId: null,
      alarmContactUpdate: {},
      emailContacts: [
        {
          id: 0,
          email: "",
          availabilityId: -1,
          errors: {
            email: "",
            availabilityId: "",
          },
        },
      ],
      phoneContacts: [
        {
          id: 0,
          phoneNumber: "",
          availabilityId: -1,
          smsFlag: "N",
          voiceFlag: "N",
          errors: {
            phoneNumber: "",
            availabilityId: "",
          },
        },
      ],
      errors: {},
    };

    this.setState({
      contactEditing: resetContact,
      showAddEditContact: true,
    });
  }

  hideAddEditContact() {
    this.setState({ showAddEditContact: false });
  }

  cancelAddContactToAllAlarms() {
    this.setState({
      addContactToAllAlarmsClassname: "add-contact-all-alarms-container-hide",
      showAvailableContacts: false,
    });
  }

  addContactToAllAlarms() {
    if (this.state.selectedContactId !== null) {
      this.props
        .addAlarmContactToAllAlarmsForDevice(
          this.props.deviceId,
          this.state.selectedContactId
        )
        .then(() => {
          this.props.loadDeviceAlarmConfigList(this.props.deviceId);
        });
    }
    this.setState({
      addContactToAllAlarmsClassname: "add-contact-all-alarms-container-hide",
      showAvailableContacts: false,
    });
  }

  removeContactFromAllAlarms() {
    if (this.state.contactIdDeleted !== null) {
      this.props
        .removeAlarmContactToAllAlarmsForDevice(
          this.props.deviceId,
          this.state.contactIdDeleted
        )
        .then(() => {
          this.props.loadDeviceAlarmConfigList(this.props.deviceId);
        });
    }
    this.setState({
      removeContactFromAllAlarmsClassname:
        "remove-contact-all-alarms-container-hide",
    });
  }

  cancelRemoveContactFromAllAlarms() {
    this.setState({
      removeContactFromAllAlarmsClassname:
        "remove-contact-all-alarms-container-hide",
    });
  }

  render() {
    return (
      <div>
        {/* <JSONPretty data={this.props.contacts}></JSONPretty> */}
        {/* <JSONPretty data={this.state}></JSONPretty> */}
        <Table className="alarm-contact-configuration-table">
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {this.props.contacts.map((c, key) => {
              return (
                <tr key={key}>
                  <td>{c.name}</td>
                  <td>
                    <Button
                      onClick={() =>
                        this.deleteAlarmContact(this.props.alarmId, c.contactId)
                      }
                    >
                      Remove
                    </Button>
                  </td>
                  <td>
                    <Button onClick={() => this.editContact(c.contactId)}>
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className={this.state.removeContactFromAllAlarmsClassname}>
          Do you want this contact removed from all alarms for this device?
          <Button
            className="remove-contact-button"
            onClick={() => this.removeContactFromAllAlarms()}
          >
            Yes
          </Button>
          <Button
            className="remove-contact-button"
            onClick={() => this.cancelRemoveContactFromAllAlarms()}
          >
            No
          </Button>
        </div>
        <Button onClick={() => this.showAvailableContacts()}>Add</Button>
        {this.state.showAvailableContacts && (
          <div>
            <br />
            <h5>Available contacts</h5>
            <Select
              placeholder="Type to search..."
              isClearable
              onChange={this.setSelectedContact}
              options={this.props.availableContacts.map((item, index) => {
                return { value: item.contactId, label: item.name };
              })}
            />

            <div className={this.state.addContactToAllAlarmsClassname}>
              Do you want this contact adding to all alarms for this device?
              <Button
                className="add-contact-button"
                onClick={() => this.addContactToAllAlarms()}
              >
                Yes
              </Button>
              <Button
                className="add-contact-button"
                onClick={() => this.cancelAddContactToAllAlarms()}
              >
                No
              </Button>
            </div>
            <br />
            <Button
              className="cancel-button"
              onClick={() => this.hideAvailableContacts()}
            >
              Cancel
            </Button>
            <Button
              className="save-button"
              onClick={() => this.addAlarmContact()}
            >
              Add
            </Button>
            <br />
            <br />
            <Button onClick={() => this.showAddNewContact()}>
              Add New Contact
            </Button>
            <br />
          </div>
        )}
        <br />
        <br />
        {this.state.showAddEditContact && (
          <AlarmContact
            contactEditing={this.state.contactEditing}
            closeAddContact={() => this.hideAddEditContact()}
            forceChildControlReRender={this.state.forceChildControlReRender}
            addNewContact={(contactId) => this.addNewContact(contactId)}
          />
        )}
      </div>
    );
  }
}

AlarmContactConfiguration.defaultProps = {
  contacts: [],
  availableContacts: [],
  timezones: [],
  //alarmId: -1,
};
function mapStateToProps(state, ownProps) {
  return {
    contacts: getAlarmContacts(state, ownProps.alarmId),
    availableContacts: getAvailableAlarmContacts(state, ownProps.alarmId),
  };
}

const mapDispatchToProps = {
  loadAlarmContacts,
  loadAvailableAlarmContacts,
  loadContact,
  addAlarmContact,
  deleteAlarmContact,
  getFullAlarmContact,
  addAlarmContactToAllAlarmsForDevice,
  removeAlarmContactToAllAlarmsForDevice,
  loadDeviceAlarmConfigList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlarmContactConfiguration);
