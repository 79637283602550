import React, { Component } from "react";
import { connect } from "react-redux";
import { getSelectedDevice } from "../deviceList/selectors/deviceListSelector";
import {
    getDeviceInventorySummary,
} from "./selectors/deviceInventorySummarySelector";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

class InventorySummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    dataField: "productVariant",
                    text: "Product",
                    editable: false,
                },
                {
                    dataField: "customerRef3",
                    text: "Category",
                    editable: false,
                },
                {
                    dataField: "stock",
                    text: "Stock",
                    editable: false,
                },
            ],
        };

    }

    sumTotal() {
        const result = this.props.inventorySummary.reduce((total, currentValue) => total = total + currentValue.stock, 0);

        return result;
    }

    render() {
        return (
            <div>
                <div>
                    {/* Inventory Summary: {device} */}
                    {/*<JSONPretty data={this.props.token}></JSONPretty> */}
                </div>
                <h5>Stock Summary: {this.sumTotal()} items</h5>
                <div>
                    <ToolkitProvider
                        bootstrap4
                        keyField="productVariant"
                        data={this.props.inventorySummary}
                        columns={this.state.columns}
                        search={{ defaultSearch: "" }}
                    >
                        {(props) => (
                            <div>
                                <BootstrapTable
                                    {...props.baseProps}
                                    hover
                                    bordered
                                    condensed
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                    {/* <JSONPretty data={this.props.inventorySummary}></JSONPretty> */}
                </div>
                {/* <JSONPretty data={this.props.inventorySummary}></JSONPretty> */}
            </div>
        );
    }
}

InventorySummary.defaultProps = {
    device: {},
    inventorySummary: [],
};

function mapStateToProps(state) {
    const device = getSelectedDevice(state);
    return {
        device: device,
        inventorySummary: getDeviceInventorySummary(state, device.id),
    };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(InventorySummary);
