import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const DeviceListSortableColumnHeader = ({ label, onSort, activeFilter }) => {
  const [sortOrder, setSortOrder] = useState("ASC");

  const handleSort = () => {
    const newSortOrder = sortOrder === "ASC" ? "DESC" : "ASC";
    setSortOrder(newSortOrder);
    onSort(label, newSortOrder);
  };

  return (
    <div
      className={`filter ${activeFilter === label ? "active-filter" : ""}`}
      onClick={handleSort}
      style={{ cursor: "pointer" }}
    >
      {label} {activeFilter === label ? (
        sortOrder === "ASC" ? (
          <FontAwesomeIcon icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faChevronUp} />
        )
      ) : (
        <FontAwesomeIcon icon={faChevronDown} />
      )}
    </div>
  );
};

DeviceListSortableColumnHeader.propTypes = {
  label: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  activeFilter: PropTypes.string,
};

export default DeviceListSortableColumnHeader;
