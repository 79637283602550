import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";

export default function incidentListReducer(
  state = initialState.deviceProvisioningTemplates,
  action
) {
  switch (action.type) {
    case types.DEVICE_PROVISIONING_TEMPLATES_LOADED: {
      return action.deviceTemplates;
    }
    default:
      return state;
  }
}
