import React, { Component } from "react";
import { callAdminApiGet } from "../../components/api/apiUtils";
import OtaGrid from "../ota/OtaGrid";
//import CurrentEnvironmentSettings from "./CurrentEnvironmentSettings";

class TestPage extends Component {
  test1() {
    const url = "api/test/test1";
    callAdminApiGet(url).then((result) => {
      alert(result.result);
    });
  }

  test2() {
    const url = "api/test/test2";
    callAdminApiGet(url).then((result) => {
      debugger;
      alert(result.result);
    });
  }
  render() {
    return (
      <div className="content-container">
        <div className="container">
          <h2>Test</h2>
          <div className="row">
            <div className="col">
              {/* <CurrentEnvironmentSettings />
              <Button onClick={() => this.test1()}>Test 1</Button>
              <br />
              <Button onClick={() => this.test2()}>Test 2</Button> */}
              <OtaGrid></OtaGrid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestPage;
