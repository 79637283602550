import * as types from "../../common/state/actionTypes";
import initialState from "../../common/state/initialState";
export default function eventLogDataReducer(
  state = initialState.eventLogData,
  action
) {
  switch (action.type) {
    case types.LOAD_EVENT_LOG_DATA_SUCCESS: {
      if (state.length === 1 && state[0].eventLogs.length === 0) {
        const eventLogData = [];
        eventLogData.push(action.eventLog);
        const eventLogs = [];
        const newEventLog = {
          deviceId: action.deviceId,
          eventLogs: eventLogData,
        };
        eventLogs.push(newEventLog);

        return eventLogs;
      }

      const filtered = state.filter((e) => e.deviceId !== action.deviceId);

      const updatedEventLogData = [];
      const updatedEventLog = {
        deviceId: action.deviceId,
        eventLogs: action.eventLog,
      };
      updatedEventLogData.push(updatedEventLog);

      return [...filtered, ...updatedEventLogData];
    }
    default:
      return state;
  }
}
