export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const LOAD_DEVICES_SUCCESS = "LOAD_DEVICES_SUCCESS";
export const LOAD_DEVICE_DETAILS_SUCCESS = "LOAD_DEVICE_DETAILS_SUCCESS";
export const LOAD_DEVICE_STATUS_SUCCESS = "LOAD_DEVICE_STATUS_SUCCESS";
export const LOAD_DEVICE_INPUTS_SUCCESS = "LOAD_DEVICE_INPUTS_SUCCESS";
export const DEVICE_STATUS_UPDATE = "DEVICE_STATUS_UPDATE";
export const DASHBOARD_STATUS_UPDATE = "DASHBOARD_STATUS_UPDATE";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export const LOAD_EVENT_LOG_DATA_SUCCESS = "LOAD_EVENT_LOG_DATA_SUCCESS";
export const DEVICE_TELEMETRY_UPDATE = "DEVICE_TELEMETRY_UPDATE";
export const LOAD_LATEST_CUSTOMER_INCIDENT_SUCCESS =
  "LOAD_LATEST_CUSTOMER_INCIDENT_SUCCESS";
export const LOAD_INCIDENT_DETAILS_SUCCESS = "LOAD_INCIDENT_DETAILS_SUCCESS";
export const LOAD_DEVICE_INCIDENT_LIST_SUCCESS =
  "LOAD_DEVICE_INCIDENT_LIST_SUCCESS";
export const RESET_DEVICE_INCIDENT_LIST = "RESET_DEVICE_INCIDENT_LIST";
export const LOAD_DEVICE_INCIDENT_ALARM_LIST_SUCCESS =
  "LOAD_DEVICE_INCIDENT_ALARM_LIST_SUCCESS";
export const LOG_ERROR = "LOG_ERROR";
export const LOAD_DEVICE_LIST_SUCCESS = "LOAD_DEVICE_LIST_SUCCESS";
export const DEVICE_LIST_FILTER_BY_STATUS = "DEVICE_LIST_FILTER_BY_STATUS";
export const DEVICE_LIST_SEARCH_BY_VALUE = "DEVICE_LIST_SEARCH_BY_VALUE";
export const DEVICE_LIST_FILTER_BY_LOCATION = "DEVICE_LIST_FILTER_BY_LOCATION";
export const DEVICE_LIST_FILTER_BY_AlARM = "DEVICE_LIST_FILTER_BY_AlARM";
export const DEVICE_LIST_FILTER_BY_OK = "DEVICE_LIST_FILTER_BY_OK";
export const DEVICE_LIST_FILTER_CLEAR = "DEVICE_LIST_FILTER_CLEAR";
export const DEVICE_LIST_FILTER_BY_FAVOURITE =
  "DEVICE_LIST_FILTER_BY_FAVOURITE";
export const DEVICE_LIST_FILTER_BY_DOOR = "DEVICE_LIST_FILTER_BY_DOOR";
export const DEVICE_LIST_SET_SELECTED_DEVICE =
  "DEVICE_LIST_SET_SELECTED_DEVICE";
export const DEVICE_LIST_LOAD_EXACT_PAGE = "DEVICE_LIST_LOAD_EXACT_PAGE";
export const DEVICE_LIST_LOAD_NEW_PAGE = "DEVICE_LIST_LOAD_NEW_PAGE";
export const DEVICE_LIST_NOTIFICATION_UPDATE =
  "DEVICE_LIST_NOTIFICATION_UPDATE";
export const DEVICE_LIST_UPDATE_DEVICE_DATA = "DEVICE_LIST_UPDATE_DEVICE_DATA";
export const DEVICE_LIST_UPDATE_DEVICE_LIST_DATA =
  "DEVICE_LIST_UPDATE_DEVICE_LIST_DATA";
export const SERVICE_BUS_SUBSCRIPTION_DETAILS_LOADED =
  "SERVICE_BUS_SUBSCRIPTION_DETAILS_LOADED";
export const CUSTOMER_DETAILS_LOADED = "CUSTOMER_DETAILS_LOADED";
export const CUSTOMER_LOCATIONS_LOADED_SUCCESS =
  "CUSTOMER_LOCATIONS_LOADED_SUCCESS";
export const CUSTOMER_SUBCUSTOMER_FILTER_LOADED_SUCCESS =
  "CUSTOMER_SUBCUSTOMER_FILTER_LOADED_SUCCESS";
export const DEVICE_TELEMETRY_DATA_UPDATE = "DEVICE_TELEMETRY_DATA_UPDATE";
export const RESET_LIVE_DEVICE_TELEMETRY_DATA =
  "RESET_LIVE_DEVICE_TELEMETRY_DATA";
export const LOAD_RECENT_TELEMETRY_DATA_SUCCESS =
  "LOAD_RECENT_TELEMETRY_DATA_SUCCESS";
export const LOAD_ANALYSIS_DEVICE_TELEMETRY_DATA_SUCCESS =
  "LOAD_ANALYSIS_DEVICE_TELEMETRY_DATA_SUCCESS";
export const RESET_ANALYIS_DEVICE_TELEMETRY_DATA =
  "RESET_ANALYIS_DEVICE_TELEMETRY_DATA";
export const SAVE_DATA_ANALYSIS_START_DATE = "SAVE_DATA_ANALYSIS_START_DATE";
export const SAVE_DATA_ANALYSIS_END_DATE = "SAVE_DATA_ANALYSIS_END_DATE";
export const USER_DEVICE_FAVOURITE_ADDED = "USER_DEVICE_FAVOURITE_ADDED";
export const USER_DEVICE_FAVOURITE_DELETED = "USER_DEVICE_FAVOURITE_DELETED";
export const LOAD_INCIDENT_REPORT_DROPDOWN_LIST_SUCCESS =
  "LOAD_INCIDENT_REPORT_DROPDOWN_LIST_SUCCESS";
export const LOAD_DEVICE_CUSTOMER_CONTACT_INFO_SUCCESS =
  "LOAD_DEVICE_CUSTOMER_CONTACT_INFO_SUCCESS";
export const LOAD_DEVICE_ALARM_CONFIG_LIST_SUCCESS =
  "LOAD_DEVICE_ALARM_CONFIG_LIST_SUCCESS";
export const RESET_DEVICE_ALARM_CONFIG_LIST = "RESET_DEVICE_ALARM_CONFIG_LIST";
export const TOGGLE_NEW_ALARM_CONFIG_DIALOG = "TOGGLE_NEW_ALARM_CONFIG_DIALOG";
export const LOAD_GENERAL_DEVICE_SETTINGS_SUCCESS =
  "LOAD_GENERAL_DEVICE_SETTINGS_SUCCESS";
export const LOAD_DEVICE_GROUPS_SUCCESS = "LOAD_DEVICE_GROUPS_SUCCESS";
export const ACKNOWLEDGE_INCIDENT_ALARM_SUCCESS =
  "ACKNOWLEDGE_INCIDENT_ALARM_SUCCESS";
export const LOAD_DEVICE_INVENTORY_SUCCESS = "LOAD_DEVICE_INVENTORY_SUCCESS";
export const LOAD_DEVICE_INVENTORY_PRODUCT_COUNT_SUCCESS = "LOAD_DEVICE_INVENTORY_PRODUCT_COUNT_SUCCESS";
export const LOAD_DEVICE_INVENTORY_HISTORY_SUCCESS =
  "LOAD_DEVICE_INVENTORY_HISTORY_SUCCESS";
export const LOAD_DEVICE_INVENTORY_SUMMARY_SUCCESS =
    "LOAD_DEVICE_INVENTORY_SUMMARY_SUCCESS";
export const REFRESH_DEVICE_INVENTORY_HISTORY_SUCCESS =
    "REFRESH_DEVICE_INVENTORY_HISTORY_SUCCESS";
export const REFRESH_DEVICE_INVENTORY_SUMMARY_SUCCESS =
    "REFRESH_DEVICE_INVENTORY_SUMMARY_SUCCESS";
export const LOAD_DEVICE_INVENTORY_HISTORY_MORE_SUCCESS =
    "LOAD_DEVICE_INVENTORY_HISTORY_MORE_SUCCESS";
export const LOAD_CHECK_SCAN_SUCCESS =
    "LOAD_CHECK_SCAN_SUCCESS";
export const LOAD_ALARM_CONTACTS_SUCCESS = "LOAD_ALARM_CONTACTS_SUCCESS";
export const LOAD_ALARM_AVIALABLE_CONTACTS_SUCCESS =
  "LOAD_ALARM_AVIALABLE_CONTACTS_SUCCESS";
export const LOAD_TIMEZONES_SUCCESS = "LOAD_TIMEZONES_SUCCESS";
export const LOAD_AVAILABILITY_SCHEDULE_SUCCESS =
  "LOAD_AVAILABILITY_SCHEDULE_SUCCESS";
export const UPDATE_GENERAL_DEVICE_SETTINGS_LOCATION =
  "UPDATE_GENERAL_DEVICE_SETTINGS_LOCATION";
export const CUSTOMER_LIST_LOADED = "CUSTOMER_LIST_LOADED";
export const DEVICE_PROVISIONING_TEMPLATES_LOADED =
  "DEVICE_PROVISIONING_TEMPLATES_LOADED";
export const DEVICE_LIST_SHOW_HIGH_TEMP = "DEVICE_LIST_SHOW_HIGH_TEMP";
export const DEVICE_LIST_IS_ENBALED = "DEVICE_LIST_IS_ENBALED";
export const DEVICE_LIST_FILTER_BY_NAME  = "DEVICE_LIST_FILTER_BY_NAME";
export const DEVICE_LIST_FILTER_BY_CUSOMTER_CODE = "DEVICE_LIST_FILTER_BY_CUSOMTER_CODE";
export const DEVICE_LIST_FILTER_BY_VOLTAGE = "DEVICE_LIST_FILTER_BY_VOLTAGE";
export const DEVICE_LIST_FILTER_BY_PROD_TEMP = "DEVICE_LIST_FILTER_BY_PROD_TEMP";
export const DEVICE_LIST_SORT_BY_DOOR = "DEVICE_LIST_SORT_BY_DOOR";
export const SET_IS_CONNECTED = 'SET_IS_CONNECTED';
